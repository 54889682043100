/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Board,
    Comment, defaultItemFilters,
    Item, ItemFilters,
    Listing,
    noBoard,
    noItem,
    noListing,
    noListingId,
    noUser,
    Project,
    ProjectBoard
} from "shared/model/model";
import {appContext} from "ApplicationContext";
import {Box, Button, Typography} from "@mui/material";
import IsoIcon from "@mui/icons-material/Iso";
import {ProjectCollaborationDialog} from "./dialogs/ProjectCollaborationDialog";
import {MainContainer} from "../../layout/MainContainer";
import {useConfirm} from "material-ui-confirm";
import {ProjectBoardCard} from "./boards/ProjectBoardCard";
import {v4 as uuidv4} from "uuid";
import ProjectDrawer, {ProjectMenuItemType} from "./ProjectDrawer";
import {ProjectItemsView} from "./ProjectItemsView";
import {Dashboard, SvgIconComponent, TurnedIn} from "@mui/icons-material";
import {ProjectNameDialog} from "./dialogs/ProjectNameDialog";
import {ListingNameDialog} from "./ListingNameDialog";
import {ProjectAppBar} from "./ProjectAppBar";
import {BoardReadOnlyCanvas} from "./boards/BoardReadOnlyCanvas";
import {useProjectState} from "state/ProjectState";
import {colors} from "shared/styles/vars";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {BoardCreationDialog} from "./boards/editor/BoardCreationDialog";
import {ListingItemStatusDialog} from "./listing-item/ListingItemStatusDialog";
import {ListingSharingDialog} from "./listings/ListingSharingDialog";
import {appBarHeight, logDebug, logError} from "shared/utils";
import {ListingItemDetail} from "./listing-item/ListingItemDetail";
import html2canvas from "html2canvas";
import downloadjs from "downloadjs";
import {boardHeight, boardWidth} from "config/settings";
import {navigateTo, useTypedParams} from "utils/routing-utils";
import {ProjectPath} from 'generated/routing/ApplicationPath.generated';
import {useUserState} from "state/UserState";
import {filtersToSearchParams, filtersToSearchParamsIfDefined, searchParamsToFilters} from "common/param_utils";
import {useLocation} from "react-router-dom";

export interface WorkParams {
    projectId: string
    menuItemType: ProjectMenuItemType
    listingId: string | null
    boardId: string | null
    itemId: string | null
}

export interface Props {
    workParams: WorkParams
}

export interface MenuItem {
    menuItemType: ProjectMenuItemType
    label: string
    subId?: string | null
    icon?: SvgIconComponent,
    shared?: boolean | null
}

export const ProjectComponent = (props: Props) => {
    const params = useTypedParams<ProjectPath>()

    const userInfos = useUserState(s => s.userInfos);
    const setConnected = useUserState(s => s.setConnected);

    const project = useProjectState(s => s.project);
    const setProject = useProjectState(s => s.setProject);

    const [projectName, setProjectName] = useState('');
    const [isAddPickerDisplayed, setAddPickerDisplayed] = useState(false);
    const [pickedItemsIds, setPickedItemsIds] = useState<string[]>([]);

    const [listingCreationMode, setListingCreationMode] = useState(true);
    const [currentListingName, setCurrentListingName] = useState('');
    const [listingNameDialogOpen, setListingNameDialogOpen] = useState(false)
    const [listingSharingDialogOpen, setListingSharingDialogOpen] = useState(false)
    const [currentListing, setCurrentListing] = useState(noListing)
    const [currentListingItem, setCurrentListingItem] = useState(noItem)
    const [editedListingItems, setEditedListingItems] = useState<Item[]>([])
    const [listingItemStatusDialogOpen, setListingItemStatusDialogOpen] = useState(false)

    const [boardCreationDialogOpen, setBoardCreationDialogOpen] = useState(false)
    const [currentBoard, setCurrentBoard] = useState(noBoard)

    const [menuItems, setMenuItems] = useState([] as MenuItem[])
    const listingsMenuItem = {menuItemType: 'listings', label: "Articles", icon: TurnedIn} as MenuItem
    const boardsMenuItem = {menuItemType: 'boards', label: "Planches", icon: Dashboard} as MenuItem
    const [currentMenuItem, setCurrentMenuItem] = useState(listingsMenuItem)

    const [sharingDialogOpen, setSharingDialogOpen] = React.useState(false);
    const [collabDialogOpen, setCollabDialogOpen] = React.useState(false);
    const [projectNameDialogOpen, setProjectNameDialogOpen] = React.useState(false);

    const [itemFilters, setItemFilters] = useState(defaultItemFilters)

    const confirm = useConfirm();

    const projectId = props.workParams.projectId
    const listingId = props.workParams.listingId
    const itemId = props.workParams.itemId
    const boardId = props.workParams.boardId
    const menuItemType = props.workParams.menuItemType

    const [loading, setLoading] = useState(false)
    const [downloadRequested, setDownloadRequested] = useState(false)
    const [boardForDownloadVisible, setBoardForDownloadVisible] = useState(false)

    const [mainPanelHeight, setMainPanelHeight] = useState(500);

    const handleLogout = () => {
        appContext.loginService().logout().then(() => {
            setConnected(false, noUser)
            navigateTo({name: 'Login'})
        });
    }

    const toListingMenuItem = (listing: Listing) => {
        return {menuItemType: "listing_detail", label: listing.name, subId: listing.id} as MenuItem
    }

    const toBoardMenuItem = (board: ProjectBoard) => {
        return {menuItemType: "board_detail", label: board.name, subId: board.id} as MenuItem
    }

    const createMenuItems = (project: Project) => {
        let listingDetailsMenuItems: Array<MenuItem> = project.listings.map((listing) => toListingMenuItem(listing))
        let boardDetailsMenuItems: Array<MenuItem> = project.boards.map((board) => toBoardMenuItem(board))
        let newMenuItems = [
            listingsMenuItem,
            ...listingDetailsMenuItems,
            boardsMenuItem,
            ...boardDetailsMenuItems,
        ]
        setMenuItems(newMenuItems)
        return newMenuItems
    }

    useEffect(() => {
        if (project) {
            setProjectName(project.name)
        }
        setMainPanelHeight(window.visualViewport?.height!! - appBarHeight - 10) // appBar is 50, but panel is margin top 60
    }, [project]);

    const handleCloseCollabDialog = () => {
        setCollabDialogOpen(false);
    };

    const handleAddCollab = async (email: string, role: string, notify: boolean) => {
        try {
            let invitation = await appContext.projectService().share(projectId, email, role, notify)
            let p = await appContext.projectService().getProject(projectId)
            // TODO afficher en collaborateur (et pas en invitation) si user existe déjà
            setProject(p)
        } catch (e) {
            logError(`Could not share project with email ${email}`, e)
        }
    }

    const handleUnshare = (collaboratorMails: string[]) => {
        appContext.projectService().unshare(projectId, collaboratorMails)
            .then(() => {
                return appContext.projectService().getProject(projectId)
            }).then((p: Project) => {
            // TODO afficher en collaborateur (et pas en invitation) si user existe déjà
            setProject(p)
        })
        // TODO ETD
    }

    const handleCloseProjectNameDialog = () => {
        setProjectNameDialogOpen(false);
    };

    const handleCancelClicked = () => {
        setAddPickerDisplayed(false)
        resetSelection()
    };

    const handleArchiveProject = () => {
        confirm({
            title: `Archiver le projet`,
            description: "Le projet ne sera plus listé parmi vos projets courants, mais vous pourrez le retrouver en consultant les projets archivés",
            cancellationText: "Annuler"
        })
            .then(() => {
                appContext.projectService().archiveProjects([projectId]).then(() => {
                    navigateTo({name: 'Root'})
                })
            })
            .catch(() => {
                logError("error when confirming project archival")
            });

    };

    const resetCurrentItem = () => {
        setCurrentListingItem(noItem)
    }

    const resetSelection = () => {
        setPickedItemsIds([])
    }

    const handleClickPickItems = () => {
        resetSelection()
        setAddPickerDisplayed(true)
    };

    function renameProject(newName: string) {
        appContext.projectService().renameProject(project.id, newName).then(() => {
            setProject({...project, name: newName})
        })
    }

    function addProjectItems() {
        appContext.projectService().addItems(project.id, pickedItemsIds).then(() => getOrFetchProject(project.id, true)).then(() => {
            setAddPickerDisplayed(false)
            resetSelection()
            resetCurrentItem()
        })
    }

    function removeProjectItems() {
        let desc = (pickedItemsIds.length > 1) ?
            `Êtes-vous sûr de vouloir retirer ces ${pickedItemsIds.length} articles du projet ?`
            : `Êtes-vous sûr de vouloir retirer cet article du projet ?`
        confirm({
            title: `Retirer ${(pickedItemsIds.length > 1) ? "les articles" : "l'article"} `,
            description: desc,
            cancellationText: "Annuler"
        })
            .then(() => {
                appContext.projectService().removeItems(project.id, pickedItemsIds).then(() => getOrFetchProject(project.id, true)).then(() => {
                    resetSelection()
                    resetCurrentItem()
                })
            })
            .catch(() => {
                logError("error when confirming item deletion")
            });
    }

    function handleDeleteItems() {
        if (!currentListing.id || (currentListing.id == noListingId)) {
            removeProjectItems();
        } else {
            removeListingItems();
        }
    }

    function addListingItems() {
        appContext.listingService().addItems(currentListing.id, pickedItemsIds).then(() => getOrFetchProject(project.id, true)).then((p: Project) => {
            let updatedListing = p.listings.find((l) => l.id == currentListing.id)
            if (updatedListing) {
                setCurrentListing(updatedListing)
            }
            setAddPickerDisplayed(false)
            resetCurrentItem()
            resetSelection()
        })
    }

    function removeListingItems() {
        let desc = (pickedItemsIds.length > 1) ?
            `Êtes-vous sûr de vouloir retirer ces ${pickedItemsIds.length} articles de la liste ?`
            : `Êtes-vous sûr de vouloir retirer cet article de la liste ?`
        confirm({
            title: `Retirer ${(pickedItemsIds.length > 1) ? "les articles" : "l'article"} `,
            description: desc,
            cancellationText: "Annuler"
        })
            .then(() => {
                appContext.listingService().removeItems(currentListing.id, pickedItemsIds).then(() => getOrFetchProject(project.id, true)).then((p: Project) => {
                    let updatedListing = p.listings.find((l) => l.id == currentListing.id)
                    if (updatedListing) {
                        setCurrentListing(updatedListing)
                    }
                    resetCurrentItem()
                    resetSelection()
                })
            })
            .catch(() => {
                logError("error when confirming item deletion")
            });
    }

    const handleSaveClicked = () => {
        if (isAddPickerDisplayed) {
            if (!currentListing.id || (currentListing.id == noListingId)) {
                addProjectItems();
            } else {
                addListingItems();
            }
        }
    };

    const handleRenameProject = () => {
        setProjectNameDialogOpen(true)
    };

    const handleDeleteProject = () => {
        confirm({
            title: "Supprimer le projet",
            description: "Cette action est irréversible !",
            cancellationText: "Annuler"
        })
            .then(() => {
                return appContext.projectService().deleteProject(project.id).then(() => {
                    navigateTo({name: "Root"})
                })
            })
            .catch(() => {
                logError("error when confirming project deletion")
            });
    };

    const handleSharingUrlRequested = () => {
        if (project.listings.length == 0) {
            confirm({
                title: "Partager le projet",
                description: "Vous n'avez pas encore créé de liste d'articles. Commencez par créer une ou plusieurs listes pour ce projet avant de le partager.",
                cancellationText: "",
            })
                .then(() => {
                })
                .catch(() => {
                });
        } else if (project.listings.filter((l) => l.shared).length == 0) {
            confirm({
                title: "Partager le projet",
                description: "Vous avez créé des listes mais aucune n'est partagée. Commencez par activez le partage de vos listes avant de partager ce projet.",
                cancellationText: "",
            })
                .then(() => {
                })
                .catch(() => {
                });
        } else {
            setSharingDialogOpen(true);
        }
    }

    const handleManageCollaboratorsRequested = () => {
        setCollabDialogOpen(true)
    }

    const handleNewBoardClick = () => {
        resetSelection()
        resetCurrentItem()
        setBoardCreationDialogOpen(true)
    }

    const createBoard = (name: string, originBoardId: string | null, originProjectId: string | null, addItemsToProject: boolean) => {
        let id = uuidv4()
        appContext.projectService().createBoard(id, projectId, name, originBoardId, originProjectId, addItemsToProject, {options: {}}).then((board: Board) => {
            if (addItemsToProject) {
                appContext.projectService().getProject(projectId).then((updatedProject: Project) => {
                    afterBoardCreated(id, updatedProject)
                })
            } else {
                let boards = [...project.boards, board]
                let updatedProject: Project = {...project, boards: boards}
                afterBoardCreated(id, updatedProject)
            }
        })
    }

    const afterBoardCreated = (id: string, project: Project) => {
        updateBoardsAndProjectState(project);
        navigateTo({
            name: 'EditBoard',
            projectId: projectId,
            boardId: id
        });
    }

    const handleEditBoard = () => {
        navigateTo({name: 'EditBoard', projectId: project.id, boardId: currentBoard.id})
    }

    const handleDownloadBoardImage = async () => {
        //let imageDataArray: Blob[] = await appContext.boardService().fetchImages(boardItems.map((bi) => bi.image.url))
        setLoading(true)
        setBoardForDownloadVisible(true)
        setTimeout(() => {
            setDownloadRequested(true)
        }, 500)
    }
    useEffect(() => {
        if (!downloadRequested) {
            return
        }
        html2canvas(document.getElementById(`download-board-canvas`)!!, {useCORS: true, scale: 4}).then((canvas) => {
            const dataURL = canvas.toDataURL('image/jpeg');
            downloadjs(dataURL, "board.jpeg", 'image/jpeg');
            setDownloadRequested(false)
            setLoading(false)
            setBoardForDownloadVisible(false)
        })
    }, [downloadRequested])


    const handleNewListingClick = () => {
        resetSelection()
        resetCurrentItem()
        setCurrentListingName("")
        setListingCreationMode(true)
        setListingNameDialogOpen(true)
    }

    const handleShowListingClick = (listingId: string) => {
        resetSelection()
        resetCurrentItem()
        let menuItem = getListingMenuItem(listingId, menuItems)
        menuItem && handleMenuItemClicked(menuItem)
    }

    const getListingMenuItem = (listingId: string, menuItems: MenuItem[]) => {
        return menuItems.find((m) => (m.menuItemType == "listing_detail") && m.subId == listingId)
    }

    const handleMenuItemClicked = (menuItem: MenuItem) => {
        setAddPickerDisplayed(false)
        if (menuItem.menuItemType == "listing_detail" && menuItem.subId) {
            console.debug("Will display listing_detail")
            navigateTo({
                name: 'ProjectListing',
                projectId: project.id,
                listingId: menuItem.subId
            })
            displayListing(menuItem.subId)
        } else if (menuItem.menuItemType == "board_detail" && menuItem.subId) {
            console.debug("Will display board_detail")
            displayBoard(menuItem.subId)
        } else if (menuItem.menuItemType == "items") {
            console.debug("Will display items")
            navigateTo({name: 'Project', projectId: project.id})
        } else if (menuItem.menuItemType == "boards") {
            console.debug("Will display boards")
            navigateTo({name: 'ProjectBoards', projectId: project.id})
        } else if (menuItem.menuItemType == "listings") {
            console.debug("Will display listings")
            navigateTo({name: 'Project', projectId: project.id})
        }
    }


    const displayListing = (listingId: string) => {
        navigateTo({name: 'ProjectListing', projectId: project.id, listingId: listingId})
    }

    const displayBoard = (boardId: string) => {
        navigateTo({name: 'ProjectBoard', projectId: project.id, boardId: boardId})
    }

    const handleCloseBoardCreationDialog = () => {
        setBoardCreationDialogOpen(false)
    }

    const handleCloseListingItemStatusDialog = () => {
        setListingItemStatusDialogOpen(false)
    }

    const handleCloseListingNameDialog = () => {
        setListingNameDialogOpen(false)
    }

    const handleCloseListingSharingDialog = () => {
        setListingSharingDialogOpen(false)
    }

    const handleProjectNameValidated = (newName: string) => {
        setProjectNameDialogOpen(false)
        renameProject(newName)
    }

    function updateListingsAndProjectState(updatedProject: Project, currentListingId: string) {
        setProject(updatedProject)
        let menuItems = createMenuItems(updatedProject)
        setListingNameDialogOpen(false)
        let listing = updatedProject.listings.find((l) => l.id == currentListingId)
        listing && setCurrentListing(listing)
        let menuItem = getListingMenuItem(currentListingId, menuItems)
        menuItem && setCurrentMenuItem(menuItem)
        if (listing && props.workParams.itemId) {
            let listingItem = listing.items.find((item) => item.id == itemId)
            if (listingItem) {
                setCurrentListingItem(listingItem)
            }
        }
    }

    function updateBoardsAndProjectState(updatedProject: Project) {
        setProject(updatedProject)
        createMenuItems(updatedProject)
        setBoardCreationDialogOpen(false)
        setCurrentMenuItem(boardsMenuItem)
    }

    const createListingWithName = (newListingName: string, originListingId: string | null, originProjectId: string | null) => {
        let id = uuidv4()
        appContext.listingService().createListing(id, projectId, newListingName, originListingId, originProjectId)
            .then((listing) => {
                appContext.projectService().getProject(projectId).then((updatedProject: Project) => {
                    updateListingsAndProjectState(updatedProject, id);
                    displayListing(id)
                })
            })
    }

    const updateListingName = (newListingName: string) => {
        appContext.listingService().renameListing(currentListing.id, newListingName)
            .then((updatedListing: Listing) => {
                const updatedListings = project.listings.map((l) => l.id == currentListing.id ? updatedListing : l)
                let updatedProject = {...project, listings: updatedListings}
                updateListingsAndProjectState(updatedProject, currentListing.id)
            })
    }

    const handlePickedItemsUpdated = (itemsIds: string[]) => {
        setPickedItemsIds(itemsIds)
    }

    const handleDeleteListingRequest = (listingId: string) => {
        confirm({
            title: "Supprimer la liste",
            description: "Cette action est irréversible !",
            cancellationText: "Annuler"
        })
            .then(() => {
                return appContext.listingService().deleteListing(listingId).then(() => {
                    let updatedListings = project.listings.filter((l) => l.id != listingId)
                    let updatedProject = {...project, listings: updatedListings}
                    setProject(updatedProject)
                    createMenuItems(updatedProject)
                    setCurrentListing(noListing)
                    setCurrentMenuItem(listingsMenuItem)
                })
            })
            .catch(() => {
                logError("error when confirming listing deletion")
            });
    }

    const handleRenameListingRequest = (listingId: string) => {
        setCurrentListingName(currentListing.name)
        setListingCreationMode(false)
        setListingNameDialogOpen(true)
    }

    const handleItemClicked = (libraryItemId: string) => {
        if (pickedItemsIds.length > 0) {
            return
        }
        if (currentListing && currentListing.id != noListingId) {
            navigateTo({
                name: 'ListingItem',
                projectId: project.id,
                listingId: listingId!!,
                itemId: libraryItemId
            }, false, filtersToSearchParamsIfDefined(itemFilters))
        } else {
            navigateTo({
                name: 'LibraryItem',
                itemId: libraryItemId,
            }, false, filtersToSearchParamsIfDefined(itemFilters))
        }
    }

    const handleChangeSharing = (listingId: string) => {
        setListingSharingDialogOpen(true)
    }

    const handleSharingOptionsUpdated = (shared: boolean) => {
        appContext.listingService().updateSharing(currentListing.id, shared)
            .then((updatedListing: Listing) => {
                const updatedListings = project.listings.map((l) => l.id == currentListing.id ? updatedListing : l)
                let updatedProject = {...project, listings: updatedListings}
                updateListingsAndProjectState(updatedProject, currentListing.id)
                setListingSharingDialogOpen(false)
            })
    }

    const handleSingleItemStatusClicked = (listingItemId: string) => {
        let listingItem = currentListing.items.find((i) => i.listingItemId == listingItemId)!!
        setEditedListingItems([listingItem])
        setListingItemStatusDialogOpen(true)
    }

    const handleFiltersChanged = (filters: ItemFilters) => {
        setItemFilters(filters)
    }

    const handleSelectionStatusClicked = () => {
        let listingItems = currentListing.items.filter((i) => pickedItemsIds.includes(i.id))
        setEditedListingItems(listingItems)
        setListingItemStatusDialogOpen(true)
    }

    const handleUpdateListingItemsStatuses = (newStatus: string) => {
        setListingItemStatusDialogOpen(false)
        appContext.listingService().updateStatus(currentListing.id, editedListingItems.map((l) => l.listingItemId!!), newStatus).then((updatedListing: Listing) => {
            resetSelection()
            const updatedListings = project.listings.map((l) => l.id == currentListing.id ? updatedListing : l)
            let updatedProject = {...project, listings: updatedListings}
            updateListingsAndProjectState(updatedProject, currentListing.id)
        })
    }

    const handleAddComment = (itemId: string, listingId: string, commentText: string) => {
        let id = uuidv4()
        appContext.listingService().addComment(id, currentListing.id, currentListingItem.id, commentText)
            .then((comment: Comment) => {
                const updatedItem = {...currentListingItem, comments: [...currentListingItem.comments, comment]}
                const updatedItems = currentListing.items.map((i) => i.id == currentListingItem.id ? updatedItem : i)
                const updatedListing = {...currentListing, items: updatedItems}
                const updatedListings = project.listings.map((l) => l.id == currentListing.id ? updatedListing : l)
                let updatedProject = {...project, listings: updatedListings}
                updateListingsAndProjectState(updatedProject, currentListing.id)
            })
    }

    const handleDeleteComment = (commentId: string) => {
        appContext.listingService().deleteComment(commentId, currentListing.id, currentListingItem.id)
            .then(() => {
                const updatedItem = {
                    ...currentListingItem,
                    comments: [...currentListingItem.comments.filter((c) => c.commentId != commentId)]
                }
                const updatedItems = currentListing.items.map((i) => i.id == currentListingItem.id ? updatedItem : i)
                const updatedListing = {...currentListing, items: updatedItems}
                const updatedListings = project.listings.map((l) => l.id == currentListing.id ? updatedListing : l)
                let updatedProject = {...project, listings: updatedListings}
                updateListingsAndProjectState(updatedProject, currentListing.id)
            })
    }

    useEffect(() => {
        if (!props.workParams) {
            return
        }
        getOrFetchProject(projectId).then((project: Project) => {
            let newMenuItems = createMenuItems(project)
            if (menuItemType == "listing_detail" && listingId) {
                let menuItem = newMenuItems.find((i) => i.menuItemType == "listing_detail" && i.subId == listingId)
                logDebug(`Show listing detail ${listingId}`)
                if (menuItem) {
                    setCurrentMenuItem(menuItem)
                } else {
                    logError("Menu item not found :(")
                }
                let listing = project.listings.find((l) => l.id == listingId)
                listing && setCurrentListing(listing)
                if (listing && itemId) {
                    let listingItem = listing.items.find((item) => item.id == itemId)
                    if (listingItem) {
                        setCurrentListingItem(listingItem)
                    } else {
                    }
                }
            } else if (menuItemType == "board_detail" && boardId) {
                let menuItem = newMenuItems.find((i) => i.menuItemType == "board_detail" && i.subId == boardId)
                if (menuItem) {
                    setCurrentMenuItem(menuItem)
                } else {
                    logError("Menu item not found :(")
                }
                appContext.projectService().getBoard(projectId, boardId).then((board: Board) => {
                    setCurrentBoard(board);
                })
            } else if (menuItemType == "boards") {
                setCurrentMenuItem(boardsMenuItem)
            }

        })
    }, [props.workParams]);

    const getOrFetchProject = (projectId: string, forceRefresh: boolean = false) => {
        let projectPromise: Promise<Project>
        if ((project.id != projectId) || forceRefresh) {
            projectPromise = appContext.projectService().getProject(projectId)
            projectPromise.then((p: Project) => setProject(p))
        } else {
            projectPromise = Promise.resolve(project)
        }
        return projectPromise
    }

    return (
        <MainContainer overflowX={"hidden"} overflowY={"hidden"} margin={"0px"}
                       menu={<ProjectAppBar projectNameDisplayed={true} project={project} listing={currentListing}
                                            onCancelClicked={handleCancelClicked}
                                            onSaveClicked={handleSaveClicked} onRenameProject={handleRenameProject}
                                            onDeleteProject={handleDeleteProject}
                                            onSharingUrlRequested={handleSharingUrlRequested}
                                            onManageCollaboratorsRequested={handleManageCollaboratorsRequested}
                                            isAddPickerMode={isAddPickerDisplayed} selectedItems={pickedItemsIds}
                                            onDeleteItems={handleDeleteItems}
                                            onChangeItemsStatus={handleSelectionStatusClicked}
                                            onLogout={handleLogout}
                                            onArchiveProject={handleArchiveProject}/>}>
            <div
                css={css`
                  width: 100%;
                  margin-top: 90px;
                  margin-left: 30px;
                `}>

                <Box sx={{display: 'flex'}}>

                    {!isAddPickerDisplayed &&
                        <ProjectDrawer project={project} currentMenuItem={currentMenuItem} menuItems={menuItems}
                                       onMenuItemClicked={handleMenuItemClicked}/>
                    }

                    <Box css={css`
                      width: 100%;
                      height: ${mainPanelHeight}px;
                      ${!currentListingItem || (currentListingItem == noItem) ? "overflow-y: scroll;" : ""}
                    `}>

                        {
                            /* Items section*/
                        }
                        <div style={{marginBottom: "20px"}}>
                            {currentMenuItem.menuItemType == "listings" &&
                                <Box>
                                    <Typography variant="h3" component="div" gutterBottom>Articles
                                    </Typography>

                                    <ProjectItemsView isPickerDisplayed={isAddPickerDisplayed}
                                                      project={project} pickedItemsIds={pickedItemsIds}
                                                      onPickedItemsUpdated={handlePickedItemsUpdated}
                                                      onClickPickItems={handleClickPickItems}
                                                      onCancelReactionRequested={() => null}
                                                      onNewListingRequested={handleNewListingClick}
                                                      onShowListingRequested={handleShowListingClick}
                                                      onDeleteListingRequest={() => null}
                                                      onRenameListingRequest={handleRenameListingRequest}
                                                      onChangeSharingRequest={handleChangeSharing}
                                                      onStatusClicked={() => null}
                                                      onFiltersChanged={handleFiltersChanged}
                                                      onItemClicked={handleItemClicked}/>
                                </Box>
                            }
                            {currentMenuItem.menuItemType == "listing_detail" && currentListing && (currentListing != noListing) && (!currentListingItem || (currentListingItem == noItem)) &&
                                <Box>
                                    <Box css={css`display: flex;
                                      align-items: center`}>
                                        <Typography css={css`margin-right: 20px`} variant="h3" component="div"
                                                    gutterBottom>{currentListing.name}</Typography>
                                        {!isAddPickerDisplayed && currentListing.shared &&
                                            <Typography css={css`cursor: pointer;
                                              background-color: ${colors.clearGrey2};
                                              padding: 5px;
                                              border-radius: 5px;
                                              margin-right: 20px;
                                              font-size: 1em;
                                              color: ${colors.black};
                                              margin-bottom: 0.7em`
                                            }
                                                        onClick={() => handleChangeSharing(currentListing.id)}
                                                        component="div" gutterBottom><VisibilityIcon
                                                css={css`font-size: 1.2em;
                                                  margin-bottom: 0.2em`}/> Partagée</Typography>
                                        }
                                        {!isAddPickerDisplayed && !currentListing.shared &&
                                            <Typography css={css`cursor: pointer;
                                              background-color: ${colors.white};
                                              padding: 5px;
                                              border-radius: 5px;
                                              margin-right: 20px;
                                              font-size: 1em;
                                              color: ${colors.darkgrey};
                                              margin-bottom: 0.7em`} component="div" gutterBottom><VisibilityOffIcon
                                                css={css`font-size: 1.2em;
                                                  margin-bottom: 0.2em`}/> Privée</Typography>
                                        }
                                    </Box>
                                    <ProjectItemsView isPickerDisplayed={isAddPickerDisplayed}
                                                      project={project} pickedItemsIds={pickedItemsIds}
                                                      listing={currentListing}
                                                      onPickedItemsUpdated={handlePickedItemsUpdated}
                                                      onCancelReactionRequested={() => null}
                                                      onClickPickItems={handleClickPickItems}
                                                      onNewListingRequested={handleNewListingClick}
                                                      onShowListingRequested={handleShowListingClick}
                                                      onDeleteListingRequest={handleDeleteListingRequest}
                                                      onRenameListingRequest={handleRenameListingRequest}
                                                      onChangeSharingRequest={handleChangeSharing}
                                                      onStatusClicked={handleSingleItemStatusClicked}
                                                      onFiltersChanged={handleFiltersChanged}
                                                      onItemClicked={handleItemClicked}/>
                                </Box>
                            }
                            {currentMenuItem.menuItemType == "listing_detail" && currentListing && (currentListingItem && (currentListingItem != noItem)) &&
                                <div css={css`
                                  margin-right: 30px;
                                `}
                                >
                                    <ListingItemDetail height={mainPanelHeight} project={project}
                                                       listing={currentListing}
                                                       userInfos={userInfos!!}
                                                       item={currentListingItem} addCommentRequested={handleAddComment}
                                                       deleteCommentRequested={handleDeleteComment}
                                                       onCancelReactionRequested={() => null}
                                                       onStatusClicked={handleSingleItemStatusClicked}/>
                                </div>
                            }
                            {currentMenuItem.menuItemType == "boards" &&
                                <Box>
                                    <Typography variant="h3" component="div" gutterBottom>Planches</Typography>
                                    <div style={{
                                        display: "flex",
                                        marginBottom: "30px",
                                        flexWrap: "wrap",
                                    }}>
                                        {
                                            /* Boards section*/
                                            project.boards.map((board) => (
                                                <ProjectBoardCard board={board} projectId={projectId}/>
                                            ))

                                        }
                                    </div>
                                    <Button startIcon={<IsoIcon/>} onClick={handleNewBoardClick}>
                                        Ajouter une nouvelle planche
                                    </Button>
                                </Box>
                            }
                            {currentMenuItem.menuItemType == "board_detail" &&
                                <Box>
                                    <Typography variant="h3" component="div"
                                                gutterBottom>{currentBoard.name}</Typography>
                                    <Box sx={{display: 'flex'}}>
                                        <Box css={{paddingRight: "20px"}}>
                                            <BoardReadOnlyCanvas elementId="preview-board-canvas" boardWidth={339}
                                                                 boardHeight={290}
                                                                 boardOffset={{x: 0, y: 0}}
                                                                 boardItems={currentBoard.content.items}
                                            />
                                        </Box>
                                        <Box>
                                            <Button onClick={handleEditBoard} css={{"display": "block"}}>
                                                Modifier
                                            </Button>
                                            <Button onClick={handleDownloadBoardImage} css={{"display": "block"}}>
                                                Télécharger
                                            </Button>
                                        </Box>
                                    </Box>
                                    {boardForDownloadVisible &&
                                        <Box css={{marginTop: "8000px"}}>
                                            <BoardReadOnlyCanvas elementId="download-board-canvas"
                                                                 boardWidth={boardWidth} boardHeight={boardHeight}
                                                                 boardOffset={{x: 0, y: 0}}
                                                                 boardItems={currentBoard.content.items}
                                            />
                                        </Box>
                                    }
                                </Box>
                            }
                        </div>
                    </Box>
                </Box>
            </div>
            <ProjectNameDialog projectName={projectName}
                               open={projectNameDialogOpen}
                               onClose={handleCloseProjectNameDialog}
                               onNameValidated={handleProjectNameValidated}
                               creationMode={false}
            />
            <ProjectCollaborationDialog
                project={project}
                selectedValue={"someUrl"}
                open={collabDialogOpen}
                onClose={handleCloseCollabDialog}
                onCollaboratorAdded={handleAddCollab}
                onCollaboratorRemoved={(email: string) => handleUnshare([email])}
                onCollaboratorsRemoved={handleUnshare}
            />
            <ListingNameDialog creationMode={listingCreationMode} name={currentListingName} open={listingNameDialogOpen}
                               onClose={handleCloseListingNameDialog}
                               onNameValidated={(name: string, originListingId: string | null, originProjectId: string | null) => listingCreationMode ? createListingWithName(name, originListingId, originProjectId) : updateListingName(name)}/>
            <ListingSharingDialog listing={currentListing} open={listingSharingDialogOpen}
                                  onClose={handleCloseListingSharingDialog}
                                  onSharingOptionsUpdated={handleSharingOptionsUpdated}/>
            <BoardCreationDialog creationMode={true} name={""} open={boardCreationDialogOpen}
                                 onClose={handleCloseBoardCreationDialog}
                                 onValidated={(name: string, originBoardId: string | null, originProjectId: string | null, addItemsToProject: boolean) => createBoard(name, originBoardId, originProjectId, addItemsToProject)}/>
            <ListingItemStatusDialog listingItems={editedListingItems}
                                     onClose={handleCloseListingItemStatusDialog} open={listingItemStatusDialogOpen}
                                     onStatusValidated={(newStatus) => handleUpdateListingItemsStatuses(newStatus)}/>
        </MainContainer>
    )
};
