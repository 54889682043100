/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import * as React from 'react';
import {MainContainer} from '../layout/MainContainer';
import {Box, Button, Typography} from "@mui/material";
import {appContext} from "ApplicationContext";
import {primaryLinkStyle} from "shared/styles/common-styles";
import {navigateTo} from "utils/routing-utils";

export const ExtensionTutorialView = () => {

    const handleDone = async () => {
        await appContext.userService().updatePreferences({chromeExtensionTutorialDone: true})
        navigateTo({name: "Root"})
    }

    const handleNotWorking = () => {
        // Simulate clicking on mailto link to open email client
        window.location.href = "mailto:support@biblo.app"
    }

    return (
        <MainContainer menu={<Box/>}>
            <div
                css={css`
                  width: 100%;
                  margin-top: 70px;
                `}
            >
                <Box>
                    <Typography variant="h3" component="div" gutterBottom
                    >
                        Configurez l'extension Biblo
                    </Typography>
                    <p>
                        <Typography variant="body1" component="div" gutterBottom>
                            L'extension Biblo vous permet de sauvegarder des articles directement depuis votre navigateur Chrome,
                            afin de les ajouter simplement à votre bibliothèque et à vos projets.
                        </Typography>
                    </p>
                    <p>
                    <Typography variant="h5" component="div" gutterBottom>
                        Installation de l'extension
                    </Typography>
                    </p>
                    <p>
                        <Typography variant="body1" component="div" gutterBottom>
                        <ul>
                            <li>Rendez-vous sur le Chrome Web Store sur la <a css={{primaryLinkStyle}} href={"https://chromewebstore.google.com/detail/jifjfcndejjcamdnbgcmkeokfmnlgkog"} target={"_blank"}>page de l'extension Biblo</a></li>
                            <li>Cliquez sur le bouton "Ajouter à Chrome" (et validez si besoin en cliquant sur "Ajouter l'extension")</li>
                        </ul>
                            <p>Une fois l'extension installée, elle sera accessible via l'icône Biblo située à droite de la barre d'adresse de votre navigateur :</p>
                            <img src={"biblo_extension_icon_display.png"} alt={"Emplacement de l'extension Biblo"} height={"40px"}/>
                        </Typography>
                        Vous n'aurez plus qu'à cliquer dessus dès que vous souhaiterez enregistrer un article depuis une page web !
                    </p>

                    <Box>
                        <Button onClick={handleDone} variant={"contained"}>C'est fait !</Button>
                        <Button onClick={handleNotWorking} variant={"text"}>J'ai besoin d'aide</Button>
                    </Box>

                </Box>
            </div>

        </MainContainer>
    );
};
