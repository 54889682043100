/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import {useEffect, useState} from 'react';
import { appContext } from 'ApplicationContext';
import { MainContainer } from '../layout/MainContainer';
import {RegisterResult, UserInfos} from 'shared/model/user';
import { RegisterForm, RegisterFormDto } from './RegisterForm';
import {RegistrationResponseDto} from "shared/services/RegistrationService";
import {colors} from "shared/styles/vars";
import MainAppBar from "containers/MainAppBar";
import {navigateTo} from "utils/routing-utils";
import {useUserState} from "state/UserState";

export const RegisterView = () => {
  const connected = useUserState(s => s.connected);
  const setConnected = useUserState(s => s.setConnected);

  const [registerResult, setRegisterResult] = useState<
    RegisterResult | undefined
  >(undefined);

  const [specialCode, setSpecialCode] = useState<string | null>(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    setSpecialCode(code)
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    setSpecialCode(code)
  }, []);

  const onChange = () => {
    setRegisterResult(undefined);
  }

  const register = (registerInput: RegisterFormDto) => {
    appContext.registrationService().register(registerInput.mail, registerInput.password, specialCode)
    .then((r: RegistrationResponseDto) => {
      if (r.registered) {
        setRegisterResult("registered");
        return appContext.loginService().login(registerInput.mail, registerInput.password)
      } else {
        setRegisterResult(r.error as RegisterResult);
        throw new Error(r.error);
      }
    }).then(() => {
      return appContext.userService().getUserInfos();
    }).then((r: UserInfos) => {
      setConnected(true, r)
      navigateTo({ name: 'Root' });
    }).catch((e) => {
      console.log(e);
    })
  };

  return (

    <MainContainer menu={<MainAppBar/>} justify={'center'}>
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-top: 60px;
        `}
      >
        { specialCode &&
        <div>
          <h1
              css={css`
                text-align: center;
                margin-bottom: 30px;
              `}
          >
            Créez votre compte Biblo
          </h1>
          <div
            css={css`
              width: 400px;
            `}
          >
            {registerResult !== 'registered' && !connected && (
              <RegisterForm onSubmit={register} onChange={onChange}/>
            )}
            {connected && (
              <div
                css={css`
                  text-align: center;
                `}
              >
                Vous êtes connecté(e)
              </div>
            )}
            {registerResult && registerResult !== 'registered' &&
            <div
                css={css`
                  text-align: center;
                  color: ${colors.errorRed};
                  margin-top: 20px;
                `}
            >
              {registerResult === 'MAIL_ALREADY_TAKEN' && (
                  <span>
                      Cette adresse e-mail est déjà utilisée
                    </span>
              )}
              {registerResult === 'USERNAME_ALREADY_TAKEN' && (
                  <span>
                      Ce nom d'utilisateur est déjà utilisé
                    </span>
              )}
              {registerResult === 'INVALID_MAIL_FORMAT' && (
                  <span>
                      Cette adresse e-mail n'est pas valide
                    </span>
              )}
              {registerResult === 'INVALID_PASSWORD_FORMAT' && (
                  <span>
                      Le format de ce mot de passe n'est pas valide
                    </span>
              )}
              {registerResult === 'INVALID_USERNAME_FORMAT' && (
                  <span>
                      Le format de ce nom d'utilisateur n'est pas valide
                    </span>
              )}
              {registerResult === 'PUBLIC_REGISTRATION_NOT_OPEN' && (
                  <span>
                      Les inscriptions ne sont pas encore ouvertes au public... Revenez très bientôt !
                    </span>
              )}
            </div>
            }
          </div>
        </div>
        }
        {
          !specialCode &&
            <div
                css={css`
              width: 400px;
            `}
            >
                  <div
                      css={css`
                  text-align: center;
                `}
                  >
                    <p>Les inscriptions seront bientôt ouvertes.</p><p>Revenez très bientôt !</p>
                  </div>
            </div>
        }
      </div>

    </MainContainer>
  );
};
