/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {appContext} from 'ApplicationContext';
import {MainContainer} from '../layout/MainContainer';
import {LoginResult} from 'shared/model/user';
import {LoginForm, LoginFormDto} from './LoginForm';
import {assertUnreachable, logDebug, logError, publicRegistrationOpen} from 'shared/utils';
import MainAppBar from "containers/MainAppBar";
import {Divider, Typography} from "@mui/material";
import {navigateTo} from "utils/routing-utils";
import {useUserState} from "state/UserState";

export const LoginView = () => {
    const connected = useUserState(s => s.connected);
    const setConnected = useUserState(s => s.setConnected);

    const [registrationLinkVisible, setRegistrationLinkVisible] = useState(publicRegistrationOpen)
    const [specialCode, setSpecialCode] = useState<string | null>(null)
    const [loginResult, setLoginResult] = useState<LoginResult | undefined>(
        undefined
    );
    const [loginInProgress, setLoginInProgress] = useState(false)

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code');
        setSpecialCode(code)
    }, []);

    const login = async (data: LoginFormDto) => {
        logDebug("Trying to login...")
        setLoginInProgress(true)
        try {
            await appContext
                .loginService().login(data.username, data.password)
            setLoginResult('loggedIn');
            await appContext.csrfTokenService().refreshToken();
            let userInfos = await appContext.userService().getUserInfos();
            setLoginInProgress(false)
            setConnected(true, userInfos)
            navigateTo({name: 'Root'});
        } catch (e) {
            logError("User not found")
            setLoginInProgress(false)
            setLoginResult('userNotFound');
        }
    }

    return (
        <MainContainer menu={<MainAppBar/>} justify='center'>
            <div
                css={css`
                  display: flex;
                  justify-content: center;
                  margin-top: 60px;
                `}
            >
                <div>
                    <h1
                        css={css`
                          text-align: center;
                          margin-bottom: 30px;
                          width: 400px;
                        `}
                    >
                        Connexion
                    </h1>
                        {loginResult !== 'loggedIn' && !connected && (
                            <div>
                                <LoginForm onSubmit={login} loginInProgress={loginInProgress}/>
                            </div>
                        )}
                        {loginResult &&
                            <div
                                            css={css`
                              text-align: center;
                              margin-top: 20px;
                            `}>
                                { connected && (
                                    <div
                                        css={css`
                                          text-align: center;
                                        `}
                                    >
                                        <Typography variant="body1">Connexion réussie !</Typography>
                                    </div>
                                )}
                                {
                                    loginInProgress && <Typography variant="body1" color="info">Connexion en cours...</Typography>
                                }
                                { !loginInProgress && (function () {
                                    switch (loginResult) {
                                        case 'loggedIn':
                                            return null;
                                        case 'userNotFound':
                                            return <Typography variant="body1" color="error">Adresse e-mail ou mot de passe incorrect</Typography>;
                                        default:
                                    }
                                })()
                                }
                            </div>
                        }
                        {(registrationLinkVisible || specialCode) &&

                            <div css={{marginTop: "20px",textAlign: "center"}}>
                                <Divider/>
                                <p>
                                    <Typography variant="body2">
                                        Vous n'avez pas encore de compte Biblo ? <a
                                        href={`/register${specialCode ? "?code=" + specialCode : ""}`}>Inscrivez-vous</a>
                                    </Typography>
                                </p>
                            </div>
                        }
                    </div>
            </div>
        </MainContainer>
    );
};
