/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Collaborator, Project} from "shared/model/model";
import {css} from "@emotion/react";
import {
    Box,
    DialogContent,
    FormControl,
    FormControlLabel,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import {colors} from "shared/styles/vars";
import {isValidEmailAddress} from "shared/utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from "@mui/material/Switch";
import {EventSnackBar} from "view/components/EventSnackBar";
import {useUserState} from "state/UserState";

export interface Props {
    project: Project
    open: boolean
    selectedValue: string
    onClose: () => void
    onCollaboratorAdded: (email: string, role: string, notify: boolean) => void
    onCollaboratorsRemoved: (emails: string[]) => void
    onCollaboratorRemoved: (email: string) => void
}

type ProjectCollaborationPanel = "collaborations" | "share"

export const ProjectCollaborationDialog = (props: Props) => {
    const userInfos = useUserState(s => s.userInfos);

    const {onClose, open} = props;
    const [email, setEmail] = useState('')
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [emailAlreadyInvited, setEmailAlreadyInvited] = useState(false);
    const [role, setRole] = useState<string>('COMMENTER')
    const [currentPanel, setCurrentPanel] = useState<ProjectCollaborationPanel>("collaborations")
    const [collabOrInvites, setCollabOrInvites] = useState<Collaborator[]>([])

    const [snackBarDisplayed, setSnackBarDisplayed] = useState(false);
    const [collabAdded, setCollabAdded] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [sendEmailEnabled, setSendEmailEabled] = useState(true);

    const [emailsPendingDeletion, setEmailsPendingDeletion] = useState<string[]>([]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
        let enteredEmail = e.target.value
        setEmailIsValid(isValidEmailAddress(enteredEmail))
        setEmailAlreadyInvited(props.project.collaborators.map((c) => c.collaboratorMail).includes(enteredEmail))
    }

    const handleRoleChange = (e: SelectChangeEvent) => {
        setRole(e.target.value)
    }

    const handleSendEmailChange = () => {
        setSendEmailEabled(!sendEmailEnabled)
    }

    const handleShowSharePanelClick = () => {
        setCurrentPanel("share")
    }

    const handleBackClick = () => {
        setCurrentPanel("collaborations")
    }

    const handleCancelShare = () => {
        if (collabOrInvites.length > 0) {
            setEmail("")
            setEmailIsValid(false)
            setEmailAlreadyInvited(false)
            setRole("COMMENTER")
            setCurrentPanel("collaborations")
        } else {
            handleCloseDialog()
        }
    }

    const addCollab = () => {
        setCollabAdded(true)
        props.onCollaboratorAdded(email, role, sendEmailEnabled)
    }

    const removeCollab = (emailToRemove: string) => {
        setEmailsPendingDeletion([...emailsPendingDeletion, emailToRemove])
    }

    const copyUrlToClipboard = (token: String) => {
        navigator.clipboard.writeText(getSharingUrl(token)).then(() => {
            setSnackBarMessage("Lien copié")
            setSnackBarDisplayed(true)
        });
    }

    const getSharingUrl = (sharingToken: String) => {
        return window.location.protocol + "//" + window.location.host + "/v/" + sharingToken
    }

    const reset = (resetSnackBar: boolean = true) => {
        setCollabAdded(false)
        setEmail('')
        setEmailIsValid(false)
        setEmailAlreadyInvited(false)
        setRole('COMMENTER')
        setEmailsPendingDeletion([])
        if (resetSnackBar) {
            setSnackBarDisplayed(false)
        }
        setSendEmailEabled(true)
    }

    useEffect(() => {
        console.log(`collabAdded: ${collabAdded}`)
        if (collabAdded) {
            if (sendEmailEnabled) {
                setSnackBarMessage(`Invitation envoyée à ${email}`)
                setSnackBarDisplayed(true)
            } else if (role == "COMMENTER") {
                setSnackBarMessage(`Lien personnalisé créé pour ${email}`)
                setSnackBarDisplayed(true)
            }
        } else if (emailsPendingDeletion.length > 0) {
            setSnackBarMessage(`Paramètres de partage mis à jour`)
            setSnackBarDisplayed(true)
        }
        setEmail('')
        reset(!collabAdded && (emailsPendingDeletion.length == 0))
    }, [props.project])

    useEffect(() => {
        let nonDeletedCollabs = props.project.collaborators.filter((c) => !emailsPendingDeletion.includes(c.collaboratorMail))
        setCollabOrInvites(nonDeletedCollabs)
        if ((nonDeletedCollabs.length == 0) && (emailsPendingDeletion.length == 0)) {
            setCurrentPanel("share")
        } else {
            setCurrentPanel("collaborations")
        }
    }, [props.project, emailsPendingDeletion])

    const handleCloseDialog = () => {
        props.onCollaboratorsRemoved(emailsPendingDeletion)
        reset()
        setSnackBarDisplayed(false)
        onClose();
    };

    const handleApplyDeletions = () => {
        props.onCollaboratorsRemoved(emailsPendingDeletion)
    };

    const handleCancelRemoveCollabs = () => {
        reset()
        setSnackBarDisplayed(false)
        onClose();
    }

    const handleSnackBarClosed = () => {
        setSnackBarDisplayed(false)
    }

    return (
        <Dialog onClose={handleCloseDialog} open={open} sx={{style: {width: "700px"}}}
                fullWidth={true}>
            <DialogTitle>
                {(currentPanel == 'share') && (collabOrInvites.length > 0) &&
                    <IconButton
                        aria-label="retour"
                        onClick={handleBackClick}
                        edge="start"
                    >
                        <ArrowBackIcon/>
                    </IconButton>
                }

                Partager le projet</DialogTitle>
            <DialogContent>
                {(currentPanel == 'share') &&
                    <Box id={"share-panel"}>
                        <Box css={{display: "flex", flexDirection: "column"}}>
                            <Typography color={colors.verydarkgrey}>Inviter cette personne à accéder au projet
                                :</Typography>
                            <FormControl>
                                <Box css={css`
                                  margin-top: 10px;
                                  display: flex;
                                  justify-content: space-between;

                                  .emailAddress {
                                    padding-right: 10px;
                                  }
                                `}>
                                    <TextField id="share-email" className={"emailAddress"} value={email}
                                               fullWidth={true} placeholder={"Adresse email"}
                                               onChange={handleEmailChange}/>
                                    <div css={css`
                                      font-size: 0.9em;
                                      margin-left: 10px;
                                      margin-right: 20px;
                                      margin-top: 20px;
                                      width: 150px;
                                    `}>en tant que :
                                    </div>
                                    <Select
                                        value={role}
                                        onChange={handleRoleChange}
                                        variant="standard"
                                    >
                                        <MenuItem value={"COMMENTER"} key={"COMMENTER"}>Commentateur</MenuItem>
                                        {userInfos && ((userInfos!!.mail == 'contact@atelier428.com') || (userInfos!!.mail == 'eric.therond@gmail.com')) &&
                                            <MenuItem value={"EDITOR"} key={"EDITOR"}>Co-concepteur</MenuItem>
                                        }
                                    </Select>
                                </Box>
                                <Box css={css`
                                  margin-top: 10px;
                                `}>
                                    {(role == 'COMMENTER') &&
                                        <Typography variant="body2">
                                            Cette personne pourra uniquement consulter et commenter les articles
                                            présents
                                            dans les listes partagées.
                                        </Typography>
                                    }
                                    {(role == 'EDITOR') &&
                                        <Typography variant="body2">
                                            Cette personne pourra effectuer les mêmes opérations que vous sur ce projet,
                                            excepté le supprimer ou le partager avec d'autres personnes.
                                        </Typography>
                                    }
                                </Box>
                                <Box css={css`
                                  margin-top: 10px;
                                `}>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={sendEmailEnabled} onChange={handleSendEmailChange}
                                                    name="sendEmail"/>
                                        }
                                        label={`Notifier cette personne par e-mail`}
                                    />
                                </Box>
                            </FormControl>
                            {(emailAlreadyInvited) &&
                                <Box css={css`
                                  color: ${colors.errorRed}
                                `}>
                                    Cette adresse email a déjà accès au projet.
                                </Box>
                            }
                            <Box css={css`
                              margin-top: 40px;
                              display: flex;
                              justify-content: flex-end;

                              Button {
                                margin-left: 20px;
                              }
                            `}>

                                <Button variant="text" onClick={handleCancelShare}>Annuler</Button>
                                <Button variant="contained" onClick={addCollab}
                                        disabled={!emailIsValid || emailAlreadyInvited}>Partager</Button>
                            </Box>
                        </Box>
                    </Box>
                }
                {(currentPanel == 'collaborations') &&
                    <Box id={"collaborations-panel"}>
                        {collabOrInvites.length > 0 &&
                            <Box>
                                <Typography variant="body1" style={{margin: "10px 0px 10px 0px", fontWeight: "bold"}}
                                            color={colors.verydarkgrey}>Utilisateurs avec accès au projet :</Typography>
                                <Box style={{maxHeight: "300px", overflowY: "scroll"}}>
                                    {
                                        collabOrInvites.map((collab) => (
                                            <div key={collab.collaboratorMail}
                                                 style={{display: "flex", flexDirection: "row", marginBottom: "15px"}}>
                                                <div style={{flexGrow: "1"}}><span>{collab.collaboratorMail}</span>
                                                    {collab.inviteToken &&
                                                        <Button
                                                            onClick={() => copyUrlToClipboard(collab.inviteToken!!)}>Copier
                                                            son lien d'accès</Button>
                                                    }
                                                </div>
                                                <div><span
                                                    style={{cursor: "default"}}>{collab.role == 'EDITOR' ? "Co-concepteur" : "Commentateur"}</span> [
                                                    <span
                                                        style={{color: colors.darkgrey, cursor: "pointer", top: "0.4em"}}
                                                        onClick={() => removeCollab(collab.collaboratorMail)}>
                                                        <DeleteIcon/>
                                                    </span>
                                                    ]
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Box>
                            </Box>
                        }
                    </Box>
                }
                {
                    (currentPanel == 'collaborations') && (emailsPendingDeletion.length == 0) &&
                    <Box css={css`
                      margin-top: 10px;
                    `}>
                        <Button variant={"outlined"} onClick={handleShowSharePanelClick}>Inviter d'autres
                            personnes...</Button>
                    </Box>
                }
                {(currentPanel == 'collaborations') &&
                    <Box css={css`
                      margin-top: 40px;
                    `}>
                        {(emailsPendingDeletion.length > 0) &&
                            <Box css={css`
                              display: flex;
                              justify-content: flex-end;
                              color: ${colors.infoMain}
                            `}>
                                {emailsPendingDeletion.length == 1 ? "1 accès au projet sera supprimé" : ""}
                                {emailsPendingDeletion.length > 1 ? `${emailsPendingDeletion.length} accès au projet seront supprimés` : ""}
                            </Box>
                        }

                        {(currentPanel == 'collaborations') &&
                            <Box css={css`
                              margin-top: 20px;
                              display: flex;
                              justify-content: flex-end;

                              Button {
                                margin-left: 20px;
                              }
                            `}>
                                {(emailsPendingDeletion.length > 0) &&
                                    <div>
                                        <Button variant="text" onClick={handleCancelRemoveCollabs}>Annuler</Button>
                                        <Button variant="contained" onClick={handleApplyDeletions}>Appliquer</Button>
                                    </div>
                                }
                                {(emailsPendingDeletion.length == 0) &&
                                    <Button variant="contained" onClick={handleCloseDialog}>Terminer</Button>
                                }
                            </Box>
                        }
                    </Box>
                }
            </DialogContent>
            <EventSnackBar displayed={snackBarDisplayed} message={snackBarMessage} severity={"success"}
                           onClosed={handleSnackBarClosed}/>
        </Dialog>
    );
}