/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {ProjectComponent} from "./ProjectComponent";
import {useTypedParams} from "utils/routing-utils";
import {ProjectPath} from "generated/routing/ApplicationPath.generated";

export const ProjectView = () => {
    const routeParams = useTypedParams<ProjectPath>();

    return (
        <ProjectComponent
            workParams={{projectId: routeParams.projectId, listingId: null, itemId: null, boardId: null, menuItemType: "items"}}/>
    )
};
