/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@mui/material';
import * as React from 'react';
import {ChangeEvent, FocusEvent, useEffect, useState} from 'react';
import { appContext } from 'ApplicationContext';
import { PasswordInput } from '../components/PasswordInput';
import { SimpleForm } from '../components/SimpleForm';
import { TextInput } from '../components/TextInput';
import {ValidationResponseDto} from "shared/services/RegistrationService";

export interface RegisterFormDto {
  mail: string;
  password: string
}

interface Props {
  onSubmit: (dto: RegisterFormDto) => void;
  onChange?: () => void;
}

export const RegisterForm = (props: Props) => {
  const [passwordTyped, setPasswordTyped] = useState(false);
  const [mailIsValid, setMailIsValid] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [password, setPassword] = useState('');

  const onMailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMailIsValid(event.target.value.length > 0);
    if (props.onChange) {
      props.onChange();
    }
  };

  const onPasswordChange = () => {
    setPasswordTyped(true);
    if (props.onChange) {
      props.onChange();
    }
  };

  const checkPasswordValid = (password: String) => {
    setPasswordIsValid(password.length >= 8);
  }

  useEffect(() => {
    checkPasswordValid(password);
  }, [password]);

  return (
    <SimpleForm
      onSubmit={(dto: { mail: string; }) =>
        props.onSubmit({
          mail: dto.mail,
          password
        })
      }
    >
      <div
        css={css`
          margin: 10px 0;
        `}
      >
        <TextInput
          name="mail"
          label={'Email'}
          onChange={onMailChange}
        />
      </div>
      <div
        css={css`
          margin: 20px 0;
        `}
      >
        <PasswordInput
          label="Password"
          value={password}
          setValue={setPassword}
          onChange={onPasswordChange}
        />
      </div>
      {!passwordIsValid && passwordTyped && (
          <div className="form-field-error"
              css={css`
            margin: 10px 0;
          `}
          >
            Le mot de passe doit comporter au moins 8 caractères
          </div>
      )}
      <div
          css={css`
            text-align: center
          `}
      >
      <Button type="submit" disabled={!passwordIsValid || !mailIsValid} variant="outlined">Créer mon compte</Button>
      </div>
    </SimpleForm>
  );
};
