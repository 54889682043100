/** @jsxImportSource @emotion/react */
import {css} from "@emotion/react";
import React, {useState} from 'react'
import {Item, Project} from "shared/model/model";
import {Card, CardActionArea, CardContent, CardMedia, Typography} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

interface Props {
    project: Project,
}

export const ProjectCard = (props: Props) => {

    const [name] = useState(props.project.name);

    return (
    <Card variant="outlined" sx={{ maxWidth: 280 }}  css={css`margin: 7.5px;`}>
        <CardActionArea css={css`
            min-width: 200px;
            min-height: 200px;
        `}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div"   css={css`
       text-align: center
      `}>
                    {name}
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>

)
};