/** @jsxImportSource @emotion/react */
import * as React from 'react'
import {useCallback, useEffect, useState} from 'react'
import {BoardItem, BoardOptionKey, CropDto, ImageDto, ImageObject, Item, Listing, Project} from "shared/model/model";
import {DndProvider, XYCoord} from "react-dnd";
import {v4 as uuidv4} from 'uuid';
import {noBoardItemId} from "./DragSourceItem";
import {BoardCanvas} from "./BoardCanvas";
import {HTML5Backend} from "react-dnd-html5-backend";
import {Box, Button, Checkbox, FormControlLabel, Typography} from "@mui/material";
import {css} from "@emotion/react";
import {ItemType} from "./ItemTypes";
import {appContext} from "ApplicationContext";
import config from "config";
import {ImageCropperDialog} from "view/library/item/ImageCropperDialog";
import {BoardItemPicker} from "./BoardItemPicker";
import {EmotionJSX} from "@emotion/react/types/jsx-namespace";
import {boardHeight, boardWidth} from "config/settings";
import { logDebug } from 'shared/utils';
import {HttpResponse} from "../../../../../shared/services/HttpService";

interface Props {
    project: Project
    items: Item[]
    allLibraryItems: Item[]
    pickSource: string
    sourceListing: Listing
    selectedBoardItemId: string | null
    selectedItemType: ItemType | null
    boardItems: BoardItem[]
    projectLoaded: boolean
    boardOffset: XYCoord | null
    onBoardItemsUpdated: (boardItems: BoardItem[]) => void
    onBoardOffsetProvided: (offset: XYCoord | null) => void
    onBoardItemSelectionChange: (selectedBoardItemId: string | null, selectedItemType: ItemType | null) => void
    onItemEditionRequested: (itemId: string) => void
    onCaptionCopied: () => void
    onLoading: (loading: boolean) => void
    onPickSourceChanged: (pickSource: string) => void
    onSourceListingChanged: (sourceListingId: string) => void
    captionMode: boolean
    includeBrandCaption: boolean
    includePriceCaption: boolean
    includeDimensionsCaption: boolean
    onOptionUpdated: (key: BoardOptionKey, value: boolean) => void
}

export const BoardEditor = (props: Props) => {

    const [debugMode, setDebugMode] = useState<boolean>(false)


    const [selectedBoardItem, setSelectedBoardItem] = useState<BoardItem | null>(null)
    const [dropped, setDropped] = useState<boolean>(false)
    const [targetBoardItem, setTargetBoardItem] = useState<BoardItem | null>(null)

    // cropping
    const [itemCropperDialogOpen, setItemCropperDialogOpen] = useState<boolean>(false)
    const [cropping, setCropping] = useState(false)
    const [cropRawImageId, setCropRawImageId] = useState('');
    const [crop, setCrop] = useState<CropDto | null>(null);

    const boardItems = props.boardItems

    const [deletePressed, _setDeletePressed] = useState(false)
    const deletePressedRef = React.useRef(deletePressed); // see https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559

    const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0)
    const [history, setHistory] = useState<BoardItem[][]>([]);

    const [ctrlZPressed, _setCtrlZPressed] = useState(false)
    const ctrlZPressedRef = React.useRef(ctrlZPressed);

    const [ctrlYPressed, _setCtrlYPressed] = useState(false)
    const ctrlYPressedRef = React.useRef(ctrlYPressed);

    const [captionElements, setCaptionElements] = useState<EmotionJSX.Element[]>([])

    const includeBrandCaption = props.includeBrandCaption
    const includePriceCaption = props.includePriceCaption
    const includeDimensionsCaption = props.includeDimensionsCaption
    const captionMode = props.captionMode

    const setDeletePressed = (data: boolean) => {
        deletePressedRef.current = data;
        _setDeletePressed(data);
    };

    const setCtrlZPressed = (data: boolean) => {
        ctrlZPressedRef.current = data;
        _setCtrlZPressed(data);
    };

    const setCtrlYPressed = (data: boolean) => {
        ctrlYPressedRef.current = data;
        _setCtrlYPressed(data);
    };

    const handleUndo = () => {
        if (currentHistoryIndex > 0) {
            logDebug(`Set history index to ${currentHistoryIndex - 1}`)
            setCurrentHistoryIndex(currentHistoryIndex - 1);
            props.onBoardItemsUpdated(history[currentHistoryIndex - 1]);
        }
    };

    const handleRedo = () => {
        if (currentHistoryIndex < history.length - 1) {
            logDebug(`Set history index to ${currentHistoryIndex + 1}`)
            setCurrentHistoryIndex(currentHistoryIndex + 1);
            props.onBoardItemsUpdated(history[currentHistoryIndex + 1]);
        }
    };

    const toggleIncludeBrandCaption = () => {
        props.onOptionUpdated("displayBrandCaption", !includeBrandCaption)
    }

    const toggleIncludePriceCaption = () => {
        props.onOptionUpdated("displayPriceCaption", !includePriceCaption)
    }

    const toggleIncludeDimensionsCaption = () => {
        props.onOptionUpdated("displayDimsCaption", !includeDimensionsCaption)
    }

    useEffect(() => {
        if (props.projectLoaded) {
            logDebug(`initialize history with ${props.boardItems.length} board items`)
            setHistory([...history, props.boardItems])
        }
    }, [props.projectLoaded]);

    function createNewBoardItem() {
        let boardItem = targetBoardItem!!
        let maxZ = boardItems.length > 0 ? Math.max(...boardItems.map((i) => i.image.z)) : 0
        return {
            ...targetBoardItem,
            instanceId: uuidv4(),
            image: {
                ...boardItem.image,
                z: maxZ + 1
            },
            ref: {
                x: boardItem.image.x + boardItem.image.w,
                y: boardItem.image.y + boardItem.image.h,
                w: 20,
                h: 20
            },
            refNum: 0
        } as BoardItem;
    }


    const handleKeyPress = useCallback((event: KeyboardEvent) => {
        //logDebug(`Ctrl: ${event.ctrlKey}, Meta: ${event.metaKey}, Alt: ${event.altKey}, Shift: ${event.shiftKey}, z: ${event.key == "z"}, y: ${event.key == "y"}`)
        if (event.key == "Backspace" || event.key == "Delete") {
            setDeletePressed(true)
        } else if ((event.ctrlKey || (event.metaKey)) && (event.key == "z")) {
            logDebug("ctrl+z")
            _setCtrlZPressed(true)
        } else if (((event.ctrlKey || (event.metaKey)) && (event.key == "y")) || ((event.ctrlKey || (event.metaKey)) && event.shiftKey && (event.key == "z"))) {
            logDebug("ctrl+y")
            _setCtrlYPressed(true)
        }
    }, []);

    useEffect(() => {
        if (deletePressed) {
            if (selectedBoardItem) {
                handleItemDeleted(selectedBoardItem.instanceId)
            }
            setDeletePressed(false)
        }
    }, [deletePressed]);

    useEffect(() => {
        if (ctrlZPressed) {
            handleUndo()
        }
        setCtrlZPressed(false)
    }, [ctrlZPressed]);

    useEffect(() => {
        if (ctrlYPressed) {
            handleRedo()
        }
        setCtrlYPressed(false)
    }, [ctrlYPressed]);

    useEffect(() => {
        // attach the event listener - careful, it will belong to the initial render and won't be updated
        // on subsequent rerenders, so it won't have access to the latest state
        document.addEventListener('keydown', handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);


    useEffect(() => {
        if (targetBoardItem != null && dropped) {
            logDebug("useEffect board container - target board item id = " + targetBoardItem.instanceId)
            let newArray = (targetBoardItem.instanceId == noBoardItemId)
                ? [...boardItems,
                    createNewBoardItem()
                ]
                : getArrayWithUpdatedBoardItem(targetBoardItem);
            setTargetBoardItem(null)
            setDropped(false)
            setNewStateAndHistorize(newArray)
        }
    }, [targetBoardItem, boardItems, dropped])

    useEffect(() => {
        setSelectedBoardItem(props.boardItems.find((bi) => bi.instanceId == props.selectedBoardItemId) ?? null)
    }, [props.selectedBoardItemId, props.boardItems])


    function getArrayWithUpdatedBoardItem(boardItem: BoardItem) {
        if (targetBoardItem == null) {
            return boardItems
        }
        let arrayWithoutBoardItem: BoardItem[] = boardItems.filter((p) => p.instanceId != boardItem.instanceId)
        let existingBoardItemWithRightSize = getBoardItem(boardItem.instanceId)!! // potentially the item was resized previously, and the updated/dragged BoardItem doesn't have this info
        let droppedItemWithRightSize;

        if (props.selectedItemType == 'image') {
            droppedItemWithRightSize = {
                ...(existingBoardItemWithRightSize),
                image: {
                    ...existingBoardItemWithRightSize.image,
                    x: targetBoardItem.image.x,
                    y: targetBoardItem.image.y
                }
            } as BoardItem
            let xVariation = targetBoardItem.image.x - existingBoardItemWithRightSize.image.x
            let yVariation = targetBoardItem.image.y - existingBoardItemWithRightSize.image.y
            droppedItemWithRightSize = {
                ...(droppedItemWithRightSize),
                ref: {
                    ...droppedItemWithRightSize.ref,
                    x: (droppedItemWithRightSize.ref?.x ?? droppedItemWithRightSize.image.x + 10) + xVariation,
                    y: (droppedItemWithRightSize.ref?.y ?? droppedItemWithRightSize.image.y + 10) + yVariation
                }
            } as BoardItem
        } else {
            droppedItemWithRightSize = {
                ...(existingBoardItemWithRightSize),
                ref: {
                    ...existingBoardItemWithRightSize.ref,
                    x: targetBoardItem.ref!!.x,
                    y: targetBoardItem.ref!!.y
                }
            } as BoardItem
        }
        return [...arrayWithoutBoardItem, droppedItemWithRightSize]
    }

    const handleItemDragged = (boardItem: BoardItem) => {
        logDebug("handleItemDragged")
        props.onBoardItemSelectionChange(boardItem.instanceId, 'image')
    }

    const handleItemDropped = (boardItem: BoardItem) => {
        logDebug(`handleItemDropped at ${boardItem.image.x}, ${boardItem.image.y}`)
        setTargetBoardItem(boardItem)
    }

    const handleDragAndDropEnd = () => {
        logDebug("handleDragAndDropEnd")
        setDropped(true)
    }

    const handleRefLabelDragged = (boardItem: BoardItem) => {
        logDebug("handleItemDragged")
        props.onBoardItemSelectionChange(boardItem.instanceId, 'refLabel')
    }

    const handleRefLabelDropped = (boardItem: BoardItem) => {
        logDebug(`handleRefLabelDropped at ${boardItem.image.x}, ${boardItem.image.y}`)
        setTargetBoardItem(boardItem)
    }

    const handleRefLabelDragAndDropEnd = (boardItem: BoardItem) => {
        logDebug("handleDragAndDropEnd")
        setDropped(true)
    }


    const handleBoardItemClick = (boardItem: BoardItem) => {
        logDebug(`handleBoardItemClick - ${boardItem.instanceId}`)
        props.onBoardItemSelectionChange(boardItem.instanceId, 'image')
    }

    const handleContainerClick = () => {
        props.onBoardItemSelectionChange(null, null)
    }

    const openLibraryItem = (boardItemId: string) => {
        window.open(`${config.client.url}/items/${boardItemId}`, "_blank")
    }

    const handleItemDeleted = (boardItemId: string) => {
        let boardItem = boardItems.find((bi) => bi.instanceId == boardItemId)!!
        let updatedArray: BoardItem[] = boardItems.filter((bi) => bi.instanceId != boardItemId)
        setNewStateAndHistorize(updatedArray)
    }

    const setNewStateAndHistorize = (boardItems: BoardItem[]) => {
        props.onBoardItemsUpdated(boardItems)
        setCurrentHistoryIndex(currentHistoryIndex + 1)
        setHistory([...history.slice(0, currentHistoryIndex + 1), boardItems])
        logDebug(`update history to index ${currentHistoryIndex + 1}`)
    }

    const handleBoardItemResized = (x: number, y: number, width: number, height: number) => {
        logDebug(`handleBoardItemResized - ${x}, ${y}, ${width}, ${height}`)
        let currentBoardItem = getBoardItem(props.selectedBoardItemId)!!

        let xVariation = x - currentBoardItem.image.x
        let yVariation = y - currentBoardItem.image.y
        let widthVariation = width - currentBoardItem.image.w
        let heightVariation = height - currentBoardItem.image.h

        logDebug(`width variation : ${widthVariation}, height: ${heightVariation}`)

        let boardItem = {
            ...currentBoardItem,
            image: {
                ...currentBoardItem.image,
                x: x,
                y: y,
                w: width,
                h: height
            } as ImageObject,
            ref: {
                ...currentBoardItem.ref,
                x: currentBoardItem.ref!!.x + widthVariation + xVariation,
                y: currentBoardItem.ref!!.y + heightVariation + yVariation
            } as ImageObject
        } as BoardItem
        let arrayWithoutBoardItem: BoardItem[] = boardItems.filter((p) => p.instanceId != boardItem.instanceId)
        let newArray = [...arrayWithoutBoardItem, boardItem]
        setNewStateAndHistorize(newArray)
    }

    const handleBoardItemRotated = (degrees: number) => {
        logDebug(`handleBoardItemRotated - ${degrees}`)
        let currentBoardItem = getBoardItem(props.selectedBoardItemId)!!

        let boardItem = {
            ...currentBoardItem,
            image: {
                ...currentBoardItem.image,
                r: degrees
            } as ImageObject
        } as BoardItem
        let arrayWithoutBoardItem: BoardItem[] = boardItems.filter((p) => p.instanceId != boardItem.instanceId)
        let newArray = [...arrayWithoutBoardItem, boardItem]
        setNewStateAndHistorize(newArray)
    }

    const handleItemCropperClosed = () => {
        setItemCropperDialogOpen(false)
    }

    const handleCrop = (boardItemId: string) => {
        let boardItem = getBoardItem(boardItemId)!!
        let currentImageId = boardItem.image.id
        appContext.httpService().get(config.base.url + `/api/dd/images/props/${currentImageId}`).then((resp: HttpResponse) => {
            let image: ImageDto = resp.body
            setCropRawImageId(image.rawImageId ?? image.id)
            setCrop(image.crop)
            setItemCropperDialogOpen(true)
        })
    }

    const handleImageCropped = async (crop: CropDto) => {
        setCropping(true)
        let croppedImageId = await appContext.sessionAwareHttpService().post(config.base.url + `/api/dd/images/crop?imageId=${cropRawImageId}`, {crop: crop})
        setItemCropperDialogOpen(false)
        let newImageId = ((croppedImageId.body as ImageDto).id)
        let currentBoardItem = getBoardItem(props.selectedBoardItemId)!!
        let newRatio = (crop.width / crop.height)
        let newHeight = currentBoardItem.image.w / newRatio
        let boardItem = {
            ...currentBoardItem,
            image: {
                ...currentBoardItem.image,
                id: newImageId,
                h: newHeight
            } as ImageObject,
        } as BoardItem
        let arrayWithoutBoardItem: BoardItem[] = boardItems.filter((p) => p.instanceId != boardItem.instanceId)
        let newArray = [...arrayWithoutBoardItem, boardItem]
        setNewStateAndHistorize(newArray)
        setCropping(false)
    }

    const handleMoveForward = (boardItemId: string) => {
        logDebug("move forward")
        moveZ(boardItemId, (z) => z + 1)
    }

    const handleMoveBackward = (boardItemId: string) => {
        logDebug("move backward")
        moveZ(boardItemId, (z) => z - 1)
    }

    const moveZ = (boardItemId: string, func: ((z: number) => number)) => {
        let currentBoardItem = getBoardItem(props.selectedBoardItemId)!!
        let boardItem = {
            ...currentBoardItem,
            image: {
                ...currentBoardItem.image,
                z: func(currentBoardItem.image.z)
            } as ImageObject,
        } as BoardItem
        let arrayWithoutBoardItem: BoardItem[] = boardItems.filter((p) => p.instanceId != boardItem.instanceId)
        logDebug(`nouveau z: ${boardItem.image.z}`)
        let newArray = [...arrayWithoutBoardItem, boardItem]
        setNewStateAndHistorize(newArray)
    }

    const handleRemoveBackground = async (boardItemId: string) => {
        let currentBoardItem = getBoardItem(props.selectedBoardItemId)!!
        props.onLoading(true)
        let newImageId = await appContext.boardService().removeBackground(currentBoardItem.image.id)
        let boardItem = {
            ...currentBoardItem,
            image: {
                ...currentBoardItem.image,
                id: newImageId
            } as ImageObject,
        } as BoardItem
        let arrayWithoutBoardItem: BoardItem[] = boardItems.filter((p) => p.instanceId != boardItem.instanceId)
        logDebug(`nouvel image id : ${boardItem.image.id}`)
        let newArray = [...arrayWithoutBoardItem, boardItem]
        setNewStateAndHistorize(newArray)
        props.onLoading(false)
    }

    const handleEditItem = (boardItemId: string) => {
        let boardItem = getBoardItem(boardItemId)!!
        props.onItemEditionRequested(boardItem.itemId)
    }

    useEffect(() => {
        logDebug("change caption")
        setCaptionElements(getCaptionForDisplay())
    }, [props.boardItems, includeDimensionsCaption, includePriceCaption, includeBrandCaption]);

    const getCaptionForDisplay: () => EmotionJSX.Element[] = () => {
        const sortedBoardItems = [...boardItems];
        return sortedBoardItems.sort((a, b) => a.refNum - b.refNum).map((boardItem, index) => {
            return <span key={boardItem.instanceId}
                         className={(boardItem.instanceId == selectedBoardItem?.instanceId) ? "selected" : ""}>
                ({boardItem.refNum}) <a style={{cursor: "pointer"}}
                                        onClick={() => openLibraryItem(boardItem.itemId)}>{getItemCaption(boardItem)}</a>
                {(index < boardItems.length - 1) &&
                    <br/>
                }
            </span>
        })
    }

    const getItemCaption = (boardItem: BoardItem): string => {
        let caption = boardItem.text
        let item = props.allLibraryItems.find((i) => i.id == boardItem.itemId)
        logDebug("includeBrandCaption ? " + includeBrandCaption)
        logDebug("item found ? " + item)
        if (includeBrandCaption && item && item.brand) {
            caption += `, ${item.brand}`
        }
        if (includePriceCaption && item && item.price) {
            caption += `, ${item.price} ${item.currency}`
        }
        if (includeDimensionsCaption && item) {
            if (item.dimensions.width || item.dimensions.height || item.dimensions.length || item.dimensions.diameter) {
                caption += ', '
            }
            if (item.dimensions.length) {
                caption += "L" + item.dimensions.length
                if (item.dimensions.width || item.dimensions.height || item.dimensions.diameter) {
                    caption += " x "
                }
            }
            if (item.dimensions.width) {
                caption += "l" + item.dimensions.width
                if (item.dimensions.height || item.dimensions.diameter) {
                    caption += " x "
                }
            }
            if (item.dimensions.height) {
                caption += "H" + item.dimensions.height
                if (item.dimensions.diameter) {
                    caption += " x "
                }
            }
            if (item.dimensions.diameter) {
                caption += "D" + item.dimensions.diameter
            }
        }
        return caption
    }

    const getCaptionForCopyPaste: () => string = () => {
        return boardItems.sort((a, b) => a.refNum - b.refNum).map((boardItem, index) => {
            return `(${boardItem.refNum}) ${boardItem.text}` + ((index < boardItems.length - 1) ? ". " : "")
        }).join("")
    }


    const handleCopyCaption = () => {
        navigator.clipboard.writeText(getCaptionForCopyPaste()).then(() => {
            props.onCaptionCopied()
        });
    }

    function getBoardItem(id: string | null): BoardItem | undefined {
        return boardItems.find((p) => p.instanceId == id)
    }

    const toggleCaptionMode = () => {
        props.onOptionUpdated("displayCaptionRefs", !captionMode)
    }


    return (
        <div>
            <DndProvider backend={HTML5Backend}>
                <div style={{display: "flex"}} onClick={handleContainerClick}>
                    <BoardItemPicker boardOffset={props.boardOffset} items={props.items}
                                     onDragAndDropEnd={handleDragAndDropEnd} onItemDropped={handleItemDropped}
                                     onItemDragged={handleItemDragged} onPickSourceChanged={props.onPickSourceChanged}
                                     pickSource={props.pickSource} sourceListing={props.sourceListing}
                                     project={props.project}
                                     onSourceListingChanged={props.onSourceListingChanged}/>
                    <div style={{clear: 'both', width: `${boardWidth}px`, height: "580px"}}>
                        <BoardCanvas boardWidth={boardWidth} boardHeight={boardHeight} captionMode={captionMode}
                                     debugMode={debugMode} selectedBoardItem={props.selectedBoardItemId}
                                     boardOffset={props.boardOffset} onBoardOffsetProvided={props.onBoardOffsetProvided}
                                     boardItems={boardItems} onBoardItemDragged={handleItemDragged}
                                     onBoardItemDropped={handleItemDropped}
                                     onItemClick={handleBoardItemClick} onBoardItemDragAndDropEnd={handleDragAndDropEnd}
                                     onRotateEnd={handleBoardItemRotated}
                                     onResizeEnd={handleBoardItemResized} onDelete={handleItemDeleted}
                                     onRefLabelDragged={handleRefLabelDragged} onRefLabelDropped={handleRefLabelDropped}
                                     onRefLabelDragAndDropEnd={handleRefLabelDragAndDropEnd}
                                     onMoveBackward={handleMoveBackward} onMoveForward={handleMoveForward}
                                     onRemoveBackground={handleRemoveBackground}
                                     onCrop={handleCrop}
                                     onEditItem={handleEditItem}
                        />
                    </div>
                    <div style={{}}>

                        {
                            <Box sx={{margin: "20px"}} css={css`
                              .selected {
                                font-weight: bold;
                              }
                            `}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={captionMode} onChange={toggleCaptionMode}/>
                                        }
                                        label={"Afficher les références à la légende"}
                                    />
                                </div>
                                <Box>
                                    <div>Inclure dans la légende :</div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={includeBrandCaption}
                                                      onChange={toggleIncludeBrandCaption}/>
                                        }
                                        label={"Marque"}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={includePriceCaption}
                                                      onChange={toggleIncludePriceCaption}/>
                                        }
                                        label={"Prix"}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={includeDimensionsCaption}
                                                      onChange={toggleIncludeDimensionsCaption}/>
                                        }
                                        label={"Dimensions"}
                                    />
                                </Box>
                                <Typography variant={"h6"}>Légende</Typography>
                                <Box sx={{fontSize: "0.8em"}}>
                                    {captionElements}
                                    {(boardItems.length > 0) &&
                                        <div><Button onClick={handleCopyCaption}>Copier la légende</Button></div>
                                    }
                                </Box>

                            </Box>
                        }
                    </div>
                </div>

            </DndProvider>
            <ImageCropperDialog open={itemCropperDialogOpen} imageId={cropRawImageId} crop={crop}
                                onClose={handleItemCropperClosed} onCropped={handleImageCropped} cropping={cropping}/>
        </div>
    )
}