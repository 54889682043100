/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react';
import {approvedStatus, Item, ListingItemStatus, rejectedStatus} from "shared/model/model";
import {Box, Button} from "@mui/material";
import {styled} from "@mui/material/styles";
import Rating, {IconContainerProps} from "@mui/material/Rating";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {colors} from "shared/styles/vars";
import {CancelOutlined} from "@mui/icons-material";
import {css} from "@emotion/react";


const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));

const customIcons: {
    [index: string]: {
        icon: React.ReactElement;
        label: string;
    };
} = {
    1: {
        icon: <CheckCircleIcon color="success" />,
        label: 'Approved',
    }
};


function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

interface Props {
    item: Item
    readOnly: boolean
    onChange: (decision: boolean) => void
}

export const ItemDecider = (props: Props) => {

    const [status,setStatus] = useState<ListingItemStatus | null>(null)

    useEffect(() => {
    }, [props.item])

    const handleChange = (value: ListingItemStatus) => {
        props.onChange(value == approvedStatus)
    }

    return (
        <Box css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          
          .MuiButton-outlinedPrimary {
            color: ${colors.darkgreen};
            border-color: ${colors.darkgreen};
          }

          .MuiButton-root {
            font-size: 0.8em;
            text-transform: none;
          }
          
        `}>
            <Button variant="outlined" startIcon={<CheckCircleIcon />} onClick={() => handleChange(approvedStatus)} color="primary">
                Valider
            </Button>
            <Button variant="outlined" endIcon={<CancelOutlined />} onClick={() => handleChange(rejectedStatus)} color="error">
                Rejeter
            </Button>
        </Box>
    );
}