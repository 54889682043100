/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import * as React from 'react';
import {PropsWithChildren, ReactElement, useEffect} from 'react';
import {Button, ClickAwayListener, Grow, MenuList, Paper, Popper, Stack} from "@mui/material";

interface Props extends PropsWithChildren<{}> {
    open: boolean
    icon: ReactElement
    onMenuStateChange: (open: boolean) => void
}

export const IconButtonMenu = (props: Props) => {

    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        props.onMenuStateChange(!props.open);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        props.onMenuStateChange(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            props.onMenuStateChange(false);
        } else if (event.key === 'Escape') {
            props.onMenuStateChange(false);
        }
    }

    // return focus to the button when we transitioned from !moreMenuOpen -> moreMenuOpen
    const prevOpen = React.useRef(props.open);
    React.useEffect(() => {
        if (prevOpen.current && !props.open) {
            anchorRef.current!.focus();
        }

        prevOpen.current = props.open;
    }, [props.open]);

    return (

        <Stack direction="row" spacing={2} display={"inline-flex"} css={css`
          .menuButton {
            padding-top: 4px;
            color:gray;
          }
          `}>
            <Button
                className="menuButton"
                startIcon={props.icon}
                ref={anchorRef}
                id="composition-button"
                aria-controls={props.open ? 'composition-menu' : undefined}
                aria-expanded={props.open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
            </Button>
            <Popper
                open={props.open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal nonce={undefined} onResize={undefined} onResizeCapture={undefined}>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={props.open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {props.children}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Stack>
    );
};
