/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import {defaultItemFilters, Item, Listing, ListingItemStatus, noStatus, Project} from "shared/model/model";
import {appContext} from "ApplicationContext";
import {css} from "@emotion/react";
import {EventSnackBar} from "view/components/EventSnackBar";
import {ItemPropertiesPanel} from "../item/ItemPropertiesPanel";
import {ListingItemReactionPanel} from "./ListingItemReactionPanel";
import {AlertColor, Box} from "@mui/material";
import {ItemViewMenu} from "../item/ItemViewMenu";
import Chip from "@mui/material/Chip";
import {UserInfos} from "shared/model/user";
import {navigateTo} from "utils/routing-utils";
import {useReferentialsState} from "state/ReferentialsState";
import {useLocation} from "react-router-dom";
import {
    filtersToSearchParams,
    filtersToSearchParamsIfDefined,
    searchParamsToFilters
} from "common/param_utils";

const itemViewMenuHeight = 40

interface Props {
    project: Project
    listing: Listing
    item: Item
    userInfos: UserInfos
    addCommentRequested: (itemId: string, listingId: string, commentText: string) => void
    deleteCommentRequested: (commentId: string) => void
    onStatusClicked: (listingItemId: string) => void
    onCancelReactionRequested: (itemId: string, listingId: string) => void
    height: number
}

export const ListingItemDetail = (props: Props) => {
    const [snackBarDisplayed, setSnackBarDisplayed] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarSeverity, setSnackBarSeverity] = useState<AlertColor>('info');

    const statuses = useReferentialsState(s => s.listingItemStatuses)
    const [status, setStatus] = useState<ListingItemStatus>(noStatus);

    const typologies = useReferentialsState(s => s.typologies)

    const [filters, setFilters] = useState(defaultItemFilters);

    const location = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const filters = searchParamsToFilters(params, typologies);
        setFilters(filters);
    }, [location])

    useEffect(() => {
        setStatus(statuses.find(s => s.code == props.item.status) ?? noStatus)
    }, []);

    const handleBack = () => {
        navigateTo({name: 'ProjectListing', projectId: props.project.id, listingId: props.listing.id}, false, filtersToSearchParamsIfDefined(filters))
    }

    const openUrl = () => {
        window.open(props.item.url, '_blank')
    }

    const edit = () => {
        navigateTo({name: 'LibraryItem', itemId: props.item.id})
    }

    const handleSnackBarClosed = () => {
        setSnackBarDisplayed(false)
    }

    const getProject = (projectId: string, forceRefresh: boolean = false) => {
        return appContext.projectService().getProject(projectId)
    }

    return (
        <Box>
            <ItemViewMenu item={props.item} goBackText={"retour"} onBackRequested={handleBack} openUrl={openUrl}
                          edit={edit}/>

            <div
                css={css`
                  display: flex;
                `}
            >
                <div css={css`
                  flex: 1;
                  height: ${props.height - 50 - itemViewMenuHeight}px;
                  overflow-y: scroll;

                  .MuiChip-filled {
                    background-color: ${status.color};
                    color: ${status.textColor};
                  }
                `}
                >
                    <Chip label={status.label} onClick={() => props.onStatusClicked(props.item.listingItemId!!)}/>
                    <ItemPropertiesPanel item={props.item} fullPage={false}/>
                </div>

                <div css={css`
                  flex: 1;
                  height: ${props.height - 50 - itemViewMenuHeight - 10}px;
                  margin: 10px 0px 0px 50px;
                  overflow-y: scroll;
                  overflow-x: hidden;
                `}
                >

                    <ListingItemReactionPanel libraryItemId={props.item.id} listing={props.listing}
                                              project={props.project}
                                              userInfos={props.userInfos}
                                              onCommentSubmitted={props.addCommentRequested} guestMode={false}
                                              onApprovalChange={() => null}
                                              onCancelReactionRequested={props.onCancelReactionRequested}
                                              onRatingChange={() => null} onDelete={props.deleteCommentRequested}/>

                </div>

                <EventSnackBar displayed={snackBarDisplayed} message={snackBarMessage}
                               onClosed={handleSnackBarClosed} severity={snackBarSeverity}/>
            </div>
        </Box>
    )
};