/** @jsxImportSource @emotion/react */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import {css} from "@emotion/react";
import {colors} from "shared/styles/vars";
import {Tooltip} from "@mui/material";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import {AccountCircleOutlined, AddOutlined} from "@mui/icons-material";
import {RootLink} from "../components/RootLink";
import {useUserState} from "state/UserState";

interface Props {
    onAddItem: () => void
    onLogout: () => void
}

export const LibraryAppBar = (props: Props) => {
    const setConnected = useUserState(s => s.setConnected);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleLogout = () => {
        handleMenuClose()
        props.onLogout()
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderDesktopMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const menuTextStyle = {marginBottom: "16px", marginTop: "16px"}
    const menuMobileCss = css`
        p.menu-text {
            margin-bottom: 16px;
            margin-top: 16px;
        }
    `
    const renderMobileMenu = (
        <Menu css={menuMobileCss}
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={props.onAddItem}>
                <IconButton>
                    <AddOutlined/>
                </IconButton>
                <p className="menu-text">Ajouter un article</p>
            </MenuItem>

            <MenuItem onClick={handleLogout}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircleOutlined/>
                </IconButton>
                <p className="menu-text">Se déconnecter</p>
            </MenuItem>
        </Menu>
    );

    const appBarCss = css`
        .MuiPaper-root {
        background-color: white;
        box-shadow: none;
        border-bottom: 1px solid ${colors.clearGrey};
        color: ${colors.verydarkgrey}
       }
    `

    return (
        <Box sx={{flexGrow: 1}} css={appBarCss}>
            <AppBar position="fixed">
                <Toolbar>
                    <RootLink short={false}/>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{display: {xs: 'none', sm: 'block'}}}
                    >
                    </Typography>
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                        <Tooltip title="Ajouter un article">
                            <IconButton onClick={props.onAddItem}>
                                <AddOutlined/>
                            </IconButton>
                        </Tooltip>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircleOutlined/>
                        </IconButton>
                    </Box>
                    <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderDesktopMenu}
        </Box>
    );
}
