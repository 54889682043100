/** @jsxImportSource @emotion/react */
import {Download} from "@mui/icons-material";
import {css} from "@emotion/react";
import {Box} from "@mui/material";

export const DownloadableImg = (props: {
    imgSrc: string
    css: any
    onDownload: () => void
}) => {
    return <Box css={css`
        :hover .pick-icon {
          visibility: visible;
        }

         .pick-icon {
            visibility: hidden;
          }
      `}>
        <img src={props.imgSrc} css={props.css}/>
        <Download onClick={props.onDownload} cursor='pointer'
                  className={"pick-icon"}

                  css={css`
                            background: rgba(255, 255, 255, 0.8);
                            position: absolute;
                            z-index: 1;
                            border-radius: 100%;
                            border: 1px solid rgba(0, 0, 0, 0.2);
                            box-sizing: content-box;
                      padding: 5px;
                            display: flex;
                            top: 10px;
                            right: 20px;

                            transition: all .135s cubic-bezier(0, 0, .2, 1);
                            transition-property: all;
                            fill-opacity: .54;

                            &:hover {
                              background: rgba(255, 255, 255, 1);
                              transition: all .135s cubic-bezier(0, 0, .2, 1);
                              transition-property: all;
                            }
                          `}
        >
        </Download>
    </Box>
}