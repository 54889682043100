/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import {PropsWithChildren, ReactElement} from 'react';

interface MainContainerProps extends  PropsWithChildren<{}> {
  justify?: 'default' | 'center'
  menu: ReactElement | null
  overflowX?: 'scroll' | 'hidden' | null
  overflowY?: 'scroll' | 'hidden' | null
  margin?: string | null
}

export const MainContainer = (props: MainContainerProps) => {

  const justify = (props.justify != undefined && props.justify != 'default') ? (css`justify-content: ${props.justify};`) : css``;

  return (
      <div
          css={css`
      position: absolute;
      display: flex;
      height: 100%;
      width: 100%;
    `}
      >
        <div
            css={css`
        flex: 1;
        overflow-x: ${props.overflowX || 'scroll'};
        overflow-y: ${props.overflowY || 'scroll'};
      `}
        >
          <div style={{zIndex: 5}}>{props.menu}</div>
          <div
              css={css`
          margin: ${props.margin ? props.margin : "30px"};
        `}
          >
            <div css={[css`display: flex;`, justify]}>
            {props.children}
          </div>
        </div>
      </div>

  </div>
  )
}