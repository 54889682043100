import config from 'config';
import { appContext } from 'ApplicationContext';

export interface ValidationResponseDto {
    mailAlreadyTaken: boolean
}

export interface RegistrationResponseDto {
    registered: boolean,
    error: string
}

export class RegistrationService {

    public register(mail: String, password: String, specialCode: string | null): Promise<RegistrationResponseDto> {
        const http = appContext.httpService();
        return http.postBody(config.base.url + '/public/register', {mail: mail, password: password, specialCode: specialCode});
    }

}