/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import {
    archivedStatus,
    Item,
    ItemCardSelectionMode,
    ListingItemStatus,
    noListing,
    noStatus,
    Project,
    toReviewStatus
} from "shared/model/model";
import {Box, Card, CardContent, CardMedia, Typography} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import {css} from "@emotion/react";
import {ItemRating} from "./ItemRating";
import {formatPrice, getStatus, getThumbnailImageUrl} from "shared/utils";
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import {colors} from "shared/styles/vars";
import Chip from '@mui/material/Chip';
import {ItemDecider} from "./ItemDecider";
import {navigateTo} from "../../../utils/routing-utils";
import {useReferentialsState} from "state/ReferentialsState";

interface Props {
    item: Item
    project: Project
    projectId: string | null
    listingId: string | null
    sharingToken: string | null
    selectionMode: ItemCardSelectionMode // Pick mode means the sole possible interaction of item is to pick it (can't be open, etc.)
    itemAction: 'open_origin' | 'open_edition' | null
    selected: boolean
    selectOnClick?: boolean | undefined
    onSelectionChange: () => void
    onRatingChange: (itemId: string, listingId: string | null, rating: string | null) => void
    onApprovalChange: (itemId: string, listingId: string | null, approved: boolean) => void
    onItemClicked: (libraryItemId: string) => void
    onStatusClicked: (listingItemId: string) => void
    onReactionsDetailsRequested: (listingItemId: string) => void
}

export const ItemCard = (props: Props) => {

    const [name] = useState(props.item.name);
    const [listing, setListing] = useState(noListing);
    const [price] = useState(props.item.price);
    const [currency] = useState(props.item.currency);
    const [url] = useState(props.item.url);
    const [tags] = useState(props.item.tagging.tags);
    const [selected, setSelected] = useState(props.selected);
    const [guestMode, setGuestMode] = useState(false);

    const statuses = useReferentialsState(s => s.listingItemStatuses)

    const [status, setStatus] = useState<ListingItemStatus>(noStatus);

    const imageUrl = props.item.imageId && getThumbnailImageUrl(props.item.imageId);

    useEffect(() => {
        setStatus(statuses.find(s => s.code == props.item.status)!!)
    }, [props.item]);

    useEffect(() => {
        setGuestMode(props.itemAction == 'open_origin')
    }, [props.itemAction])

    useEffect(() => {
        if (props.listingId && props.project) {
            setListing(props.project.listings.find(l => l.id == props.listingId)!!)
        }
    }, [props.listingId, props.project])


    useEffect(() => {
        setSelected(props.selected)
    }, [props.selected])

    const openItemView = (e: React.MouseEvent) => {
        if (props.selectionMode == 'pickable' || ((props.selectionMode == 'selectable') && (props.selectOnClick))) {
            e.preventDefault()
            props.onSelectionChange()
            return;
        }
        props.onItemClicked(props.item.id)
    }

    const openOnClickProps = () => {
        if (guestMode) {
            return {}
        } else {
            return {
                onClick: openItemView
            }
        }
    }


    const handleReactionTypePicked = (reactionType: string) => {
        props.onRatingChange(props.item.id, props.listingId, reactionType)
    }

    const handleApprovalChange = (approved: boolean) => {
        props.onApprovalChange(props.item.id, props.listingId, approved)
    }

    let selectionStyle = ""
    if (props.selectionMode == 'pickable') {
        selectionStyle += " pickable "
    }
    if (props.selectionMode == 'selectable') {
        selectionStyle += " selectable "
    }
    if (props.selected) {
        selectionStyle += " selected "
    }
    let archivedStyle = props.item.status == archivedStatus.code ? "opacity: 0.5;" : ""
    return (

        <Card variant="outlined" sx={{maxWidth: 300, minWidth: 200, minHeight: 300}} className={selectionStyle}
              css={css`
                cursor: pointer;
                margin: 7.5px;

                &.selected {
                  background-color: ${colors.primaryLight2};
                }

                &.selectable:not(.selected) .pick-icon {
                  visibility: hidden;
                }

                &.selectable:hover .pick-icon {
                  visibility: visible;
                }

                ${archivedStyle}
                .MuiCardContent-root {
                  padding: 0;

                  &:last-child {
                    padding-bottom: 0px;
                  }
                }

                .item-desc {
                  padding: 16px 16px 0px 16px;

                  .MuiTypography-h6 {
                    font-size: 1.15rem;
                    height: 3.6rem;
                    overflow: hidden;
                  }

                  .MuiTypography-body1 {
                    font-size: 0.9rem;
                  }
                }

                .item-status-block {
                  border-top: 1px solid ${colors.clearGrey};
                  padding: 16px 16px 8px 16px;
                }
              `}
              {...openOnClickProps}
        >
            {(props.selectionMode == 'pickable' || props.selectionMode == 'selectable') &&
                <DoneIcon onClick={props.onSelectionChange} cursor='pointer'
                          className={selected ? "selected pick-icon" : "pick-icon"}

                          css={css`
                            background: rgba(0, 0, 0, 0.1);
                            position: absolute;
                            z-index: 1;
                            border-radius: 100%;
                            display: flex;
                            top: 10px;
                            right: 10px;

                            transition: all .135s cubic-bezier(0, 0, .2, 1);
                            transition-property: all;
                            fill-opacity: .54;

                            &:hover {
                              background: rgba(0, 0, 0, 0.3);
                              transition: all .135s cubic-bezier(0, 0, .2, 1);
                              transition-property: all;
                            }

                            &.selected {
                              background: white;
                              fill: green;
                              fill-opacity: 1;
                              opacity: 1;
                            }
                          `}
                >
                </DoneIcon>
            }
            <div className={selected ? "selected" : ""}
                 css={css`
                   &.selected {
                     background-color: white;
                     transform: translateZ(0px) scale3d(0.82, 0.82, 1);
                   }
                 `}
            >
                {imageUrl &&
                    <CardMedia className={selected ? "selected" : ""}
                               css={css`
                                 object-fit: contain;

                                 &.selected {
                                   margin-top: 15px;
                                 }
                               `}
                               component="img"
                               height="170"
                               image={imageUrl}
                               onClick={openItemView}
                    />
                }

                {!imageUrl &&
                    <Box
                        css={css`
                          text-align: center;
                          font-weight: bold;
                          color: ${colors.clearGrey}
                        `}
                        onClick={openItemView}
                    >
                        <PhotoOutlinedIcon style={{marginTop: "30px", fontSize: "8em"}}/>
                    </Box>
                }

                <CardContent>
                    <Box onClick={openItemView} className={"item-desc"}>
                        <Typography gutterBottom variant="h6" component="div" css={css`
                          text-overflow: ellipsis;
                          white-space: normal;
                          -webkit-line-clamp: 2;
                          line-clamp: 2;
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          box-orient: vertical;
                          overflow: hidden;`}
                        >
                            {name}
                        </Typography>

                        <Typography gutterBottom variant="body1" color='grey'>
                            {price &&
                                <span>{formatPrice(price)} {currency}</span>
                            }
                            {!price &&
                                <span>&nbsp;</span>
                            }
                        </Typography>
                    </Box>
                    {props.item.listingItemId &&
                        <Box sx={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}
                             className={"item-status-block"} css={css`
                          .MuiChip-filled {
                            background-color: ${status.color};
                            color: ${status.textColor};

                          }
                        `}>
                            {!guestMode &&
                                <Chip label={status.label}
                                      onClick={() => props.onStatusClicked(props.item.listingItemId!!)}/>
                            }
                            {guestMode && (getStatus(props.item.status).level > toReviewStatus.level) &&
                                <Chip label={status.label}
                                      onClick={() => props.onStatusClicked(props.item.listingItemId!!)}/>
                            }
                            {guestMode && (getStatus(props.item.status).level <= toReviewStatus.level) &&
                                <span/>
                            }
                            {!guestMode &&
                                <ItemRating userReaction={props.item.userReaction} reactions={props.item.reactions}
                                            readOnly={true}
                                            onClick={() => props.onReactionsDetailsRequested(props.item.listingItemId!!)}
                                            onReactionTypePicked={handleReactionTypePicked}/>
                            }
                            {guestMode &&
                                <ItemRating userReaction={props.item.userReaction} reactions={props.item.reactions}
                                            readOnly={false}
                                            onClick={() => props.onReactionsDetailsRequested(props.item.listingItemId!!)}
                                            onReactionTypePicked={handleReactionTypePicked}/>
                            }
                            {(getStatus(props.item.status).level == toReviewStatus.level) && guestMode &&
                                <ItemDecider item={props.item} readOnly={!guestMode}
                                             onChange={handleApprovalChange}/>

                            }
                        </Box>
                    }

                </CardContent>
            </div>
        </Card>


    )
};