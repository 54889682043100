import * as React from 'react';
import {createElement} from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Project} from "shared/model/model";
import {MenuItem} from "./ProjectComponent";
import {Typography} from "@mui/material";
import { css } from '@emotion/react'
import {colors} from "shared/styles/vars";
import FolderSharedIcon from "@mui/icons-material/FolderShared";

export const drawerWidth = 240;
const drawerTop = 80;

export type ProjectMenuItemType =  "items" | "listings" | "listing_detail" | "boards" | "board_detail"


interface Props {
    project: Project,
    menuItems: MenuItem[],
    currentMenuItem: MenuItem,
    onMenuItemClicked: (menuItem: MenuItem) => void,
}

export default function ProjectDrawer(props: Props) {

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    top: `${drawerTop}px`,
                    borderRight: "0",
                },
                '& .MuiList-root': {
                    marginBottom: "100px"
                },
            }}
            variant="permanent"
            anchor="left"
        >

            <List>
                {props.menuItems.map((menuItem, index) => (
                    <ListItem key={menuItem.label} disablePadding selected={(menuItem.menuItemType == props.currentMenuItem.menuItemType) && (menuItem.subId == props.currentMenuItem.subId) }>
                        <ListItemButton onClick={() => props.onMenuItemClicked(menuItem)}>
                            <ListItemIcon>
                                {menuItem.icon && createElement(menuItem.icon)}
                            </ListItemIcon>
                            <ListItemText primary={menuItem.label} />
                            <ListItemIcon>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

        </Drawer>
    );
}