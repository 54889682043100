/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import {css} from "@emotion/react";
import {noBoard, noProject, Project} from "shared/model/model";
import {appContext} from "ApplicationContext";
import {colors} from "shared/styles/vars";
import { logDebug } from 'shared/utils';

export interface Props {
    creationMode: boolean,
    name: string,
    open: boolean;
    onClose: () => void;
    onValidated: (name: string, originBoardId: string | null, originProjectId: string | null, addItemsToProject: boolean) => void;
}

export const BoardCreationDialog = (props: Props) => {

    const [name, setName] = useState('')
    const [copyBoard, setCopyBoard] = useState(false)
    const [addItemsToProject, setAddItemsToProject] = useState(false)
    const [projects, setProjects] = useState<Project[]>([])
    const [originProjectId, setOriginProjectId] = useState<string>(noProject.id)
    const [originBoardId, setOriginBoardId] = useState<string>(noBoard.id)

    const originProject = (originProjectId && (originProjectId != noProject.id)) ? projects.find((p) => p.id == originProjectId) : noProject

    const fetchItems = () => {
        appContext.projectService().findAll(true)
            .then((projects: Project[]) => {
                setProjects(projects);
                if (projects.length > 0) {
                    setOriginProjectId(projects[0].id)
                }
            });
    };

    useEffect(() => {
        if (originProject && (originProject.boards.length > 0)) {
            setOriginBoardId(originProject.boards[0].id)
        }
    }, [originProject]);

    useEffect(() => {
        reset()
        if (props.open) {
            fetchItems();
        }
    }, [props.open]);

    useEffect(() => {
        setName(props.name)
    }, [props.name]);

    const toggleCopyBoard = () => {
        setCopyBoard(!copyBoard)
    }

    const toggleAddItemsToProject = () => {
        setAddItemsToProject(!addItemsToProject)
    }

    const reset = () => {
        setCopyBoard(false)
        setAddItemsToProject(false)
        setName("")
        setOriginBoardId(noBoard.id)
        setOriginProjectId(noProject.id)
    }

    const handleCloseDialog = () => {
        props.onClose();
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    }

    const handleProjectChange = (e: SelectChangeEvent) => {
        let selectedProjectId = e.target.value
        setOriginProjectId(selectedProjectId ? selectedProjectId : noProject.id)
    }

    const handleOriginBoardChange = (e: SelectChangeEvent) => {
        let selectedBoardId = e.target.value
        setOriginBoardId(selectedBoardId ? selectedBoardId : noBoard.id)
    }

    const submit = () => {
        logDebug(`submit board creation with origin board ${originBoardId}`)
        if (copyBoard) {
            props.onValidated(name, originBoardId, originProjectId, addItemsToProject)
        } else {
            props.onValidated(name, null, null, false)
        }
    }

    const handleNameKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            submit()
        }
    }

    return (
        <Dialog onClose={handleCloseDialog} open={props.open}>
            <DialogTitle fontSize={"1.5em"}>{props.creationMode ? "Créer une planche" : "Renommer la planche"}</DialogTitle>
            <Box style={{marginLeft: "25px", marginBottom: "25px"}}>

                <FormControl sx={{m: 1, minWidth: 120}}>
                    <TextField onChange={handleNameChange} onKeyDown={handleNameKeyDown} variant="standard"
                               InputProps={{}} autoFocus={true} value={name} placeholder={"Nom de la planche"}/>

                    { props.creationMode &&
                        <Box css={css`
                          margin-top: 20px;
                        `}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={copyBoard} onChange={toggleCopyBoard}/>
                                }
                                label={"Copier à partir d'une planche existante"}
                            />
                            { copyBoard &&
                                <Box css={css`
                                      margin-top: 10px;
                                    `}>
                                    <Typography css={css`
                                      margin-bottom:20px;
                                      color:${colors.darkgrey}
                                    `}>Sélectionnez la planche à copier :</Typography>
                                    <FormControl>
                                        <InputLabel id={"project-label"}>Projet</InputLabel>
                                        <Select
                                            label={"project"}
                                            value={originProjectId}
                                            labelId="project-label"
                                            onChange={handleProjectChange}
                                            variant="standard"
                                            css={css`margin-right: 20px;`}
                                        >
                                            {
                                                projects.map((p) => {
                                                    return <MenuItem value={p.id} key={p.id}>{p.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel id={"origin-board-label"}>Planche</InputLabel>
                                        <Select
                                            label={"origin-board"}
                                            value={originBoardId}
                                            labelId="origin-board-label"
                                            onChange={handleOriginBoardChange}
                                            variant="standard"
                                            css={css`margin-right: 20px;`}
                                        >
                                            {
                                                originProject?.boards.map((b) => {
                                                    return <MenuItem value={b.id} key={b.id}>{b.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <Box css={css`margin-top:10px`}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox css={css`font-size:0.8em`} size="small"  checked={addItemsToProject} onChange={toggleAddItemsToProject}/>
                                            }
                                            label={<Typography variant="body2" color="textSecondary">Ajouter les articles de la planche au projet</Typography>}
                                        />
                                    </Box>
                                </Box>
                            }
                        </Box>
                    }
                </FormControl>

                <Box style={{marginTop: "20px"}}>
                    <FormControl>
                        <Button onClick={submit}
                                disabled={"" == name}>{props.creationMode ? "Créer" : "Renommer"}</Button>
                    </FormControl>
                </Box>
            </Box>
        </Dialog>
    );
}