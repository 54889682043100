/** @jsxImportSource @emotion/react */
import { MainLayout } from 'view/layout/MainLayout';
import { LoginView } from 'view/login/LoginView';
import { RegisterView } from 'view/register/RegisterView';
import { LogoutView } from 'view/login/LogoutView';
import { LibraryView } from 'view/library/LibraryView';
import { ProjectListView } from 'view/projects/project-list/ProjectListView';
import { ProjectView } from 'view/projects/project-editor/ProjectView';
import { LibraryItemView } from 'view/library/item/LibraryItemView';
import { ProjectListingView } from 'view/projects/project-editor/listings/ProjectListingView';
import { ProjectBoardView } from 'view/projects/project-editor/boards/ProjectBoardView';
import { ProjectBoardsView } from 'view/projects/project-editor/boards/ProjectBoardsView';
import { ListingItemView } from 'view/projects/project-editor/listing-item/ListingItemView';
import { SharedListingItemView } from 'view/shared-project/SharedListingItemView';
import { SharedProjectView } from 'view/shared-project/SharedProjectView';
import { EditBoardView } from 'view/projects/project-editor/boards/editor/EditBoardView';
import { ExtensionTutorialView } from 'view/onboarding/ExtensionTutorialView';
import { NotFoundRoute } from 'view/not-found/NotFoundRoute';
import { LoginLayout } from 'view/layout/LoginLayout';
import { createBrowserRouter, RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
  {
    id: 'LoginLayout',
    element: <LoginLayout />,
    children: [
      {
        id: 'Login',
        path: 'login',
        element: <LoginView />
      }
    ]
  },
  {
    id: 'MainLayout',
    element: <MainLayout />,
    children: [
      {
        id: 'EditBoard',
        path: 'projects/:projectId/boards/:boardId/edit',
        element: <EditBoardView />
      },
      {
        id: 'ExtensionTutorial',
        path: 'setup-extension',
        element: <ExtensionTutorialView />
      },
      {
        id: 'Library',
        path: 'items',
        element: <LibraryView />
      },
      {
        id: 'LibraryItem',
        path: 'items/:itemId',
        element: <LibraryItemView />
      },
      {
        id: 'ListingItem',
        path: 'projects/:projectId/listings/:listingId/items/:itemId',
        element: <ListingItemView />
      },
      {
        id: 'Logout',
        path: 'logout',
        element: <LogoutView />
      },
      {
        id: 'Project',
        path: 'projects/:projectId',
        element: <ProjectView />
      },
      {
        id: 'ProjectBoard',
        path: 'projects/:projectId/boards/:boardId',
        element: <ProjectBoardView />
      },
      {
        id: 'ProjectBoards',
        path: 'projects/:projectId/boards',
        element: <ProjectBoardsView />
      },
      {
        id: 'ProjectListing',
        path: 'projects/:projectId/listings/:listingId',
        element: <ProjectListingView />
      },
      {
        id: 'Register',
        path: 'register',
        element: <RegisterView />
      },
      {
        id: 'Root',
        path: '/',
        element: <ProjectListView />
      },
      {
        id: 'SharedListingItem',
        path: 'v/:token/listings/:listingId/items/:itemId',
        element: <SharedListingItemView />
      },
      {
        id: 'SharedProject',
        path: 'v/:token',
        element: <SharedProjectView />
      },
      {
        id: 'NotFound',
        path: '*',
        element: <NotFoundRoute />
      }
    ]
  }
];
export const router = createBrowserRouter(routes);
