import {Session} from "inspector";
import { appContext } from "ApplicationContext";
import {Board, BoardContent, BoardOptions, Item, Project} from "../model/model";
import config from "config";

interface SharingTokenResponse {
    token: string
}

export class ProjectService {

    findAll(children: boolean): Promise<Project[]> {
        let url = `${config.base.url}/api/dd/projects?${children ? 'children=true&' : ''}descOnly=true`;
        return appContext.sessionAwareHttpService().getBody<Project[]>(url)
    }

    getProject(projectId: string): Promise<Project> {
        console.debug(`Retrieving project ${projectId}...`)
        return appContext.sessionAwareHttpService().getBody<Project>(config.base.url + '/api/dd/projects/' + projectId)
    }

    createProject(project: Project) {
        return appContext.sessionAwareHttpService().post(config.base.url + '/api/dd/projects', project)
            .then(() => console.log("Successfully save project"))
    }

    addItem(projectId: string, itemId: string) {
        return appContext.sessionAwareHttpService().postBody(config.base.url + '/api/dd/projects/' + projectId + "/items",
            {itemId: itemId}).then(() => console.log("Successfully added item to project"))
    }

    removeItem(projectId: string, itemId: string) {
        return appContext.sessionAwareHttpService().delete(config.base.url + '/api/dd/projects/' + projectId + "/items/" + itemId)
            .then(() => console.log("Successfully removed item from project"))
    }

    updateItems(projectId: string, itemIds: string[]) {
        return appContext.sessionAwareHttpService().put(config.base.url + '/api/dd/projects/' + projectId + "/items", { itemsIds: itemIds })
            .then(() => console.log("Successfully updated items in project"))
    }

    addItems(projectId: string, itemIds: string[]) {
        return appContext.sessionAwareHttpService().post(config.base.url + '/api/dd/projects/' + projectId + "/items", { itemsIds: itemIds })
            .then(() => console.log("Successfully added items to project"))
    }

    removeItems(projectId: string, itemIds: string[]) {
        return appContext.sessionAwareHttpService().delete(config.base.url + '/api/dd/projects/' + projectId + "/items", { itemsIds: itemIds })
            .then(() => console.log("Successfully removed items from project"))
    }

    renameProject(projectId: string, name: string) {
        return appContext.sessionAwareHttpService().put(config.base.url + '/api/dd/projects/' + projectId + "/name", { name: name })
            .then(() => console.log("Successfully updated project name"))
    }

    deleteProject(projectId: string) {
        return appContext.sessionAwareHttpService().delete(config.base.url + '/api/dd/projects/' + projectId)
            .then(() => console.log("Successfully deleted project"))
    }

    getSharedProject(projectToken: string): Promise<Project> {
        return appContext.sessionAwareHttpService().getBody<Project>(config.base.url + '/api/dd/shared-projects/' + projectToken)
    }

    addGuestRating(projectToken: string, itemId: string, listingId: string | null, guestRating: string | null): Promise<Item> {
        return appContext.sessionAwareHttpService().postBody<Item>(`${config.base.url}/api/dd/shared-projects/${projectToken}/rating`, { itemId: itemId, listingId: listingId, rating: guestRating })
    }

    updateGuestApproval(projectToken: string, itemId: string, listingId: string | null, approved: boolean) {
        return appContext.sessionAwareHttpService().post(`${config.base.url}/api/dd/shared-projects/${projectToken}/decision`, { itemId: itemId, listingId: listingId, approved: approved })
    }

    getBoard(projectId: string, boardId: string): Promise<Board> {
        return appContext.sessionAwareHttpService().getBody<Board>(`${config.base.url}/api/dd/projects/${projectId}/boards/${boardId}`)
    }

    createBoard(id: string, projectId: string, name: string, originBoardId: string | null, originProjectId: string | null, addItemsToProject: boolean, boardOptions: BoardOptions): Promise<Board> {
        return appContext.sessionAwareHttpService().postBody<Board>(`${config.base.url}/api/dd/projects/${projectId}/boards`, {
            id:id, projectId: projectId,
            name: name,
            originBoardId: originBoardId,
            originProjectId: originProjectId,
            addItemsToProject: addItemsToProject,
            options: boardOptions
        })
    }

    deleteBoard(projectId: string, boardId: string) {
        return appContext.sessionAwareHttpService().delete(`${config.base.url}/api/dd/projects/${projectId}/boards/${boardId}`)
            .then(() => console.log("Successfully deleted board"))
    }

    updateBoard(projectId: string, boardId: string, name: string, boardContent: BoardContent, boardOptions: BoardOptions) {
        return appContext.sessionAwareHttpService().put(`${config.base.url}/api/dd/projects/${projectId}/boards/${boardId}`, {name: name, content: boardContent, options: boardOptions})
            .then(() => console.log("Successfully updated board content"))
    }

    updateBoardSourceListing(projectId: string, boardId: string, sourceListingId: string | null) {
        return appContext.sessionAwareHttpService().put(`${config.base.url}/api/dd/projects/${projectId}/boards/${boardId}/source-listing`, {sourceListingId: sourceListingId})
            .then(() => console.log("Successfully updated board source listing id"))
    }

    share(projectId: string, email: string, role: string, notify: boolean) {
        return appContext.sessionAwareHttpService().post(`${config.base.url}/api/dd/projects/${projectId}/share`, {email: email, role: role, notify: notify})
            .then(() => console.log("Successfully shared project"))
    }

    unshare(projectId: string, emails: string[]) {
        return appContext.sessionAwareHttpService().post(`${config.base.url}/api/dd/projects/${projectId}/unshare`, { emails })
            .then(() => console.log("Successfully unshared project"))
    }

    acceptInvitation(token: string) {
        return appContext.sessionAwareHttpService().post(`${config.base.url}/api/dd/accept-invitation?inviteToken=${token}`)
            .then(() => console.log("Successfully accepted invitation"))
    }

    archiveProjects(projectIds: string[]) {
        return appContext.sessionAwareHttpService().post(config.base.url + '/api/dd/archive-projects', { projectIds: projectIds })
            .then(() => console.log("Successfully archived projects"))
    }

    unarchiveProjects(projectIds: string[]) {
        return appContext.sessionAwareHttpService().post(config.base.url + '/api/dd/unarchive-projects', { projectIds: projectIds })
            .then(() => console.log("Successfully unarchived projects"))
    }

}