const local = {
    api: {
        url: "http://localhost:9000/api"
    },
    base: {
        url: "http://localhost:9000"
    },
    client: {
        url: "http://localhost:3000"
    },
    credentials: {
        sharing: "include"
    },
    cdn: {
        enabled: false,
        proxified: false,
        url: ""
    },
    log : {
        level: "debug"
    }
};

const prod = {
    api: {
        url: "/api"
    },
    base: {
        url: ""
    },
    client: {
        url: ""
    },
    credentials: {
        sharing: "same-origin"
    },
    cdn: {
        enabled: true,
        proxified: true,
        url: "https://d339l6mbug5831.cloudfront.net"
    },
    log : {
        level: "warn"
    }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : local;

export default {
    // Add common config values here
    SOME_SETTING: 1,
    ...config
};