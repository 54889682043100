/** @jsxImportSource @emotion/react */
import * as React from "react";
import {Box} from "@mui/material";
import {colors} from "shared/styles/vars";
import {css} from "@emotion/react";

import {RouteLink} from "./RouteLink";

interface Props {
    short: boolean
}

export const RootLink = (props: Props) => {
    const shortLogoBoxCss = css`
        background-color: #000000;
      position: relative;
      width: 50px;
      height: 65px;
      left: -25px;
      top: 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      
      img {
        margin: auto;
        display: block;
      }
    `
    return <RouteLink
        route={{
            name: 'Root'
        }}
    >
        { !props.short &&
            <img src="/biblo_logo_long.png" width="70px" style={{marginLeft: "12px"}}/>
        }
        { props.short &&
            <Box className="short-logo-box" css={shortLogoBoxCss}>
                <img src="/biblo_short.png" width="20px"  />
            </Box>
        }
    </RouteLink>
}