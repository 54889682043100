/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { LogoutForm } from '../account/LogoutForm';
import { colors } from '../../shared/styles/vars';

import {RouteLink} from "../components/RouteLink";

interface Props {
  sharedToken: string
}

export const GuestMenu = (props: Props) => {

  const noDecLink = css`
    text-decoration: none !important;
    color: ${colors.black};
    &:focus, &:hover, &:visited, &:link, &:active {
        color: ${colors.black};
    }`

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        padding: 8px 20px;
        border-bottom: 1px solid ${colors.clearGrey};
      `}
    >
      <RouteLink
          route={{
            name: 'SharedProject',
            token: props.sharedToken
          }}
      ><img src="/biblo_logo_long.png" width="70px" style={{marginLeft: "20px"}}/></RouteLink>

      <div>

      </div>
    </div>
  );
};
