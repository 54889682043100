/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {useEffect} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Box, Button, DialogContent, Tab, Tabs} from "@mui/material";
import {
    DISLIKE_REACTION,
    Item,
    LIKE_REACTION,
    Listing,
    NEUTRAL_REACTION,
    Reaction,
    toReviewStatus
} from "shared/model/model";
import {css} from "@emotion/react";
import {appContext} from "ApplicationContext";
import {UserInfos} from "shared/model/user";
import {getStatus} from "shared/utils";

export interface Props {
    listingItem: Item
    listing: Listing
    userInfos: UserInfos
    open: boolean
    onClose: () => void
    onCancelReactionRequested: (itemId: string, listingId: string) => void
}

export const ItemReactionsDetailsPanel = (props: Props) => {

    const [currentReactionType, setCurrentReactionType] = React.useState<string | undefined>()
    const [reactions, setReactions] = React.useState<Reaction[]>([])

    const ratings = props.listingItem.reactions ?? {}
    const nbLikeReactions = ratings[LIKE_REACTION] ?? 0
    const nbNeutralReactions = ratings[NEUTRAL_REACTION] ?? 0
    const nbDislikeReactions = ratings[DISLIKE_REACTION] ?? 0

    const defaultSelectedReactionType = nbLikeReactions > 0 ? LIKE_REACTION : nbDislikeReactions > 0 ? DISLIKE_REACTION : NEUTRAL_REACTION

    const status = getStatus(props.listingItem.status)

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentReactionType(newValue);
    };

    useEffect(() => {
        if (!props.listingItem || !props.listing) {
            return
        }
        if (props.open) {
            if (props.listingItem.userReaction) {
                setCurrentReactionType(props.listingItem.userReaction)
            } else {
                setCurrentReactionType(defaultSelectedReactionType)
            }
            retrieveReactions()
        }
    }, [props.open])

    const retrieveReactions = async () => {
        let retrievedReactions = await appContext.listingService().getItemReactions(props.listing.id!!, props.listingItem.id)
        let userReaction = retrievedReactions.find(reaction => reaction.email === props.userInfos.mail)
        if (userReaction) {
            retrievedReactions = [userReaction, ...retrievedReactions.filter(reaction => reaction.email !== props.userInfos.mail)]
        }
        setReactions(retrievedReactions)
    }

    const handleCancelReaction = () => {
        props.onCancelReactionRequested(props.listingItem.id, props.listing.id)
        props.onClose()
    }

    const handleCloseDialog = () => {
        props.onClose();
    };

    return (
        <Dialog onClose={handleCloseDialog} open={props.open} sx={{style: {width: "700px"}}}
                fullWidth={true}>
            <DialogTitle>Réactions</DialogTitle>
            <DialogContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} css={css`
                  button {
                    font-size: 1.2em;
                  }
                `}>
                    <Tabs value={currentReactionType} onChange={handleChange} aria-label="basic tabs example">
                        { (nbLikeReactions > 0) &&
                            <Tab label={`👍 ${nbLikeReactions}`} value={LIKE_REACTION} />
                        }
                        { (nbDislikeReactions > 0) &&
                            <Tab label={`👎 ${nbDislikeReactions}`} value={DISLIKE_REACTION}/>
                        }
                        { (nbNeutralReactions > 0) &&
                            <Tab label={`😐 ${nbNeutralReactions}`}  value={NEUTRAL_REACTION}/>
                        }
                    </Tabs>
                </Box>
                    <Box css={css`
                      .user-reaction {
                        font-weight: bold;
                      }
                    `}>
                        <ul>
                        {
                            reactions.filter(reaction => reaction.reactionType === currentReactionType).map(reaction => (
                                <li className={props.userInfos.mail == reaction.email ? "user-reaction" : ""}>
                                    {reaction.email}
                                    { (props.userInfos.mail == reaction.email) && (!props.listingItem.status || status.level <= toReviewStatus.level) &&
                                        <Button variant={"text"} color={"primary"} onClick={handleCancelReaction} style={{marginLeft: "10px"}}>Annuler ma réaction</Button>
                                    }
                                </li>
                            ))
                        }
                        </ul>
                    </Box>

            </DialogContent>
        </Dialog>
    );
}