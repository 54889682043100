/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    approvedStatus,
    archivedStatus,
    draftStatus,
    Item,
    Listing,
    noUser,
    Project,
    rejectedStatus
} from "shared/model/model";
import {appContext} from "ApplicationContext";
import {Box, Typography} from "@mui/material";
import {ItemList} from "../components/items/item-list/ItemList";
import {GuestMenu} from "./GuestMenu";
import {navigateTo, useTypedParams} from "../../utils/routing-utils";
import {SharedProjectPath} from "../../generated/routing/ApplicationPath.generated";

export const SharedProjectView = () => {
    const routeParams = useTypedParams<SharedProjectPath>();

    const [project, setProject] = useState({items: [] as Item[], listings: [] as Listing[]} as Project);
    const [userInfos, setUserInfos] = useState(noUser)

    const fetchProject: () => Promise<void> = () => {
        return appContext.projectService().getSharedProject(routeParams.token)
            .then((project: Project) => {
                setProject(project);
                let collab = project.collaborators.find((c) => c.inviteToken == routeParams.token)!!
                setUserInfos({...noUser, mail: collab.collaboratorMail})
            })
            .catch((error: Error) => {
                console.log("Could not find project with token: " + routeParams.token + " error: " + error.message);
            })
    };

    const handleRatingChange = (itemId: string, listingId: string | null, rating: string | null) => {
        appContext.projectService().addGuestRating(routeParams.token, itemId, listingId, rating).then((updatedListingItem: Item) => {
            let listing = project.listings.find((l) => l.id == listingId)!!
            updatedListingItem = {...updatedListingItem, userReaction: rating}
            console.log("update guest rating")
            let updatedListing: Listing = {...listing, items: listing.items.map((i) => i.id == itemId ? updatedListingItem : i)}
            const updatedListings = project.listings.map((l) => l.id == listingId ? updatedListing : l)
            let updatedProject = {...project, listings: updatedListings}
            setProject(updatedProject)
        })
    }

    const handleCancelReaction = (itemId: string, listingId: string | null) => {
        handleRatingChange(itemId, listingId, null)
    }

    const handleApprovalChange = (itemId: string, listingId: string | null, approved: boolean) => {
        appContext.projectService().updateGuestApproval(routeParams.token, itemId, listingId, approved).then(() => {
            let listing = project.listings.find((l) => l.id == listingId)!!
            let item = listing.items.find((i) => i.id == itemId)!!
            console.log("maj guest approved")
            let updatedItem: Item = {...item, status: approved ? approvedStatus.code : rejectedStatus.code}
            let updatedListing: Listing = {...listing, items: listing.items.map((i) => i.id == itemId ? updatedItem : i)}
            const updatedListings = project.listings.map((l) => l.id == listingId ? updatedListing : l)
            let updatedProject = {...project, listings: updatedListings}
            setProject(updatedProject)
        })
    }

    const handleItemClicked = (itemId: string, listingId: string) => {
        navigateTo({
            name: 'SharedListingItem',
            token: routeParams.token,
            listingId: listingId,
            itemId: itemId
        })
    }

    useEffect(() => {
        fetchProject();
    }, []);

    return (
        <div
            css={css`
              position: absolute;
              display: flex;
              height: 100%;
              width: 100%;
            `}
        >
            <div
                css={css`
                  flex: 1;
                  overflow: scroll;
                `}
            >
                <GuestMenu sharedToken={routeParams.token}/>
                <div
                    css={css`
                      margin: 30px;
                    `}
                >
                    <div css={css`
                      display: flex;
                    `}>
                        <div
                            css={css`
                              width: 100%
                            `}>

                            <Typography variant="h3" component="div"
                                        gutterBottom>{project.name}</Typography>
                            {
                                project.listings.filter((listing: Listing) => listing.items.length > 0).map((listing) => (
                                    <Box key={listing.id} css={css`margin-bottom: 25px`}>
                                        <Typography css={css`display: inline-block`} variant="h4" component="div"
                                                    gutterBottom>{listing.name}</Typography>
                                        <div
                                            css={css`
                                              width: 100%
                                            `}>
                                            <ItemList projectId={project.id} project={project} listingId={listing.id}
                                                      items={listing.items.filter((i) => (i.status != draftStatus.code) && (i.status != archivedStatus.code))}
                                                      selectionMode={'none'} selectedItemsIds={[]}
                                                      sharingToken={routeParams.token}
                                                      itemAction={"open_origin"}
                                                      userInfos={userInfos}
                                                      onRatingChange={handleRatingChange}
                                                      onApprovalChange={handleApprovalChange}
                                                      onCancelReactionRequested={handleCancelReaction}
                                                      onSelectionChange={() => {
                                                          console.log("sel change!")
                                                      }}
                                                      onItemClicked={(itemId) => handleItemClicked(itemId, listing.id)}
                                                      onStatusClicked={() => null}
                                            />
                                        </div>
                                    </Box>
                                ))

                            }

                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
};
