/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {MenuItem} from "@mui/material";
import {IconButtonMenu} from "view/components/IconButtonMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface Props {
    onDeleteBoard: () => void,
    onDownloadRequested: () => void,
}

export const BoardMoreMenu = (props: Props) => {

    const [menuOpen, setMenuOpen] = React.useState(false);

    const handleMenuStateChange = (open: boolean) => {
        setMenuOpen(open)
    }

    return (
        <IconButtonMenu icon={<MoreVertIcon/>} open={menuOpen} onMenuStateChange={handleMenuStateChange}>

            <MenuItem onClick={props.onDownloadRequested}>Télécharger la planche en JPEG</MenuItem>

            <MenuItem onClick={props.onDeleteBoard}>Supprimer la planche</MenuItem>
        </IconButtonMenu>
    );
};
