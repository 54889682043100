import {create} from "zustand";
import {Color, ListingItemStatus, Material, Typology} from "shared/model/model";
import {getItemColors, getLibraryItemStatuses, getMaterials} from "shared/utils";

interface ReferentialsState {
    setTypologies: (typologies: Typology[]) => void
    typologies: Typology[]
    colors: Color[]
    materials: Material[]
    listingItemStatuses: ListingItemStatus[]
}

export const useReferentialsState = create<ReferentialsState>((set, get) => ({
    setTypologies: (typologies: Typology[]) => {
        set({typologies})
    },
    typologies: [],
    colors: getItemColors(),
    materials: getMaterials(),
    listingItemStatuses: getLibraryItemStatuses()
}))