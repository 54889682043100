/** @jsxImportSource @emotion/react */
import {ApplicationPath} from "../../generated/routing/ApplicationPath.generated";
import {EmotionStyles} from "../../interfaces";
import * as React from "react";
import {PropsWithChildren} from "react";
import styled from "@emotion/styled";
import {Link} from "react-router-dom";
import {buildPath} from "../../utils/routing-utils";

export const RouteLink = (props: PropsWithChildren<Props>) => {
    const Styled = props.forwardCss ? styled(Link)(props.forwardCss) : Link;
    return (
        <Styled to={buildPath(props.route)}>
            {props.children}
        </Styled>
    );
};

interface Props {
    route: ApplicationPath;
    forwardCss?: EmotionStyles;
}