/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import * as React from 'react';
import {colors} from 'shared/styles/vars';
import {Button, IconButton, Tooltip} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import {Item} from "shared/model/model";

import {RouteLink} from "../components/RouteLink";

interface Props {
    item: Item,
    sharedToken: string,
    goBackText: string
    onBackRequested: () => void
    openUrl:() => void
    edit:() => void
}

export const SharedItemViewMenu = (props: Props) => {

    return (
        <div
            css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50px;
        padding: 8px 20px;
        border-bottom: 1px solid ${colors.clearGrey};
        width: 100%;
      `}
        >

            <div css={css`
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  `}>
                <div>
                    <RouteLink
                        route={{
                            name: 'SharedProject',
                            token: props.sharedToken
                        }}
                    ><img src="/biblo_logo_long.png" width="70px" style={{marginLeft: "20px"}}/></RouteLink>
                </div>
                <div>
                    <div>
                        <Button  startIcon={<LaunchIcon/>} onClick={props.openUrl}>Voir sur le site d'origine</Button>
                    </div>
                </div>

            </div>

        </div>
    );
};
