import { pairsToDict } from '../../shared/utils/nominal-class';
import { ApplicationPath } from 'generated/routing/ApplicationPath.generated';

export const routerPathMap = pairsToDict<ApplicationPath['name'], string>([
  ['EditBoard', '/projects/:projectId/boards/:boardId/edit'],
  ['ExtensionTutorial', '/setup-extension'],
  ['Library', '/items'],
  ['LibraryItem', '/items/:itemId'],
  ['ListingItem', '/projects/:projectId/listings/:listingId/items/:itemId'],
  ['Login', '/login'],
  ['Logout', '/logout'],
  ['Project', '/projects/:projectId'],
  ['ProjectBoard', '/projects/:projectId/boards/:boardId'],
  ['ProjectBoards', '/projects/:projectId/boards'],
  ['ProjectListing', '/projects/:projectId/listings/:listingId'],
  ['Register', '/register'],
  ['Root', '/'],
  ['SharedListingItem', '/v/:token/listings/:listingId/items/:itemId'],
  ['SharedProject', '/v/:token']
]);
