/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {appContext} from 'ApplicationContext';
import {MainContainer} from '../../layout/MainContainer';
import {ProjectList} from "./ProjectList";
import {Project} from "shared/model/model";
import MainAppBar from "containers/MainAppBar";
import {Box, Typography} from "@mui/material";
import {ArchivedProjectList} from "./ArchivedProjectList";
import {colors} from "shared/styles/vars";
import Alert from '@mui/material/Alert';
import {neutralLinkStyle, primaryLinkStyle} from "shared/styles/common-styles";
import {useConfirm} from "material-ui-confirm";
import {navigateTo} from "utils/routing-utils";

export const ProjectListView = () => {
    const [projects, setProjects] = useState([] as Project[]);
    const [archivedProjects, setArchivedProjects] = useState([] as Project[]);
    const [archivedDisplay, setArchivedDisplay] = useState(false);
    const [chromeExtensionTutorialDone, setChromeExtensionTutorialDone] = useState(false);
    const [ignoreChromeExtensionTutorial, setIgnoreChromeExtensionTutorial] = useState(false);

    const confirm = useConfirm()

    const fetchUserInfos = async () => {
        let userInfos = await appContext.userService().getUserInfos()
        setChromeExtensionTutorialDone(userInfos.preferences.chromeExtensionTutorialDone ?? false)
        setIgnoreChromeExtensionTutorial(userInfos.preferences.ignoreChromeExtensionTutorial ?? false)
    };

    const fetchItems = () => {
        appContext.projectService().findAll(false)
            .then((projects: Project[]) => {
                setProjects(projects.filter((p) => !p.archived))
                setArchivedProjects(projects.filter((p) => p.archived))
            });
    };

    const handleUnarchive = (projectId: string) => {
        appContext.projectService().unarchiveProjects([projectId])
            .then(() => {
                let unarchivedProject = archivedProjects.find((p) => p.id == projectId)!!
                setProjects([...projects, unarchivedProject])
                setArchivedProjects(archivedProjects.filter((p) => p.id != projectId))
            });
    };

    const handleShowArchived = () => {
        setArchivedDisplay(true)
    }

    const handleOpenExtensionTutorial = () => {
        navigateTo({name: "ExtensionTutorial"})
    }

    const handleIgnoreChromeExtension = () => {
        confirm({
            title: "Extension Biblo pour Chrome",
            description: "L'extension Biblo pour Chrome est le moyen le plus simple d'ajouter des articles à vos projets. Êtes vous sûr de vouloir ignorer cette fonctionnalité ?",
            cancellationText: "Annuler"
        }).then(() => {
            appContext.userService().updatePreferences({ignoreChromeExtensionTutorial: true})
                .then(() => setIgnoreChromeExtensionTutorial(true))
        }).catch(() =>  {
        })
    }

    useEffect(() => {
        fetchUserInfos();
        fetchItems();
    }, []);

    return (
        <MainContainer menu={<MainAppBar/>}>
            <Box style={{marginTop: "60px"}}>
                {!chromeExtensionTutorialDone && !ignoreChromeExtensionTutorial &&
                    <Box>
                        <Alert severity="info" style={{marginBottom: "20px"}}>
                            Installez <span onClick={handleOpenExtensionTutorial} style={{...primaryLinkStyle}}>l'extension Chrome Biblo</span>, pour ajouter des articles facilement depuis n'importe quel site web.
                            <div onClick={handleIgnoreChromeExtension} style={{...neutralLinkStyle, fontSize: "0.9em", textDecoration: "underline", marginTop: "10px"}}>Je ne souhaite pas installer l'extension</div>
                        </Alert>
                    </Box>
                }
                <ProjectList projects={projects}/>
                <Box style={{marginTop: "20px"}}>
                    {!archivedDisplay && (archivedProjects.length > 0) &&
                        <span onClick={handleShowArchived} css={css`
                          cursor: pointer;
                          color: ${colors.darkgrey};

                          &:hover {
                            text-decoration: underline;
                          }
                        `}>Voir les projets archivés...</span>
                    }
                    {archivedDisplay &&
                        <Box>
                            <Typography variant={"body1"} sx={{fontSize: "1.2", fontWeight: "bold"}}>
                                Projets archivés
                            </Typography>
                            <ArchivedProjectList projects={archivedProjects} onUnarchive={handleUnarchive}/>
                        </Box>
                    }
                </Box>
            </Box>
        </MainContainer>
    );
};
