/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react';
import {ItemProject} from "../model/model";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {Box} from "@mui/material";

interface Props {
    pickedProjects: string[],
    userProjects: ItemProject[]
    onPickedProjectsChange: (pickedProjects: string[]) => void;
}

export const ItemProjectPicker = (props: Props) => {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const handleChange = (event: SelectChangeEvent<typeof props.pickedProjects>) => {
        const {
            target: {value},
        } = event;
        let newPickedValue = typeof value === 'string' ? value.split(',') : value;
        // On autofill we get a stringified value.
        props.onPickedProjectsChange(newPickedValue)
    };

    return (
        <Box sx={{marginTop: "20px"}}>
            <FormControl sx={{m: 1, width: 300}} size="small">
                <InputLabel id="project-picker-label">Projet(s)</InputLabel>
                <Select
                    labelId="project-picker-label"
                    id="project-checkbox"
                    multiple
                    value={props.pickedProjects}
                    variant="standard"
                    size="small"
                    onChange={handleChange}
                    input={<OutlinedInput label="Projets"/>}
                    renderValue={(selected) => selected.map((projectId) => (props.userProjects.find((p) => p.id == projectId) || {"name": ""}).name).join(', ')}
                    MenuProps={MenuProps}
                >
                    {props.userProjects.map((project) => (
                        <MenuItem key={project.id} value={project.id}>
                            <Checkbox checked={props.pickedProjects.indexOf(project.id) > -1}/>
                            <ListItemText primary={project.name}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );

}