/** @jsxImportSource @emotion/react */
import type {CSSProperties} from 'react'
import React from "react";
import {DragSourceMonitor, useDrag, XYCoord} from 'react-dnd'

import {ItemTypes} from './ItemTypes'
import {DragSourceItem} from "./DragSourceItem";
import {BoardItem} from "shared/model/model";
import { logDebug } from 'shared/utils';

const itemWidth = 150

const boardBorderSize = 1

const itemWidthString = `${itemWidth}px`

const style: CSSProperties = {
    cursor: 'move',
    position: 'absolute',
    zIndex: 1000
}

export interface Props {
    boardItem: BoardItem
    boardOffset: XYCoord | null
    onDragStart: (item: BoardItem) => void
    onDragAndDropEnd: (item: BoardItem) => void
    onItemClick: (item: BoardItem) => void
    debugMode: boolean
}

interface DropResult {
    name: string
}

export const RefLabel = (props: Props) => {

    const [{isDragging}, drag] = useDrag<DragSourceItem, any, any>(() => ({
        type: ItemTypes.REF_LABEL,
        canDrag: true,
        item: (monitor: DragSourceMonitor<DragSourceItem, any>) => {
            props.onDragStart(props.boardItem)
            return {
                boardItem: props.boardItem,
                clientOffset: monitor.getClientOffset(),
                sourceClientOffset: monitor.getSourceClientOffset(),
                boardOffset: props.boardOffset
            }
        },
        end: (item: DragSourceItem, monitor) => {
            const dropResult = monitor.getDropResult<DropResult>()
            if (item && dropResult) {
                props.onDragAndDropEnd(item.boardItem)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        })
    }), [props.boardOffset])

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        logDebug(`handle click, moi je suis ref label ${props.boardItem.instanceId}`)
        props.onItemClick(props.boardItem)
        e.stopPropagation()
    }

    return (
        <div ref={drag} style={{
            ...style, left: `${props.boardItem.ref?.x ?? 0}px`, top: `${props.boardItem.ref?.y ?? 0}px`
        }} onClick={handleClick}>

            <span key={"caption" + props.boardItem.instanceId}
                  style={{
                      color: "black",
                      width: `20px`,
                      height: `20px`,
                  }} draggable={"false"}>{props.boardItem.refNum}
            </span>

        </div>
    )
}
