/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import * as React from 'react';
import {colors} from 'shared/styles/vars';
import {Box, Button, Divider, IconButton, Tooltip} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {Close} from "@mui/icons-material";
import {ItemEditionMoreMenu} from "./ItemEditionMoreMenu";
import {Item} from "shared/model/model";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import {useUserState} from "state/UserState";

interface Props {
    item: Item
    goBackText: string
    onBackRequested: () => void
    onSaveRequested: () => void
    onDeleteRequested: () => void
}

export const ItemEditionMenu = (props: Props) => {
    const userInfos = useUserState(s => s.userInfos);

    const appBarCss = css`
        .MuiPaper-root {
        background-color: white;
        box-shadow: none;
        border-bottom: 1px solid ${colors.clearGrey};
        color: ${colors.verydarkgrey}
       }`

    return (
        <Box sx={{flexGrow: 1}} css={appBarCss}>
            <AppBar position="fixed">
                <Toolbar>
                    <Tooltip title={props.goBackText}>
                        <IconButton onClick={props.onBackRequested}>
                            <Close/>
                        </IconButton>
                    </Tooltip>

                    <Box sx={{flexGrow: 1}}/>
                { (props.item.ownerId == userInfos?.id) &&
                    <Box sx={{display: "flex"}}>
                        <ItemEditionMoreMenu onDeleteItem={props.onDeleteRequested}/>

                        <Button startIcon={<SaveIcon/>} onClick={props.onSaveRequested}
                                variant="contained">Enregistrer</Button>
                    </Box>
                }

                </Toolbar>
            </AppBar>
        </Box>
    );
};
