import React from 'react'
import {Listing, noListingId, Project} from "shared/model/model";
import {FiltrableItemList} from "view/components/items/filtrable-item-list/FiltrableItemList";
import {Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography} from "@mui/material";

import {useUserState} from "state/UserState";
import {logDebug} from "shared/utils";

interface Props {
    project: Project,
    listing?: Listing | null,
    selectedItemsIds: string[],
    onSelectionChange: (itemId: string) => void;
}

export const ProjectItemsPicker = (props: Props) => {
    const userInfos = useUserState(s => s.userInfos);

    let listing = props.listing

    const [pickSource, setPickSource] = React.useState('project');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPickSource((event.target as HTMLInputElement).value);
    };

    return <div>
        <div>
            {listing && listing.id != noListingId &&
            <Box>
                <FormControl>
                    <FormLabel id="pick-source-radio-group">Ajouter depuis :</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="pick-source-radio-group"
                        name="pick-source-radio-group"
                        defaultValue="project"
                        onChange={handleChange}
                    >
                        <FormControlLabel value="project" control={<Radio/>} label="les articles de ce projet"/>
                        <FormControlLabel value="library" control={<Radio/>} label="toute ma bibliothèque"/>
                    </RadioGroup>
                </FormControl>
            </Box>
            }
            <FiltrableItemList project={props.project} isAddPickerMode={true} isSelectionEnabled={false}
                      listing={props.listing}
                       onCancelReactionRequested={() => null}
                       addFromLibrary={pickSource == "library"}
                        userInfos={userInfos!!}
                      selectedItemsIds={props.selectedItemsIds}
                      onRatingChange={() => null}
                    onApprovalChange={() => null}
                      onSelectionChange={(itemId) => {logDebug('sel change!');props.onSelectionChange(itemId)}}
                      itemAction={null}
                      onStatusClicked={() => null}
                       onFiltersChanged={() => null}
                       onItemClicked={(itemId: string) => null}/>
        </div>
    </div>


}