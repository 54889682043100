/** @jsxImportSource @emotion/react */
import {css} from "@emotion/react";
import React from 'react';
import {ProjectCard} from './ProjectCard'
import {Item, Project} from "shared/model/model";
import {Box, Card, CardActionArea, CardContent, Typography} from "@mui/material";
import {appContext} from "ApplicationContext";
import {v4 as uuidv4} from 'uuid';
import {ProjectNameDialog} from "../project-editor/dialogs/ProjectNameDialog";
import {inspect} from "util";
import {colors} from "shared/styles/vars";

import {RouteLink} from "../../components/RouteLink";

interface Props {
    projects: Project[]
    onUnarchive: (projectId: string) => void
}

export const ArchivedProjectList = (props: Props) => {

    return (
        <div
            css={css`
                .unarchive {
                  font-style: italic;
                  color: ${colors.darkgrey};
                  cursor: pointer;
                }
         `}
        >
            { props.projects.length > 0 &&
                <ul>
                    {
                        props.projects.map((project) => (
                            <li>
                                <span key={project.id}>{project.name}</span> <span className={"unarchive"} onClick={() => props.onUnarchive(project.id)}>(désarchiver)</span>
                            </li>
                        ))
                    }
                </ul>
            }
            { props.projects.length == 0 &&
                <span>Aucun projet archivé</span>
            }
        </div>
    )
};