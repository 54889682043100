import {XYCoord} from "react-dnd";
import {BoardItem} from "shared/model/model";

export interface DragSourceItem {
    boardItem: BoardItem,
    boardOffset: XYCoord | null,
    clientOffset:  XYCoord | null,
    sourceClientOffset: XYCoord | null,
}

export const noBoardItemId = 'none'