/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react';
import {Color, ItemColor} from "../model/model";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {Box, Typography} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import {css} from "@emotion/react"

interface Props {
    itemColor: ItemColor,
    availableColors: Color[]
    onPickedColorsChange: (pickedColors: string[]) => void;
}

export const ItemColorPicker = (props: Props) => {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const availableColors = props.availableColors

    const [pickedColors, setPickedColors] = React.useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<typeof pickedColors>) => {
        const {
            target: {value},
        } = event;
        let newPickedValue = typeof value === 'string' ? value.split(',') : value;
        setPickedColors(
            newPickedValue,
        );
        props.onPickedColorsChange(newPickedValue)
    };

    useEffect(() => {
        setPickedColors(props.itemColor.colors)
    }, [props.itemColor])

    return (
        <Box sx={{marginTop: "20px"}}>
            <FormControl sx={{m: 1, width: 300}} size="small">
                <InputLabel id="color-picker-label">Couleur</InputLabel>
                <Select
                    labelId="color-picker-label"
                    id="color-checkbox"
                    multiple
                    value={pickedColors}
                    variant="standard"
                    size="small"
                    onChange={handleChange}
                    input={<OutlinedInput label="Couleur"/>}
                    renderValue={(selected) => selected.map((code) => (availableColors.find((c) => c.code == code) || {"code": ""}).label).join(', ')}
                    MenuProps={MenuProps}
                >
                    {availableColors.map((color) => (
                        <MenuItem key={color.code} value={color.code}>
                            <Checkbox checked={pickedColors.indexOf(color.code) > -1}/>
                            <ListItem>
                                {color.hexValue != "" &&
                                    <span className={"color-block"} css={css`width: 1em;
                                      height: 1em;
                                      margin-right: 0.5em;
                                      background-color: ${color.hexValue}`}></span>
                                }
                                <Typography>{color.label} </Typography>
                            </ListItem>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );

}