import {createRef, useEffect, useRef, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import {colors} from "shared/styles/vars";
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import IsoIcon from "@mui/icons-material/Iso";
import * as React from "react";
import {Download} from "@mui/icons-material";

/*export interface File {
    name: string;
    lastModified: number;
    size: number;
    type: string;
}*/

export interface UploadFileProps {
    //onFilesSelected?: (files: File[]) => void;
    onFormDataReady: (formData: FormData) => void
    uploading: boolean
    replaceMode: boolean
}

export const UploadFile = (props: UploadFileProps) => {

    const inputRef = createRef<HTMLInputElement>()

    const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files
        if (files == null || files.length < 1) {
            return
        }
        let file = files.item(0)!!
        const formData = new FormData();
        formData.append("image", file, file.name);
        props.onFormDataReady(formData)
    };

    return (
        <Box>
            {!props.replaceMode && !props.uploading &&
            <Box style={{
                border: `5px solid ${colors.clearGrey}`,
                borderRadius: "10px",
                textAlign: "center",
                padding: "20px",
                color: colors.mediumgrey,
                cursor: "pointer",
                maxWidth: "300px"
            }} onClick={(e) => inputRef.current?.click()}>
                {!props.uploading &&
                <Box>
                    <AddPhotoAlternateOutlinedIcon fontSize="large"/>
                    <Typography variant={"body1"} sx={{fontSize: "1.2em", fontWeight: "bold"}}>
                        Charger une image depuis cet appareil</Typography>
                </Box>
                }
            </Box>
            }
            {props.replaceMode && !props.uploading &&
                <Button startIcon={<AddPhotoAlternateOutlinedIcon/>} onClick={(e) => inputRef.current?.click()}>Remplacer l'image</Button>
            }
            {!props.uploading &&
            <input ref={inputRef}
                   type="file"
                   accept="image/*"
                   onChange={handleOnChange}
                   style={{visibility: "hidden", width: "0px"}}
            />
            }
            {props.uploading &&
                    <Box style={{
                        border: `5px solid ${colors.clearGrey}`,
                        borderRadius: "10px",
                        textAlign: "center",
                        padding: "20px",
                        color: colors.mediumgrey
                    }}>
            <Typography variant={"body1"} sx={{fontSize: "1.2em", fontWeight: "bold"}}>
                            Téléchargement en cours...</Typography>
                    </Box>
            }
        </Box>
    );
};