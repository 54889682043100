import {create} from "zustand";
import {applicationBootstrapData} from "../constants";
import {UserInfos} from "shared/model/user";

interface UserState {
    userInfos?: UserInfos
    connected: boolean
    setConnected: (connected: boolean, userInfos: UserInfos) => void
}

export const useUserState = create<UserState>(set => ({
    userInfos: applicationBootstrapData.userInfos,
    connected: false,
    setConnected: (connected: boolean, userInfos: UserInfos) => set({connected, userInfos}),
}))