/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {styled} from '@mui/material/styles';
import Rating, {IconContainerProps} from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import {Box} from "@mui/material";
import {colors} from "shared/styles/vars";
import {ReactionCounter} from "@charkour/react-reactions";
import {ReactionCounterObject} from "@charkour/react-reactions/src/helpers";
import {DISLIKE_REACTION, LIKE_REACTION, NEUTRAL_REACTION} from "shared/model/model";
import {css} from "@emotion/react";
import {ItemReactionPicker} from "./ItemReactionPicker";

interface Props {
    reactions: { [key: string]: number },
    userReaction: string | null
    readOnly: boolean
    onClick: () => void
    onReactionTypePicked: (reactionType: string) => void
}

export const ItemRating = (props: Props) => {

    const getEmoji = (value: string) => {
        switch (value) {
            case DISLIKE_REACTION:
                return "👎"
            case NEUTRAL_REACTION:
                return "😐"
            case LIKE_REACTION:
                return "👍"
            default:
                return "😐"
        }
    }

    const handleReactionTypePicked = (reactionType: string) => {
        props.onReactionTypePicked(reactionType)
    }

    const reactions = props.reactions ?? {}
    const reactionCounters: ReactionCounterObject[] = Object.entries(reactions).filter(([key, value]) => value > 0).map(([key, value]) => ({
        label: key,
        node: <div>{getEmoji(key)}</div>,
        by: ""
    }) as ReactionCounterObject)
    const nbReactions = Object.entries(reactions).filter(([key, value]) => value > 0).map(([key, value]) => value).reduce((sum, current) => sum + current, 0)

    return (
        <Box css={css`
          font-size: 1.2em;
          display: flex;
        `}>
            {(nbReactions > 0) &&
                <div css={css`
                  display: flex;
                  align-items: center;
                  div {
                    box-shadow: none;
                  }

                  .counter {
                    div {
                      box-shadow: none !important;
                    }
                  }
                `} onClick={props.onClick}>
                    <ReactionCounter className={"counter"} iconSize={24} reactions={reactionCounters}
                                     showReactsOnly={true}/>
                    <span style={{
                        color: colors.darkgrey,
                        fontSize: "0.8em",
                        marginLeft: "2px"
                    }}>{nbReactions}</span>
                </div>
            }
            {!props.readOnly && !props.userReaction &&
                <div css={css`
                  margin-left: 10px;
                  `}>
                    <ItemReactionPicker userReaction={props.userReaction} onReactionTypePicked={handleReactionTypePicked}/>
                </div>
            }
        </Box>
    )

}