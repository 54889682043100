/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react';
import {
    defaultItemFilters,
    Item,
    ItemFilters,
    Listing,
    noListing,
    noListingId,
    noProject,
    noTypology,
    Project,
    Typology
} from "shared/model/model";
import {Box, css, Typography} from "@mui/material";
import {ItemList} from "../item-list/ItemList";
import {ItemFiltersDialog} from "../filter-dialog/ItemFiltersDialog";
import {appContext} from "ApplicationContext";
import {FiltrableItemListHeader} from "../FiltrableItemListHeader";
import {colors} from "shared/styles/vars";
import {primaryLinkStyle} from "shared/styles/common-styles";
import {UserInfos} from 'shared/model/user';
import {navigateTo} from "utils/routing-utils";
import {useReferentialsState} from "state/ReferentialsState";
import {logDebug} from "shared/utils";
import {useLocation, useNavigate} from "react-router-dom";
import {filtersToSearchParams, searchParamsToFilters} from 'common/param_utils';

interface Props {
    project: Project | null
    listing?: Listing | null
    isAddPickerMode: boolean
    isSelectionEnabled: boolean
    addFromLibrary: boolean
    userInfos: UserInfos
    itemAction: 'open_origin' | 'open_edition' | null
    selectedItemsIds: string[]
    onSelectionChange: (itemId: string) => void
    onRatingChange: (itemId: string, listingId: string | null, rating: string | null) => void
    onApprovalChange: (itemId: string, listingId: string | null, approved: boolean) => void
    onStatusClicked: (listingItemId: string) => void
    onItemClicked: (itemId: string) => void
    onCancelReactionRequested: (itemId: string, listingId: string) => void
    onFiltersChanged: (filters: ItemFilters) => void
}

export const FiltrableItemList = (props: Props) => {

    const typologies = useReferentialsState(s => s.typologies)

    const project = props.project
    const listing = props.listing

    const [filters, setFilters] = useState(defaultItemFilters);
    const [filtersDialogOpen, setFiltersDialogOpen] = useState(false);

    const [allLibraryItems, setAllLibraryItems] = useState([] as Item[]);

    const [projectLibraryItemsIds, setProjectLibraryItemsIds] = useState([] as string[]);
    const [listingLibraryItemsIds, setListingLibraryItemsIds] = useState([] as string[]);

    const [filteredItems, setFilteredItems] = useState([] as Item[]);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        appContext.itemService().findAll()
            .then((items: Item[]) => {
                setAllLibraryItems(items);
            });
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const filters = searchParamsToFilters(params, typologies);
        setFilters(filters);
        props.onFiltersChanged(filters);
    }, [location, typologies])

    useEffect(() => {
        if (project == null) {
            logDebug("no project !")
            setProjectLibraryItemsIds([])
        } else {
            let newIds = project.items.map((item) => item.id)
            setProjectLibraryItemsIds(newIds)
        }
    }, [project])

    useEffect(() => {
        logDebug("Selected items ids: " + props.selectedItemsIds)
    }, [props.selectedItemsIds])

    useEffect(() => {
        if (listing == null || (listing.id == noListingId)) {
            logDebug("no listing !")
            setListingLibraryItemsIds([])
        } else {
            let newIds = listing.items.map((item) => item.id)
            setListingLibraryItemsIds(newIds)
            logDebug("Just set listing library items ids to: " + newIds)
        }
    }, [listing])

    useEffect(() => {
        let allItems = []
        if (isListingSelected() && !props.isAddPickerMode) {
            let statuses = listing!!.items.map((item) => item.status).join(", ")
            allItems = listing!!.items
        } else if (isProjectSelected() && !props.isAddPickerMode) {
            allItems = project!!.items
        } else {
            allItems = allLibraryItems
        }
        if (props.isAddPickerMode) {
            if (isListingSelected()) {
                allItems = allItems.filter((item) => !(listing!!.items.map((i) => i.id).includes(item.id)))
            } else {
                allItems = allItems.filter((item) => !(project!!.items.map((i) => i.id).includes(item.id)))
            }
        }
        let filteredItems = allItems.filter((item) => {
            return getItemFilteringCondition(item, filters)
        })
        let deduplicatedItems: Item[] = []
        filteredItems.forEach((item) => {
            if (!deduplicatedItems.includes(item)) {
                deduplicatedItems.push(item);
            }
        })
        setFilteredItems(deduplicatedItems)

    }, [allLibraryItems, filters, projectLibraryItemsIds, listingLibraryItemsIds, props.isAddPickerMode, props.addFromLibrary])

    const handleCloseFiltersDialog = () => {
        setFiltersDialogOpen(false)
    }

    const handleResetFilters = () => {
        logDebug("handleResetFilters")
        setFilters(defaultItemFilters)
        props.onFiltersChanged(filters);
        setFiltersDialogOpen(false)
    }


    const handleFiltersUpdated = (updatedFilters: ItemFilters) => {
        logDebug("handleFiltersUpdated")
        setFilters(updatedFilters)
        props.onFiltersChanged(filters);
        setFiltersDialogOpen(false)
        const params = filtersToSearchParams(updatedFilters)
        navigate({search: params.toString()});
    }


    const handleOpenFiltersRequested = () => {
        setFiltersDialogOpen(true)
    }

    const getItemFilteringCondition = (item: Item, filters: ItemFilters) => {
        // If the item doesn't match the filters, ignore it
        if (!((filters.supertypology == null || item.supertypology == filters.supertypology.code)
            && (filters.typology == null || item.typology == filters.typology.code)
            && (filters.subtypology == null || item.subtypology == filters.subtypology.code))) {
            return false
        }
        if (project == null) {
            // We are displaying all the library items that matches the filters
            return true
        }
        if (isListingSelected()) {
            // We are at the listing level
            // If this listing includes this library item, take it
            if (listingLibraryItemsIds.includes(item.id)) {
                return true
            } else if (props.isAddPickerMode) {
                // Otherwise, if we're picking an item, include is if we're picking from the entire library
                // OR only if the project includes it
                return props.addFromLibrary || projectLibraryItemsIds.includes(item.id)
            }
        } else {
            // We are at the project level
            return props.isAddPickerMode || projectLibraryItemsIds.includes(item.id)
        }

    }

    const isListingSelected = () => {
        return (props.listing && (props.listing != noListing))
    }

    const isProjectSelected = () => {
        return (props.project && (props.project != noProject))
    }

    const filteredItemsGroupedBySupertypology: any = filteredItems.reduce((result: any, item) => ({
        ...result,
        [(item.supertypology ?? noTypology.code) as string]: [...(result[item.supertypology ?? noTypology.code] || []), item]
    }), {});

    const presentTypologies = typologies.filter((t) => filteredItems.map((item) => item.supertypology ?? noTypology.code).includes(t.code))

    const handleOpenExtensionTutorial = () => {
        navigateTo({name: "ExtensionTutorial"})
    }

    return (
        <Box sx={{width: '100%'}}>

            {
                (project == null && listing == null && allLibraryItems.length == 0) &&
                <Box style={{color: colors.darkgrey}}>
                    <p>
                        Vous n'avez pas encore ajouté d'articles à votre bibliothèque.
                    </p>
                    <p>
                        Vous pouvez ajouter des articles directement depuis une page web grâce à l'<span
                        onClick={handleOpenExtensionTutorial}
                        style={{...primaryLinkStyle}}>extension Biblo pour Chrome</span>,
                        ou manuellement grâce au bouton + en haut à droite de l'écran.
                    </p>
                </Box>
            }

            {
                (project != null || listing != null || allLibraryItems.length > 0) &&
                <Box>
                    <Box sx={{marginBottom: '30px'}}>
                        {filtersDialogOpen &&
                            <ItemFiltersDialog
                                filters={filters}
                                onFiltersUpdated={handleFiltersUpdated}
                                onClose={handleCloseFiltersDialog}
                                onReset={handleResetFilters}
                            />
                        }
                        {!filtersDialogOpen &&
                            <FiltrableItemListHeader filters={filters} nbItems={filteredItems.length}
                                                     onOpenRequested={handleOpenFiltersRequested}/>
                        }
                    </Box>

                    {
                        presentTypologies.map((t: Typology) => {
                            return <Box sx={{marginBottom: "30px"}}>
                                <Typography variant={"h5"}
                                            css={css`text-align: left`}>{t.label == "-" ? "Divers" : t.label}</Typography>
                                <ItemList items={(filteredItemsGroupedBySupertypology[t.code ?? ""] ?? [])}
                                          project={project ?? noProject}
                                          projectId={props.project?.id ? props.project?.id : null}
                                          listingId={props.listing?.id ? props.listing?.id : null}
                                          selectionMode={props.isAddPickerMode ? "pickable" : (project != null ? "selectable" : "none")}
                                          selectedItemsIds={props.selectedItemsIds}
                                          sharingToken={null}
                                          userInfos={props.userInfos}
                                          onSelectionChange={props.onSelectionChange}
                                          onRatingChange={props.onRatingChange}
                                          onApprovalChange={props.onApprovalChange}
                                          itemAction={props.itemAction}
                                          onStatusClicked={props.onStatusClicked}
                                          onCancelReactionRequested={props.onCancelReactionRequested}
                                          onItemClicked={props.onItemClicked}/>
                            </Box>
                        })
                    }
                </Box>
            }
        </Box>
    )
};
