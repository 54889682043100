/** @jsxImportSource @emotion/react */
import * as React from "react";
import {Typography} from "@mui/material";
import {Project} from "shared/model/model";
import {appContext} from "ApplicationContext";
import {navigateTo} from "../../../utils/routing-utils";

interface Props {
    project: Project
}

export const ProjectLink = (props: Props) => {

    const goToProject = () => {
        navigateTo({'name': 'Project', 'projectId': props.project.id})
    }

    return <Typography
        variant="h5"
        noWrap
        component="div"
        sx={{display: {xs: 'none', sm: 'block'}, cursor: 'pointer'}}
    >
        <span onClick={goToProject}>{props.project.name}</span>
    </Typography>

}