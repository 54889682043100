/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { PasswordInput } from '../components/PasswordInput';
import { SimpleForm } from '../components/SimpleForm';
import { TextInput } from '../components/TextInput';
import {neutralContained} from "shared/styles/common-styles";

export interface LoginFormDto {
  username: string;
  password: string;
}

interface Props {
  loginInProgress: boolean
  onSubmit: (dto: LoginFormDto) => void;
}

export const LoginForm = (props: Props) => {
  const [password, setPassword] = useState('');
  return (
    <SimpleForm
      onSubmit={(dto: { username: string }) =>
        props.onSubmit({
          username: dto.username,
          password
        })
      }
    >
      <div
        css={css`
          margin: 10px 0;
        `}
      >
        <TextInput name={'username'} label={'Adresse e-mail'} />
      </div>
      <div
        css={css`
          margin: 20px 0;
        `}
      >
        <PasswordInput
          label="Mot de passe"
          value={password}
          setValue={setPassword}
        />
      </div>
      <div
          css={css`
            text-align: center
          `}
      >
        <Button type="submit" variant="contained" sx={{...neutralContained}} disabled={props.loginInProgress}>Se connecter</Button>
      </div>
    </SimpleForm>
  );
};
