import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, Button} from "@mui/material";
import {ItemFilters, noTypology, Typology} from "shared/model/model";
import {getTypology} from "shared/utils";
import {colors} from "shared/styles/vars";

import {useReferentialsState} from "state/ReferentialsState";


export interface Props {
    filters: ItemFilters
    nbItems: number
    onOpenRequested: () => void
}

export const FiltrableItemListHeader = (props: Props) => {
    const typologies = useReferentialsState(s => s.typologies)

    const [supertypology, setSupertypology] = useState('');
    const [typology, setTypology] = useState('');
    const [subtypology, setSubtypology] = useState('');

    useEffect(() => {
        setSupertypology((props.filters.supertypology ?? noTypology).code)
        setTypology((props.filters.typology ?? noTypology).code)
        setSubtypology((props.filters.subtypology ?? noTypology).code)
    }, [props.filters])

    const handleOpen = () => {
        props.onOpenRequested()
    }

    var currentSupertypology = getTypology(typologies, supertypology)
    var currentTypology = getTypology(typologies, typology)
    var currentSubtypology = getTypology(typologies, subtypology)

    return (
        <Box>
            {currentSupertypology.code == noTypology.code &&
                <Box>
                    {(props.nbItems == 0) &&
                        <span style={{"color": colors.darkgrey}}>Aucun article</span>
                    }
                    {(props.nbItems > 0) &&
                        <Box>
                            <span>{props.nbItems} articles</span>
                            <Button onClick={() => handleOpen()}>Filtrer</Button>
                        </Box>
                    }
                </Box>
            }
            {currentSupertypology.code != noTypology.code &&
                <Box>
                <span style={{"color": colors.darkgrey}}>
                    {(props.nbItems == 0) &&
                        <span>Aucun article correspondant à : </span>
                    }
                    {(props.nbItems > 0) &&
                        <span>{props.nbItems} article{props.nbItems > 1 ? "s" : ""} correspondant à : </span>
                    }
                </span>

                    <span>
                            {currentSupertypology.label}
                        {currentTypology.code != noTypology.code &&
                            <span>
                                <span> &gt; {currentTypology.label}</span>
                                {currentSubtypology.code != noTypology.code &&
                                    <span> &gt; {currentSubtypology.label}</span>
                                }
                                </span>
                        }
                            </span>
                    <Button onClick={() => handleOpen()}>Modifier les filtres</Button>
                </Box>
            }
        </Box>
    );
}