import React from "react";
import {PickableItem} from "./PickableItem";
import {BoardItem, Item} from "shared/model/model";
import {XYCoord} from "react-dnd";
import {Typography} from "@mui/material";

interface Props {
    items: Item[],
    height: number,
    boardOffset: XYCoord | null
    onItemDragged: (item: BoardItem) => void
    onItemDropped: (item: BoardItem) => void
    onDragAndDropEnd: (item: BoardItem) => void
}

export const PickableItemList = (props: Props) => {

    return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: "185px",
                height: props.height + "px",
                overflowY: "auto",
                paddingBottom: '220px',
                marginRight: "50px"
            }}>
                {props.items.map((item) => {
                    return <PickableItem poardOffset={props.boardOffset} item={item} onDragStart={props.onItemDragged}
                                         onDragAndDropEnd={props.onDragAndDropEnd} key={item.id}/>
                })}
            </div>
    );
};