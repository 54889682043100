import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Box, FormControl, TextField} from "@mui/material";

export interface Props {
    creationMode: boolean,
    projectName: string,
    open: boolean;
    onClose: () => void;
    onNameValidated: (name: string) => void;
}

export const ProjectNameDialog = (props: Props) => {

    const [projectName, setProjectName] = useState('')

    useEffect(() => {
        setProjectName(props.projectName)
    }, [props.projectName]);

    const handleCloseDialog = () => {
        props.onClose();
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProjectName(e.target.value)
    }

    const submit = () => {
        props.onNameValidated(projectName)
    }

    const handleNameKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            submit()
        }
    }

    return (
        <Dialog onClose={handleCloseDialog} open={props.open}>
            <DialogTitle fontSize={"1.5em"}>{props.creationMode ? "Créer un projet" : "Renommer le projet"}</DialogTitle>
            <Box style={{marginLeft: "25px", marginBottom: "25px", marginRight: "25px"}}>

                <FormControl sx={{m: 1, minWidth: 280}}>
                    <TextField onChange={handleNameChange} onKeyDown={handleNameKeyDown} variant="standard"
                               InputProps={{}} autoFocus={true} value={projectName} placeholder={"Nom du projet"}/>
                </FormControl>

                <Box style={{marginTop: "20px"}}>
                    <FormControl>
                        <Button onClick={submit} disabled={"" == projectName}>{props.creationMode ? "Créer" : "Renommer"}</Button>
                    </FormControl>
                </Box>
            </Box>
        </Dialog>
    );
}