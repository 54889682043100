/** @jsxImportSource @emotion/react */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {css} from "@emotion/react";
import {colors} from "shared/styles/vars";
import {Button, Divider, Tooltip} from "@mui/material";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import {AccountCircleOutlined} from "@mui/icons-material";
import {RootLink} from "view/components/RootLink";
import {Listing, noListing, Project} from "shared/model/model";
import {ProjectMoreMenu} from "./ProjectMoreMenu";
import CloseIcon from "@mui/icons-material/Close";
import {ProjectLink} from "../project-list/ProjectLink";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {navigateTo} from "utils/routing-utils";
import {useUserState} from "state/UserState";

interface Props {
    projectNameDisplayed: boolean
    project: Project
    listing: Listing | null
    onSaveClicked: () => void
    onCancelClicked: () => void
    onRenameProject: () => void
    onArchiveProject: () => void
    onDeleteProject: () => void
    onDeleteItems: () => void
    onLogout: () => void
    onChangeItemsStatus: () => void
    onSharingUrlRequested: () => void
    onManageCollaboratorsRequested: () => void
    isAddPickerMode: boolean
    selectedItems: String[]
}

export const ProjectAppBar = (props: Props) => {
    const userInfos = useUserState(s => s.userInfos);
    const setConnected = useUserState(s => s.setConnected);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);

    const isSelectionEditionMode: boolean = !props.isAddPickerMode && (props.selectedItems.length > 0)
    const isListingSelected: boolean = (props.listing != null) && (props.listing != noListing)

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleGoToLibrary = () => {
        navigateTo({'name': 'Library'})
    };

    const handleLogout = () => {
        handleMenuClose()
        props.onLogout()
    };

    const menuId = 'primary-search-account-menu';
    const menuTextStyle = {marginBottom: "16px", marginTop: "16px"}
    const renderDesktopMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
        </Menu>
    );

    const appBarCss = css`
      .MuiPaper-root {
        background-color: white;
        box-shadow: none;
        border-bottom: 1px solid ${colors.clearGrey};
        color: ${colors.verydarkgrey}
      }
    `

    return (
        <Box sx={{flexGrow: 1}} css={appBarCss}>
            <AppBar position="fixed">
                {!props.isAddPickerMode && !isSelectionEditionMode &&
                    <Toolbar>
                        <RootLink short={true}/>
                        {props.projectNameDisplayed &&
                            <ProjectLink project={props.project}/>
                        }
                        <Box sx={{flexGrow: 1}}/>
                        <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                            {(props.project.ownerId == userInfos?.id) &&
                                <ProjectMoreMenu onRenameProject={props.onRenameProject}
                                                 onDeleteProject={props.onDeleteProject}
                                                 onSharingUrlRequested={props.onSharingUrlRequested}
                                                 onManageCollaboratorsRequested={props.onManageCollaboratorsRequested}
                                                 onArchiveProject={props.onArchiveProject}/>
                            }
                            <Divider css={css`margin-right: 10px;`} orientation="vertical" flexItem/>
                            <Tooltip title="Bibliothèque">
                                <IconButton size="large" color="inherit" aria-label="ouvrir la bibliothèque"
                                            onClick={handleGoToLibrary}>
                                    <CollectionsBookmarkIcon/>
                                </IconButton>
                            </Tooltip>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <AccountCircleOutlined/>
                            </IconButton>
                        </Box>
                    </Toolbar>
                }
                {props.isAddPickerMode &&
                    <Toolbar>
                        <div css={css`
                          width: 100%;
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        `}>
                            <IconButton aria-label="Cancel" onClick={props.onCancelClicked} component="label">
                                <CloseIcon/>
                            </IconButton>
                            <Button variant="contained"
                                    onClick={props.onSaveClicked}>Ajouter {isListingSelected ? "à la liste" : "au projet"}</Button>
                        </div>
                    </Toolbar>
                }
                {isSelectionEditionMode &&
                    <Toolbar>
                        <div css={css`
                          width: 100%;
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        `}>
                            <IconButton aria-label="Cancel" onClick={props.onCancelClicked} component="label">
                                <CloseIcon/>
                            </IconButton>
                            <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                                { isListingSelected &&
                                    <Button variant="outlined" startIcon={<AutorenewIcon/>} onClick={props.onChangeItemsStatus}>Modifier le
                                        statut</Button>
                                }
                                <Button startIcon={<RemoveCircleOutlineIcon/>}
                                        onClick={props.onDeleteItems}>Supprimer {isListingSelected ? "de la liste" : "du projet"}</Button>
                            </Box>
                        </div>
                    </Toolbar>
                }
            </AppBar>
            {renderDesktopMenu}
        </Box>
    );
}
