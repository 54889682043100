/** @jsxImportSource @emotion/react */
import {Outlet} from 'react-router-dom';
import {css} from "@emotion/react";
import {appContext} from "../../ApplicationContext";
import {useEffect, useState} from "react";
import {navigateTo} from "../../utils/routing-utils";
import {useUserState} from "../../state/UserState";

export const LoginLayout = () => {

    return <div
        css={css`
          width: 100vw;
          height: 100vh;
          display: flex;
          flex-direction: column;
        `}
    >
        <div
            css={css`
        flex: 1;
        overflow: scroll;
        padding: 10px;
      `}
        >
                <Outlet />
        </div>
    </div>

}