import {colors} from "../styles/vars";
import {UserInfos} from "./user";

export interface UserPreferences {
    chromeExtensionTutorialDone?: boolean | null
    ignoreChromeExtensionTutorial?: boolean | null
}

export interface Color {
    code: string,
    label: string
    hexValue: string
}

export interface Material {
    code: string
    label: string
}

export interface ListingItemStatus {
    code: string
    label: string
    color: string
    textColor: string
    level: number
}

export enum FilterType {
    SUPERTYPOLOGY,
    TYPOLOGY,
    SUBTYPOLOGY,
    PRICE_RANGE
}

export interface CropDto {
    x: number,
    y: number,
    width: number,
    height: number
}

export interface ImageDto {
    id: string
    rawImageId: string | null
    crop: CropDto | null
}

export interface Collaborator {
    collaboratorMail: string
    role: CollaboratorRole
    inviteToken: string
    accepted: boolean
}

export interface Comment {
    commentId: string,
    commentText: string,
    authorId: string,
    authorDisplayName: string
}

export interface Filter {
    type: FilterType,
    value: string[]
}

export interface Range {
    min: number | null
    max: number | null
}

export interface ItemFilters {
    supertypology : Typology | null,
    typology: Typology | null,
    subtypology: Typology | null,
    width: Range | null
}

export interface Dimensions {
    length: number | null,
    width: number | null,
    height: number | null,
    diameter: number | null
}

export interface Typology {
    code: string,
    label: string,
    subtypologies: Typology[]
}

export interface ItemTagging {
    tags: string[]
}

export interface ItemColor {
    colors: string[]
}

export interface ItemMaterial {
    materials: string[]
}

export interface ItemProject {
    id: string,
    name: string
}

export interface Reaction {
    reactionType: string
    email: string
}

export interface Item {
    id: string,
    listingItemId: string | null,
    url: string,
    name: string,
    brand: string | null,
    price: number | null,
    currency: string,
    packingUnit: string,
    dimensions: Dimensions,
    color: ItemColor,
    material: ItemMaterial,
    imageUrl: string,
    imageId: string,
    originalImageId: string | null,
    supertypology: string | null,
    typology: string | null,
    subtypology: string | null,
    notes: string,
    ownerId: string,
    index: number,
    reactions: { [key: string]: number },
    userReaction: string | null,
    projects: ItemProject[],
    tagging: ItemTagging,
    comments: Comment[],
    status: string,
    libraryItemDeleted: boolean
    lastModified: string | null
}

export interface ProjectBoard {
    id: string,
    name: string
}

export interface Project {
    id: string,
    ownerId: string,
    name: string,
    items: Item[],
    boards: ProjectBoard[],
    listings: Listing[],
    collaborators: Collaborator[],
    archived: Boolean
}

export interface Position {
    x: number,
    y: number
}

export interface Size {
    w: number,
    h: number
}

export interface RefObject {
    x: number
    y: number
    w: number
    h: number
}

export interface ImageObject {
    id: string
    x: number
    y: number
    w: number
    h: number
    z: number
    r: number
    blobData: Blob | null
}

export interface BoardItem {
    instanceId: string
    itemId: string
    text: string
    refNum: number
    image: ImageObject
    ref: RefObject | null
}

export interface BoardContent {
    items: BoardItem[]
}

export interface BoardOptions {
    options: any
}

export interface Board {
    id: string
    name: string
    content: BoardContent
    sourceListingId: string | null
    options: BoardOptions
}

export interface Listing {
    id: string
    name: string
    projectId: string
    projectName: string
    items: Array<Item>
    shared: boolean
}

export interface Referentials {
    colors: Color[]
    materials: Material[]
    typologies: Typology[]
    statuses: ListingItemStatus[]
}

export const noColors = {colors: []}

export const noMaterials = {materials: []}

export const noItemId = 'NO_ITEM_ID'

export const noProjectId = 'NO_PROJECT_ID'

export const newItemId = 'default'

export const noListingId = 'NO_LISTING_ID'

export const noItem: Item = { id: noItemId, listingItemId: noItemId, url: '', name: '', brand: '', price: 0.0, currency: '', packingUnit: '', dimensions: {height: null, width: null, length: null, diameter: null}, color: {colors: []}, material: {materials: []}, imageUrl: '', imageId: '', originalImageId: '', typology: '', supertypology: '', subtypology: '', notes: '', ownerId: '', index: 0, reactions: {}, userReaction: null, tagging: {tags: []}, projects: [], comments:[], status: '', libraryItemDeleted: false, lastModified: '1970-01-01T00:00:00Z' }

export const noProject: Project = { id: noProjectId, ownerId: '', name: '', items: [] as Item[], boards: [] as ProjectBoard[], listings: [] as Listing[], collaborators: [] as Collaborator[], archived: false}

export const noListing: Listing = { id: noListingId, name: '-', items: [] as Item[], projectId: '', projectName: '', shared: false}

export const noBoard: Board = { id: '', name: '', content: {items: []}, sourceListingId: noListingId, options: {options: {}}}

export const noProjectBoard: ProjectBoard = { id: '', name: ''}

export const noTypology: Typology = { code: 'none', label: '-', subtypologies: [{code: 'none', label: '-', subtypologies: []}]}

export const noStatus: ListingItemStatus = { code: '', label: '', color: '', textColor: '', level: 0}

export const defaultItemFilters: ItemFilters = {supertypology: null, typology: null, subtypology: null, width: null}

export const materialsTypologyCode = "materials"

export const inspirationTypologyCode = "ambience"

export const furnityreTypologyCode = "furniture"

export const draftStatus: ListingItemStatus =  {code: "draft", label: "Brouillon", color: colors.clearGrey, textColor: "black", level: 0}

export const proposedStatus: ListingItemStatus = {code: "proposed", label: "Proposé", color: colors.yellow, textColor: "black", level: 1}

export const toReviewStatus: ListingItemStatus = {code: "to_review", label: "A valider", color: colors.lightgreen, textColor: "black", level: 2}

export const rejectedStatus: ListingItemStatus = {code: "rejected", label: "Rejeté", color: colors.darkRed, textColor: "white", level: 3}

export const approvedStatus: ListingItemStatus = {code: "approved", label: "Validé", color: colors.darkgreen, textColor: "white", level: 3}

export const archivedStatus: ListingItemStatus = {code: "archived", label: "Archivé", color: colors.darkgrey, textColor: "white", level: 3}

export const optionDisplayCaptionRefs: String = 'displayCaptionRefs'

export type BoardOptionKey = "displayCaptionRefs" | "displayBrandCaption" | "displayPriceCaption" | "displayDimsCaption"

export type ItemCardSelectionMode = "pickable" | "selectable" | "pickable_selectable" | "none"

export type CollaboratorRole = "COMMENTER" | "EDITOR"

export const LIKE_REACTION = "LIKE"
export const DISLIKE_REACTION = "DISLIKE"
export const NEUTRAL_REACTION = "NEUTRAL"

export const defaultUserPrefs = {chromeExtensionTutorialDone: false, ignoreChromeExtensionTutorial: false}

export const noUser: UserInfos = {id: '', mail: '', admin: false, preferences: defaultUserPrefs}

export const noCollab = {collaboratorMail: '', role: 'COMMENTER', inviteToken: '', accepted: false}

export const supertypologyQueryParamName = 'supertypology'
export const typologyQueryParamName = 'typology'
export const subtypologyQueryParamName = 'subtypology'
