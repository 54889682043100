import type {CSSProperties} from 'react'
import React from "react";
import {DragSourceMonitor, useDrag, XYCoord} from 'react-dnd'

import {ItemTypes} from './ItemTypes'
import {BoardItem, ImageObject, Item, RefObject} from "shared/model/model";
import {DragSourceItem, noBoardItemId} from "./DragSourceItem";
import {getThumbnailImageUrl} from "shared/utils";

const itemWidth = 150
const itemWidthString = `${itemWidth}px`

const style: CSSProperties = {
    backgroundColor: 'white',
    cursor: 'move',
    float: 'left',
    width: itemWidthString
}

export interface Props {
    item: Item
    poardOffset: XYCoord | null
    onDragStart: (item: BoardItem) => void
    onDragAndDropEnd: (item: BoardItem) => void
}

interface DropResult {
    name: string
}

export const PickableItem = (props: Props) => {

    const imgRef = React.createRef<HTMLImageElement>();

    const [{isDragging}, drag] = useDrag<DragSourceItem, any, any>(() => ({
        type: ItemTypes.IMAGE,
        item: (monitor: DragSourceMonitor<DragSourceItem, any>) => {
            let plankItem = {
                instanceId: noBoardItemId,
                itemId: props.item.id,
                text: props.item.name,
                refNum: 0,
                image: {
                    id: props.item.imageId,
                    x: 0,
                    y: 0,
                    h: imgRef.current?.height!!,
                    w: imgRef.current?.width!!,
                    z: 1
                } as ImageObject,
                ref: {
                    x: 10,
                    y: 10,
                    w: 20,
                    h: 20
                } as RefObject
            } as BoardItem
            props.onDragStart(plankItem)
            return {
                boardItem: plankItem,
                clientOffset: monitor.getClientOffset(),
                sourceClientOffset: monitor.getSourceClientOffset(),
                boardOffset: props.poardOffset
            }
        },
        end: (item: DragSourceItem, monitor) => {
            const dropResult = monitor.getDropResult<DropResult>()
            if (item && dropResult) {
                props.onDragAndDropEnd(item.boardItem)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        })
    }), [imgRef])

    const opacity = isDragging ? 0.4 : 1

    const imageUrl = getThumbnailImageUrl(props.item.imageId)

    return (
        <div style={{padding: "10px"}}>
            <div ref={drag} style={{...style, opacity}}>
                <img ref={imgRef}
                     src={imageUrl}
                     alt={props.item.name}
                     width={itemWidthString}
                />
            </div>
        </div>
    )
}
