/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import {
    Color,
    CropDto, ImageDto,
    inspirationTypologyCode,
    Item, ItemColor, ItemMaterial,
    ItemProject,
    Material,
    materialsTypologyCode, noColors,
    noItem, noMaterials,
    noTypology, Typology
} from "shared/model/model";
import {appContext} from "ApplicationContext";
import {MainContainer} from "../../layout/MainContainer";
import {css} from "@emotion/react";
import {useConfirm} from "material-ui-confirm";
import CropIcon from '@mui/icons-material/Crop';

import {
    AlertColor,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import EditIcon from "@mui/icons-material/Edit";
import {EventSnackBar} from "view/components/EventSnackBar";
import {
    getDownloadImageUrl,
    getStandardImageUrl,
    getThumbnailImageUrl,
    getTypology,
    logInfo,
    parseFloatLocale
} from 'shared/utils';
import {ItemEditionMenu} from "./ItemEditionMenu";
import {UploadFile} from "view/components/UploadFile";
import config from "config";
import _ from "lodash";
import {ItemProjectPicker} from "shared/components/ItemProjectPicker";
import {ImageCropperDialog} from "./ImageCropperDialog";
import {ItemColorPicker} from "shared/components/ItemColorPicker";
import {ItemMaterialPicker} from "shared/components/ItemMaterialPicker";
import {navigateTo} from "../../../utils/routing-utils";
import {useNavigate} from "react-router-dom";
import {useReferentialsState} from "state/ReferentialsState";
import {Download} from "@mui/icons-material";
import downloadjs from "downloadjs";
import {DownloadableImg} from "../../components/DownloadableImg";
import {HttpResponse} from "../../../shared/services/HttpService";

interface Props {
    itemId: string
    goBackText: string
}

export const ItemEditor = (props: Props) => {
    const navigate = useNavigate()

    const [item, setItem] = useState(noItem);
    const [deleted, setDeleted] = useState(false);
    const [brand, setBrand] = useState('');
    const [price, setPrice] = useState('');
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [currency, setCurrency] = useState('');
    const [packingUnit, setPackingUnit] = useState('');
    const [imageId, setImageId] = useState('');
    const [rawImageId, setRawImageId] = useState('');
    const [crop, setCrop] = useState<CropDto | null>(null);
    const [isImageUploading, setImageUploading] = useState(false);
    const [supertypology, setSupertypology] = useState('');
    const [typology, setTypology] = useState('');
    const [subtypology, setSubtypology] = useState('');
    const [length, setLength] = useState('');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [diameter, setDiameter] = useState('');
    const [color, setColor] = useState<ItemColor>(noColors);
    const [material, setMaterial] = useState<ItemMaterial>(noMaterials)
    const [notes, setNotes] = useState<string>('');
    const [userProjects, setUserProjects] = useState<ItemProject[]>([]);
    const [pickedProjects, setPickedProjects] = useState<string[]>([]);

    const [snackBarDisplayed, setSnackBarDisplayed] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarSeverity, setSnackBarSeverity] = useState<AlertColor>('info');

    const [urlEdition, setUrlEdition] = useState<boolean>(false)
    const [itemCropperDialogOpen, setItemCropperDialogOpen] =  useState<boolean>(false)
    const [cropping, setCropping] = useState(false)

    const availableColors = useReferentialsState(s => s.colors)
    const availableMaterials = useReferentialsState(s => s.materials)
    const typologies = useReferentialsState(s => s.typologies)

    const confirm = useConfirm();

    const onFormDataReady = async (formData: FormData) => {
        setImageUploading(true)
        try {
            let imageDto = await appContext.sessionAwareHttpService().postMultipart<ImageDto>(config.base.url + '/api/dd/images/upload', formData)
            setImageId(imageDto.id)
            setRawImageId(imageDto.id)
        } catch (e) {
            setSnackBarMessage(`Erreur lors du téléchargement de l'image, veuillez essayer de la convertir en JPG ou PNG au préalable`)
            setSnackBarSeverity("error")
            setSnackBarDisplayed(true)
        } finally {
            setImageUploading(false)
        }
    };

    useEffect(() => {
        setDeleted(item.libraryItemDeleted)
        setName(item.name)
        setUrl(item.url ?? "")
        setImageId(item.imageId)
        setBrand(item.brand ?? "")
        setPrice(item.price ? item.price.toLocaleString() : '')
        setCurrency(item.currency)
        setPackingUnit(item.packingUnit ?? "")
        setSupertypology(item.supertypology ? item.supertypology : noTypology.code)
        setTypology(item.typology ? item.typology : noTypology.code)
        setSubtypology(item.subtypology ? item.subtypology : noTypology.code)
        setLength(item.dimensions.length?.toLocaleString() ?? '')
        setWidth(item.dimensions.width?.toLocaleString() ?? '')
        setHeight(item.dimensions.height?.toLocaleString() ?? '')
        setDiameter(item.dimensions.diameter?.toLocaleString() ?? '')
        setColor(item.color)
        setMaterial(item.material)
        setNotes(item.notes)
        setPickedProjects(item.projects.map((p) => p.id))
        if (item.imageId) {
            appContext.httpService().get(config.base.url + `/api/dd/images/props/${item.imageId}`).then((resp: HttpResponse) => {
                let image: ImageDto = resp.body
                setRawImageId( image.rawImageId ?? image.id)
                setCrop(image.crop)
            })
        }
    }, [item]);

    const fetchUserProjects: () => Promise<void> = () => {
        return appContext.projectService().findAll(false).then((projects) => {
            setUserProjects(projects.map((p) => {
                return {"id": p.id, "name": p.name};
            }))
        })
    }

    const fetchItem: () => Promise<void> = () => {
        return appContext.itemService().getItem(props.itemId)
            .then((item: Item) => {
                setItem({
                    ...item,
                    supertypology: item.supertypology ? item.supertypology : noTypology.code,
                    typology: item.typology ? item.typology : noTypology.code,
                    subtypology: item.subtypology ? item.subtypology : noTypology.code,
                });
            });
    };

    const handleBack = () => {
        let updatedItem = isInspiration ? buildUpdatedInspirationItem() : buildUpdatedItem()
        if (_.isEqual(item, updatedItem)) {
            navigate(-1)
            return
        }
        confirm({
            title: "Ignorer les modifications",
            description: "Êtes-vous sûr de vouloir quitter l'éditeur d'article sans sauvegarder les modifications réalisées ?",
            cancellationText: "Annuler"
        })
            .then(() => {
                return navigate(-1)
            })
            .catch(() => {
                console.log("error when confirming back action")
            });
    }


    const openUrl = () => {
        window.open(url, '_blank')
    }

    const editUrl = () => {
        setUrlEdition(true)
    }

    const closeUrlEdition = () => {
        setUrlEdition(false)
    }

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    }

    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value)
    }

    const handleBrandChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBrand(e.target.value)
    }

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //if (e.target.value && (typeof(parseFloatLocale(e.target.value)) != 'number')) {
        setPrice(e.target.value)
        //}
    }

    const handleCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrency(e.target.value)
    }

    const handleColorChange = (pickedColors: string[]) => {
        console.log("handleColorChange: " + pickedColors)
        setColor({colors: pickedColors})
    }

    const handleMaterialChange = (pickedMaterials: string[]) => {
        console.log("handleMaterialChange: " + pickedMaterials)
        setMaterial({materials: pickedMaterials})
    }

    const handlePackingUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPackingUnit(e.target.value)
    }

    const handleSupertypologyChange = (e: SelectChangeEvent) => {
        setSupertypology(e.target.value)
        let supertypo = getTypology(typologies, typology)
        setTypology(noTypology.code)
        setSubtypology(noTypology.code)
        if (supertypo.code != materialsTypologyCode) {
            setPackingUnit('')
        }
    }

    const handleTypologyChange = (e: SelectChangeEvent) => {
        setTypology(e.target.value)
        setSubtypology(noTypology.code)
    }

    const handleSubtypologyChange = (e: SelectChangeEvent) => {
        setSubtypology(e.target.value)
    }

    const handleLengthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLength(e.target.value)
    }

    const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWidth(e.target.value)
    }

    const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHeight(e.target.value)
    }

    const handleDiameterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDiameter(e.target.value)
    }

    const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(e.target.value)
    }

    const handlePickedProjectsChange = (pickedProjects: string[]) => {
        console.log("handlePickedProjectsChange: " + pickedProjects)
        setPickedProjects(pickedProjects)
    }

    function handleFieldError(fieldName: string) {
        setSnackBarSeverity('error')
        setSnackBarMessage(fieldName + " invalide")
        setSnackBarDisplayed(true)
    }

    const checkValid = () => {
        if (item.id == noItem.id) {
            console.log("Trying to save item that doesn't exist !")
            return false
        }
        if (isInspiration) {
            return true
        }
        let validationResult = appContext.itemService().checkValid({
            name: name,
            price: price,
            currency: currency,
            width: width,
            length: length,
            height: height,
            diameter: diameter
        })
        if (!validationResult.valid) {
            handleFieldError(validationResult.errorField)
            return false
        }
        return true
    }

    function buildUpdatedItem(): Item {
        return {
            ...item,
            name: name,
            url: url,
            brand: brand,
            price: parseFloatLocale(price),
            currency: currency,
            packingUnit: packingUnit,
            imageId: imageId,
            supertypology: supertypology,
            typology: typology,
            subtypology: subtypology,
            dimensions: {
                length: parseFloatLocale(length),
                width: parseFloatLocale(width),
                height: parseFloatLocale(height),
                diameter: parseFloatLocale(diameter)
            },
            color: color,
            material: material,
            notes: notes,
            projects: toPickedProjectsDto(pickedProjects),
        };
    }

    function buildUpdatedInspirationItem(): Item {
        return {
            ...item,
            url: url,
            name: name,
            price: null,
            imageId: imageId,
            supertypology: supertypology,
            notes: notes,
            projects: toPickedProjectsDto(pickedProjects),
        };
    }

    function toPickedProjectsDto(projects: string[]) {
        return projects.map((projectId) => {
            return {"id": projectId, "name": ''}
        })
    }

    function checkValidAndUpdate() {
        if (!checkValid()) {
            return
        }
        let updatedItem = isInspiration ? buildUpdatedInspirationItem() : buildUpdatedItem();
        appContext.itemService().updateItem(updatedItem).then(() => {
            setItem(updatedItem)
            setSnackBarSeverity('success')
            setSnackBarMessage("Article mis à jour")
            setSnackBarDisplayed(true)
            console.log("Item mis à jour")
        });
    }

    const save = () => {
        const beforeProjects = item.projects.map((p) => p.id)
        const afterProjects = pickedProjects
        const removedProjects = beforeProjects.filter((id) => !afterProjects.includes(id))

        if (removedProjects.length > 0) {
            appContext.listingService().findAllByProjectsAndLibraryItem(item.id, removedProjects).then((listings) => {
                if ((listings.length) > 0) {
                    let invalidProjectIds = listings.map((p) => p.projectId)
                    let dedupProjectIds = invalidProjectIds.filter((value, index) => invalidProjectIds.indexOf(value) === index)
                    let invalidProjectNames = item.projects.filter((p) => dedupProjectIds.includes(p.id)).map((p) => p.name)
                    let formattedInvalidProjectNames = invalidProjectNames.join(', ')
                    let plural: boolean = dedupProjectIds.length > 1
                    confirm({
                        title: "Modification des projets",
                        description: `Cet article ne peut être supprimé ${plural ? "des" : "du"} projet${plural ? "s" : ""} suivant${plural ? "s" : ""} : ${formattedInvalidProjectNames}, car il est utilisé dans des listes. Retirez-le d'abord de ces listes afin de pouvoir le supprimer ${plural ? "des" : "du"} projet${plural ? "s" : ""}.`,
                        cancellationText: ""
                    })
                } else {
                    checkValidAndUpdate();
                }
            })
        } else {
            checkValidAndUpdate()
        }
    }

    function confirmAndDelete() {
        let desc = "Cette action est irréversible !"
        if (item.projects.length > 0) {
            desc = `Cet article est utilisé dans ${item.projects.length} projet${item.projects.length > 1 ? "s" : ""}. Êtes-vous sûr de vouloir le supprimer de la bibliothèque et de ${item.projects.length > 1 ? "ces projets" : "ce projet"}  ?`
        }
        confirm({
            title: "Supprimer l'article",
            description: desc,
            cancellationText: "Annuler"
        })
            .then(() => {
                return appContext.itemService().removeItem(item.id);
            })
            .then(() => {
                navigateTo({'name': 'Library'})
            })
            .catch(() => {
                console.log("error when confirming item deletion")
            });
    }

    const handleDelete = () => {
        appContext.listingService().findAllByLibraryItem(item.id).then((listings) => {
            if (listings.length == 0) {
                confirmAndDelete();
            } else {
                let projectNames = listings.map((l) => l.projectName)
                let dedupProjectNames = projectNames.filter((value, index) => projectNames.indexOf(value) === index)
                let formattedProjectNames = dedupProjectNames.join(", ")
                let plural: boolean = new Set(listings.map((p) => p.projectId)).size > 1
                confirm({
                    title: "Suppression impossible",
                    description: `Cet article a été ajouté à des listes dans le${plural ? "s" : ""} projet${plural ? "s" : ""} suivant${plural ? "s" : ""} : ${formattedProjectNames}. Retirez-le d'abord de ces listes afin de pouvoir le supprimer de votre bibliothèque.`,
                    cancellationText: ""
                })
            }
        })
    }

    const handleSnackBarClosed = () => {
        setSnackBarDisplayed(false)
    }

    const handleCropImageClicked = () => {
        setItemCropperDialogOpen(true)
    }

    const handleDownloadImage = async () => {
        let downloadImageUrl = getDownloadImageUrl(imageId)
        logInfo(`Downloading image with url ${downloadImageUrl}`)
        downloadjs(downloadImageUrl);
    }

    const handleItemCropperClosed = () => {
        setItemCropperDialogOpen(false)
    }

    const handleImageCropped = async (crop: CropDto) => {
        setCropping(true)
        let croppedImage = await appContext.sessionAwareHttpService().postBody<ImageDto>(config.base.url + `/api/dd/images/crop?imageId=${rawImageId}`, { crop: crop })
        setItemCropperDialogOpen(false)
        setImageId(croppedImage.id)
        setCropping(false)
    }

    useEffect(() => {
        fetchUserProjects()
        fetchItem()
    }, []);

    const selectProps = {
        style: {minWidth: '80px'}
    }

    const currentSupertypology = getTypology(typologies, supertypology)
    const currentTypology = getTypology(typologies, typology)
    const isMaterial = materialsTypologyCode == currentSupertypology.code
    const isInspiration = inspirationTypologyCode == currentSupertypology.code

    const priceLabel = isMaterial ? "Prix / unité" : "Prix"

    const imageUrl = imageId && getThumbnailImageUrl(imageId)

    const hasImageUrl: boolean = (imageUrl != null) && (imageUrl != '')

    return (

        <MainContainer menu={
            deleted ? <div/> :
            <ItemEditionMenu item={item} goBackText={props.goBackText}
                             onBackRequested={handleBack}
                             onSaveRequested={save}
                             onDeleteRequested={handleDelete}/>
        }
        >
            <Box style={{marginTop: "60px", display: "flex"}}>

                {
                    !deleted &&
                <div>
                    <TextField size="small" css={css`display:block;`} value={name} onChange={handleNameChange}
                               variant="standard" label="Nom" InputProps={{
                        style: {width: '800px', fontSize: '2rem'}
                    }}/>
                    { url && !urlEdition &&
                    <div>
                        <Button startIcon={<LaunchIcon/>} onClick={openUrl}>Voir sur le site d'origine</Button>
                        <Button startIcon={<EditIcon/>} onClick={editUrl}>Modifier l'URL</Button>
                    </div>
                    }
                    { !url && !urlEdition &&
                        <div style={{marginTop: "20px"}}>
                            <Button startIcon={<EditIcon/>} onClick={editUrl}>Ajouter une URL</Button>
                        </div>
                    }
                    { urlEdition &&
                        <div style={{marginTop: "20px", display: "flex", alignItems: "baseline"}}>
                            <TextField size="small"  value={url} onChange={handleUrlChange}
                                       variant="standard" label="URL" InputProps={{
                                style: {width: '600px'}}}/><Button onClick={closeUrlEdition}>OK</Button>
                        </div>
                    }
                    <div css={css`display: flex;
                                  align-items: start;
                                  margin-top: 30px;
                                  flex-wrap: wrap;
                                  `}>

                        {imageUrl &&
                        <Box style={{marginRight: "80px", marginBottom: "30px"}}>
                            {!isImageUploading &&
                                <DownloadableImg imgSrc={imageUrl} css={css`
                                  object-fit: contain;
                                  max-height: 300px;
                                  max-width: 300px;
                                  object-position: left;
                                `} onDownload={handleDownloadImage}
                                />
                            }
                            <UploadFile onFormDataReady={onFormDataReady} uploading={isImageUploading}
                                        replaceMode={hasImageUrl}/>

                            <Box>
                            <Button startIcon={<CropIcon/>} onClick={handleCropImageClicked}>Recadrer</Button>
                            </Box>

                            <Box>
                            {imageUrl && !isImageUploading &&
                                <Button startIcon={<Download/>} onClick={handleDownloadImage}>Enregistrer l'image sur cet appareil</Button>
                            }
                            </Box>

                        </Box>
                        }
                        {!imageUrl &&
                        <Box>
                            <Box style={{width: "90%"}}>
                                <UploadFile onFormDataReady={onFormDataReady} uploading={isImageUploading}
                                            replaceMode={hasImageUrl}/>
                            </Box>
                        </Box>
                        }
                        <div css={css`display: inline-block;margin-bottom: 25px`}>

                            <div css={css`display:block;margin-bottom: 25px`}>
                                <Typography variant="h6">
                                    Catégorie de produit
                                </Typography>
                                <FormControl sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="supertypology-label">Type</InputLabel>
                                    <Select
                                        label={"supertypology"}
                                        size="small"
                                        value={supertypology}
                                        labelId="supertypology-label"
                                        onChange={handleSupertypologyChange}
                                        variant="standard"
                                        css={css`margin-right: 20px;`}
                                    >
                                        {
                                            typologies.map((t) => {
                                                return <MenuItem value={t.code} key={t.code}>{t.label}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>

                                {(currentSupertypology.code != noTypology.code) && (currentSupertypology.subtypologies.length > 0) &&
                                <FormControl sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="typology-label">Typologie</InputLabel>
                                    <Select
                                        label={"typology"}
                                        size="small"
                                        value={typology}
                                        labelId="typology-label"
                                        onChange={handleTypologyChange}
                                        variant="standard"
                                        css={css`margin-right: 20px;`}
                                    >
                                        {
                                            currentSupertypology.subtypologies.map((t) => {
                                                return <MenuItem value={t.code} key={t.code}>{t.label}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                }

                                {(currentTypology.code != noTypology.code) && (currentTypology.subtypologies.length > 0) &&
                                <FormControl sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="subtypology-label">Sous-typologie</InputLabel>
                                    <Select
                                        size="small"
                                        value={subtypology}
                                        labelId="subtypology-label"
                                        variant="standard"
                                        onChange={handleSubtypologyChange}
                                    >
                                        {
                                            (currentTypology.subtypologies.map((st) => {
                                                return <MenuItem value={st.code} key={st.code}>{st.label}</MenuItem>
                                            }))
                                        }
                                    </Select>
                                </FormControl>
                                }

                                {!isInspiration &&
                                <Box>
                                    <FormControl sx={{m: 1, minWidth: 120}}>
                                        <TextField size="small" value={brand}
                                                   onChange={handleBrandChange} variant="standard" label={"Marque"}/>
                                    </FormControl>
                                </Box>
                                }
                            </div>

                            {!isInspiration &&
                            <div css={css`display:block;margin-bottom: 25px`}>
                                <Box>
                                    <Typography variant="h6">
                                        Prix {isMaterial ? " et conditionnemment" : ""}
                                    </Typography>
                                    <TextField size="small" css={css`margin-right: 20px;`} value={price} type="text" inputMode="numeric"
                                               onChange={handlePriceChange} variant="standard" label={priceLabel}/>
                                    <TextField size="small" value={currency} onChange={handleCurrencyChange}
                                               variant="standard" label="Devise" InputProps={{
                                        style: {width: '80px'}
                                    }}/>
                                </Box>
                                {isMaterial &&
                                <Box>
                                    <TextField size="small" css={css`margin-right: 20px;`} value={packingUnit}
                                               onChange={handlePackingUnitChange} variant="standard"
                                               label="Unité de conditionnement"
                                               InputProps={{
                                                   style: {width: '300px'}
                                               }}/>
                                </Box>
                                }
                            </div>
                            }

                            {!isInspiration &&
                            <div css={css`display:block;margin-bottom: 25px`}>

                                <Typography variant="h6">
                                    Caractéristiques
                                </Typography>

                                <Box sx={{display: "flex", flexDirection: "row", marginBottom: "5px"}}>
                                    <ItemColorPicker itemColor={color} availableColors={availableColors} onPickedColorsChange={handleColorChange}/>

                                    {!isMaterial &&
                                       <ItemMaterialPicker itemMaterial={material} availableMaterials={availableMaterials} onPickedMaterialsChange={handleMaterialChange}/>
                                    }
                                </Box>

                                <Box sx={{display: "flex", flexDirection: "row", marginBottom: "5px"}}>
                                    <Box sx={{display: "inline-flex", alignItems: "end", marginRight: "20px"}}>
                                        <TextField size="small" value={length} onChange={handleLengthChange}
                                                   variant="standard" label="Longueur" InputProps={{
                                            style: {width: '100px'}
                                        }}/>
                                        <span>&nbsp;cm</span>
                                    </Box>
                                    <Box sx={{display: "inline-flex", alignItems: "end", marginRight: "20px"}}>
                                        <TextField size="small" value={width} onChange={handleWidthChange}
                                                   variant="standard" label="Largeur" InputProps={{
                                            style: {width: '100px'}
                                        }}/>
                                        <span>&nbsp;cm</span>
                                    </Box>
                                    <Box sx={{display: "inline-flex", alignItems: "end", marginRight: "20px"}}>
                                        <TextField size="small" value={height} onChange={handleHeightChange}
                                                   variant="standard" label="Hauteur" InputProps={{
                                            style: {width: '100px'}
                                        }}/>
                                        <span>&nbsp;cm</span>
                                    </Box>
                                </Box>
                                <Box sx={{display: "flex", flexDirection: "row"}}>
                                    <Box sx={{display: "inline-flex", alignItems: "end", marginRight: "20px"}}>
                                        <TextField size="small" value={diameter} onChange={handleDiameterChange}
                                                   variant="standard" label="Diamètre" InputProps={{
                                            style: {width: '100px'}
                                        }}/>
                                        <span>&nbsp;cm</span>
                                    </Box>
                                </Box>
                            </div>
                            }

                            <div css={css`display:block;margin-bottom: 25px`}>

                                <Typography variant="h6">
                                    Divers
                                </Typography>
                                <Box css={css`margin-top: 10px`}>
                                    <TextField multiline size="small" value={notes} onChange={handleNotesChange}
                                               variant="outlined" label="Notes" minRows={3} InputProps={{
                                        style: {width: '400px'}
                                    }}/>
                                </Box>
                            </div>

                            <Box>
                                <Typography variant="h6">
                                    Projets associés
                                </Typography>
                                <ItemProjectPicker pickedProjects={pickedProjects} userProjects={userProjects}
                                                   onPickedProjectsChange={handlePickedProjectsChange}/>
                            </Box>

                        </div>

                    </div>

                    <EventSnackBar displayed={snackBarDisplayed} message={snackBarMessage}
                                   onClosed={handleSnackBarClosed} severity={snackBarSeverity}/>
                </div>
                }
                {
                    deleted &&
                    <div>
                        Cet article a été supprimé de la bibliothèque.
                    </div>
                }
            </Box>
            <ImageCropperDialog open={itemCropperDialogOpen} imageId={rawImageId} crop={crop} onClose={handleItemCropperClosed} onCropped={handleImageCropped} cropping={cropping}/>
        </MainContainer>
    )
};