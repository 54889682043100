import {Global} from '@emotion/react';
import {createTheme} from '@mui/material';
import {ThemeProvider} from '@mui/system';
import * as React from 'react';
import {Suspense, useEffect} from 'react';
import {RouterProvider} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import {appContext} from './ApplicationContext';
import {Root} from './view/Root';
import {globalStyles, muiThemeOptions} from './shared/styles/common-styles';
import "@fontsource/roboto";
import './shared/styles/ReactToastify-additional.css';
import {ConfirmProvider} from "material-ui-confirm";
import {Typology} from "./shared/model/model";
import {router} from "./generated/routing/router.generated";
import {useReferentialsState} from "./state/ReferentialsState";

global.log = (logged: any) => console.log(logged);

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

const muiTheme = createTheme(muiThemeOptions);

function App() {

    const setTypologies = useReferentialsState(s => s.setTypologies);
    useEffect(() => {
        appContext.referentialService().findAllTypologiesAndNone().then((typologies: Typology[]) => {
            setTypologies(typologies);
        })
    }, [])

    return (
        <>
            <Global styles={[globalStyles]}/>
            <ThemeProvider theme={muiTheme}>
                <Suspense fallback={<div/>}>
                    <Root>
                        <ConfirmProvider>
                            <RouterProvider router={router}/>
                        </ConfirmProvider>
                    </Root>
                </Suspense>
            </ThemeProvider>
        </>
    );
}

export default App;