import config from 'config';
import {appContext} from 'ApplicationContext';
import {Item} from "../model/model";
import {isNumber} from "../utils";

export interface ValidableItemProps {
    name: string,
    price: string,
    currency: string,
    width: string,
    length: string,
    height: string,
    diameter: string
}

export interface ItemValidationResult {
    valid: boolean
    errorField: string
}

export class ItemService {

    addItem(item: Item): Promise<Item> {
        const http = appContext.sessionAwareHttpService();
        let result = http.postBody<Item>(config.base.url + '/api/dd/items', item)
        result.then(() => console.log("Successfully added item"))
        return result
    }

    updateItem(item: Item): Promise<Item> {
        const http = appContext.sessionAwareHttpService();
        let result = http.postBody<Item>(config.base.url + '/api/dd/items/' + item.id, item)
        result.then(() => console.log("Successfully updated item"))
        return result
    }

    getItem(itemId: string): Promise<Item> {
        const http = appContext.sessionAwareHttpService();
        return http.getBody(config.base.url + '/api/dd/items/' + itemId)
    }

    removeItem(itemId: string) {
        const http = appContext.sessionAwareHttpService();
        return http.delete(config.base.url + '/api/dd/items/' + itemId).then(() => console.log("Successfully saved item"))
    }

    findByUrl(url: string, userId: string): Promise<Item | null> {
        const http = appContext.sessionAwareHttpService();
        return http.getBody<Array<Item>>(config.base.url + '/api/dd/items', {ownerId: userId, url: url})
            .then((response) => {
                return response.length > 0 ? response[0] : null
            })
    }

    findAll(): Promise<Item[]> {
        const http = appContext.sessionAwareHttpService();
        return http.getBody<Array<Item>>(config.base.url + '/api/dd/items?descOnly=true')
    }

    checkValid(props: ValidableItemProps): ItemValidationResult {
        if (props.price && !isNumber(props.price)) {
            return {valid: false, errorField: "Prix"}
        }
        if (props.length != '' && !isNumber(props.length)) {
            return {valid: false, errorField: "Longueur"}
        }
        if (props.width != '' && !isNumber(props.width)) {
            return {valid: false, errorField: "Largeur"}
        }
        if (props.height != '' && !isNumber(props.height)) {
            return {valid: false, errorField: "Hauteur"}
        }
        if (props.diameter != '' && !isNumber(props.diameter)) {
            return {valid: false, errorField: "Diamètre"}
        }
        return {valid: true, errorField: ''}
    }

}