import config from 'config';
import { appContext } from 'ApplicationContext';
import {UserInfos} from "../model/user";
import {UserPreferences} from "../model/model";

export class AccountService {

    public getUserInfos(): Promise<UserInfos> {
        const http = appContext.httpService();
        return http.getBody(config.base.url + '/api/account');
    }

    public updatePreferences(userPreferences: UserPreferences) {
        const http = appContext.httpService();
        return http.postBody(config.base.url + '/api/account/preferences', userPreferences);
    }

}