/** @jsxImportSource @emotion/react */
import {Outlet} from 'react-router-dom';
import {css} from "@emotion/react";
import {appContext} from "../../ApplicationContext";
import {useEffect, useState} from "react";
import {navigateTo} from "../../utils/routing-utils";
import {useUserState} from "../../state/UserState";

export const MainLayout = () => {
    const connected = useUserState(s => s.connected);
    const setConnected = useUserState(s => s.setConnected);

    const goToLoginIfNotLoggedIn = async () => {
        try {
            let userInfos = await appContext.userService().getUserInfos()
            if (!userInfos) {
                navigateTo({name: 'Login'})
            }
            setConnected(true, userInfos)
        } catch (e) {
            navigateTo({name: 'Login'})
        }
    };
    useEffect(() => {
        goToLoginIfNotLoggedIn();
    }, []);

    return <div
        css={css`
          width: 100vw;
          height: 100vh;
          display: flex;
          flex-direction: column;
        `}
    >
        <div
            css={css`
        flex: 1;
        overflow: scroll;
        padding: 10px;
      `}
        >
            { connected &&
                <Outlet />
            }
        </div>
    </div>

}