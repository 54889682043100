import config from 'config';
import {appContext} from 'ApplicationContext';

interface LoginResponse {
    userId: string
}

export class LoginService {

    public login(username: String, password:String): Promise<LoginResponse> {
        const http = appContext.httpService();
        return http.postBody<LoginResponse>(config.base.url + '/auth/login', {username: username, password: password})
    }

    public logout() {
        const http = appContext.httpService();
        return http.post(config.base.url + '/auth/logout')
            .then(() => console.log("Successfully logged out"))
    }

}