/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {ItemFilters, Listing, noListing, Project} from "shared/model/model";
import {ProjectItemsPicker} from "./ProjectItemsPicker";
import {Box, Button} from "@mui/material";
import IsoIcon from "@mui/icons-material/Iso";
import {FiltrableItemList} from "view/components/items/filtrable-item-list/FiltrableItemList";
import {ListingEditionMenu} from "./listings/ListingEditionMenu";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import {neutralContained, neutralOutlined} from "shared/styles/common-styles";
import { colors } from 'shared/styles/vars';

import {useUserState} from "state/UserState";

export const ProjectItemsView = (props: {
    isPickerDisplayed: boolean,
    project: Project,
    listing?: Listing | null,
    pickedItemsIds: string[],
    onClickPickItems(): void,
    onRenameListingRequest(listingId: string): void,
    onChangeSharingRequest(listingId: string): void,
    onDeleteListingRequest(listingId: string): void,
    onPickedItemsUpdated(itemsIds: string[]): void,
    onNewListingRequested(): void,
    onShowListingRequested(listingId: string): void
    onStatusClicked: (listingItemId: string) => void
    onItemClicked: (libraryItemId: string) => void
    onCancelReactionRequested: (itemId: string, listingId: string) => void
    onFiltersChanged: (filters: ItemFilters) => void
}) => {
    const userInfos = useUserState(s => s.userInfos);

    const isPickerDisplayed = props.isPickerDisplayed
    const project = props.project
    const pickedItemsIds = props.pickedItemsIds
    const listing = props.listing

    const itemsSelected = pickedItemsIds.length > 0

    const handleItemSelectionChange = (itemId: string) => {
        props.onPickedItemsUpdated(getNewSelectionAfterItemChange(itemId))
    };

    function getNewSelectionAfterItemChange(itemId: string) {
        if (pickedItemsIds.includes(itemId)) {
            return pickedItemsIds.filter((elem, i, arr) => elem !== itemId)
        } else {
            return pickedItemsIds.concat(itemId)
        }
    }

    return <Box>

        {!isPickerDisplayed &&
            <Box style={{marginBottom: "10px"}}>
                <Button startIcon={<LibraryAddIcon/>} variant="contained" onClick={props.onClickPickItems}
                        sx={{...neutralContained, marginRight: "10px"}}>
                    Ajouter des articles
                </Button>
                {((!listing || listing == noListing)) &&
                    <Button startIcon={<IsoIcon/>} variant="outlined" onClick={props.onNewListingRequested}
                            sx={{...neutralOutlined}}>
                        Créer une liste
                    </Button>
                }
                {(listing && (listing != noListing)) &&
                    <Box style={{display: "inline-block", zIndex: "2"}}>
                        <ListingEditionMenu sharingEnabled={listing?.shared}
                                            onDeleteListing={() => listing && props.onDeleteListingRequest(listing.id)}
                                            onRenameListing={() => listing && props.onRenameListingRequest(listing.id)}
                                            onChangeSharing={() => listing && props.onChangeSharingRequest(listing.id)}/>
                    </Box>
                }
            </Box>
        }

        {(isPickerDisplayed) &&
            <ProjectItemsPicker project={project} listing={listing} selectedItemsIds={pickedItemsIds}
                                onSelectionChange={handleItemSelectionChange}/>
        }
        {(!isPickerDisplayed) &&
            <Box sx={{marginTop: '30px'}}>
                {
                    (((listing == null) && (props.project.items.length > 0)) ||
                    ((listing != null) && (listing!!.items.length > 0))) &&
                <FiltrableItemList project={props.project} listing={props.listing} isAddPickerMode={false}
                                   userInfos={userInfos!!}
                                   selectedItemsIds={pickedItemsIds}
                                   isSelectionEnabled={true}
                                   addFromLibrary={false}
                                   onSelectionChange={handleItemSelectionChange}
                                   onRatingChange={() => null}
                                   onApprovalChange={() => null}
                                   onStatusClicked={props.onStatusClicked}
                                   onItemClicked={props.onItemClicked}
                                   onCancelReactionRequested={props.onCancelReactionRequested}
                                   onFiltersChanged={props.onFiltersChanged}
                                   itemAction={'open_edition'}/>
                }
                {
                    (listing == null) && (props.project.items.length == 0) &&
                    <Box style={{color: colors.darkgrey}}>
                        <p>
                            Vous n'avez pas encore ajouté d'articles à ce projet.
                        </p>
                        <p>
                            Vous pouvez ajouter des articles depuis votre bibliothèque en utilisant le bouton ci-dessus,
                            ou directement depuis une page web grâce à l'extension Biblo pour Chrome.
                        </p>
                    </Box>
                }
                {
                    (listing != null) && (listing!!.items.length == 0) &&
                    <Box style={{color: colors.darkgrey}}>
                        <p>
                            Vous n'avez pas encore ajouté d'articles à cette liste.
                        </p>
                        <p>
                            Cliquez sur le bouton ci-dessus pour ajouter des articles depuis ce projet ou depuis votre bibliothèque.
                        </p>
                    </Box>
                }
            </Box>
        }
    </Box>
};