/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import {css} from "@emotion/react";
import {noListing, noListingId, noProject, noTypology, Project} from "shared/model/model";
import {appContext} from "ApplicationContext";
import {colors} from "shared/styles/vars";

export interface Props {
    creationMode: boolean,
    name: string,
    open: boolean;
    onClose: () => void;
    onNameValidated: (name: string, originListingId: string | null, originProjectId: string | null) => void;
}

export const ListingNameDialog = (props: Props) => {

    const [name, setName] = useState('')
    const [copyList, setCopyList] = useState(false)
    const [projects, setProjects] = useState<Project[]>([])
    const [originProjectId, setOriginProjectId] = useState<string>(noProject.id)
    const [originListingId, setOriginListingId] = useState<string>(noListingId)

    const originProject = (originProjectId && (originProjectId != noProject.id)) ? projects.find((p) => p.id == originProjectId) : noProject

    const fetchItems = () => {
        appContext.projectService().findAll(true)
            .then((projects: Project[]) => {
                setProjects(projects);
                if (projects.length > 0) {
                    setOriginProjectId(projects[0].id)
                }
            });
    };

    useEffect(() => {
        if (originProject && (originProject.listings.length > 0)) {
            setOriginListingId(originProject.listings[0].id)
        }
    }, [originProject]);

    useEffect(() => {
        reset()
        if (props.open) {
            fetchItems();
        }
    }, [props.open]);

    useEffect(() => {
        setName(props.name)
    }, [props.name]);

    const toggleCopyList = () => {
        setCopyList(!copyList)
    }

    const reset = () => {
        setCopyList(false)
        setName("")
        setOriginListingId(noListingId)
        setOriginProjectId(noProject.id)
    }

    const handleCloseDialog = () => {
        props.onClose();
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
    }

    const handleProjectChange = (e: SelectChangeEvent) => {
        let selectedProjectId = e.target.value
        setOriginProjectId(selectedProjectId ? selectedProjectId : noProject.id)
    }

    const handleOriginListingChange = (e: SelectChangeEvent) => {
        let selectedListingId = e.target.value
        setOriginListingId(selectedListingId ? selectedListingId : noListingId)
    }

    const submit = () => {
        if (copyList) {
            props.onNameValidated(name, originListingId, originProjectId)
        } else {
            props.onNameValidated(name, null, null)
        }
    }

    const handleNameKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            submit()
        }
    }

    return (
        <Dialog onClose={handleCloseDialog} open={props.open}>
            <DialogTitle fontSize={"1.5em"}>{props.creationMode ? "Créer une liste" : "Renommer la liste"}</DialogTitle>
            <Box style={{marginLeft: "25px", marginBottom: "25px", marginRight: "25px"}}>

                <FormControl sx={{m: 1, minWidth: 120}}>
                    <TextField onChange={handleNameChange} onKeyDown={handleNameKeyDown} variant="standard"
                               InputProps={{}} autoFocus={true} value={name} placeholder={"Nom de la liste"}/>

                    { props.creationMode &&
                        <Box css={css`
                          margin-top: 20px;
                        `}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={copyList} onChange={toggleCopyList}/>
                                }
                                label={"Copier à partir d'une liste existante"}
                            />
                            { copyList &&
                                <Box css={css`
                                      margin-top: 10px;
                                    `}>
                                    <Typography css={css`
                                      margin-bottom:20px;
                                      color:${colors.darkgrey}
                                    `}>Sélectionnez la liste à copier :</Typography>
                                    <FormControl>
                                        <InputLabel id={"project-label"}>Projet</InputLabel>
                                        <Select
                                            label={"project"}
                                            value={originProjectId}
                                            labelId="project-label"
                                            onChange={handleProjectChange}
                                            variant="standard"
                                            css={css`margin-right: 20px;`}
                                        >
                                            {
                                                projects.map((p) => {
                                                    return <MenuItem value={p.id} key={p.id}>{p.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel id={"origin-listing-label"}>Liste</InputLabel>
                                        <Select
                                            label={"origin-listing"}
                                            value={originListingId}
                                            labelId="origin-listing-label"
                                            onChange={handleOriginListingChange}
                                            variant="standard"
                                            css={css`margin-right: 20px;`}
                                        >
                                            {
                                                originProject?.listings.map((l) => {
                                                    return <MenuItem value={l.id} key={l.id}>{l.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            }
                        </Box>
                    }
                </FormControl>

                <Box style={{marginTop: "20px"}}>
                    <FormControl>
                        <Button onClick={submit}
                                disabled={"" == name}>{props.creationMode ? "Créer" : "Renommer"}</Button>
                    </FormControl>
                </Box>
            </Box>
        </Dialog>
    );
}