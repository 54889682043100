/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import {css} from "@emotion/react";
import {
    approvedStatus,
    archivedStatus,
    draftStatus,
    Item,
    ListingItemStatus,
    proposedStatus,
    rejectedStatus,
    toReviewStatus
} from "shared/model/model";
import {colors} from "shared/styles/vars";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {allStatuses, getStatus} from "shared/utils";

import {useReferentialsState} from "state/ReferentialsState";

export interface Props {
    listingItems: Item[]
    open: boolean;
    onClose: () => void;
    onStatusValidated: (newStatus: string) => void;
}

export const ListingItemStatusDialog = (props: Props) => {
    const [newStatus, setNewStatus] = useState("")
    const statuses = useReferentialsState(s => s.listingItemStatuses)
    const [currentStatus, setCurrentStatus] = useState(draftStatus)
    const [currentStatusLabel, setCurrentStatusLabel] = useState("")
    //const [saving, setSaving] = useState(false)

    useEffect(() => {
        reset()
    }, [props.open, props.listingItems]);

    const reset = () => {
        if (props.listingItems.length > 0) {
            let curr = draftStatus
            if (props.listingItems.length == 1) {
                curr = getStatus(props.listingItems[0].status)
                setCurrentStatusLabel(curr.label)
            } else if (props.listingItems.map(it => it.status).every(s => s == props.listingItems[0].status)) {
                curr = getStatus(props.listingItems[0].status)
                setCurrentStatusLabel(curr.label)
            } else {
                curr = getHigherStatus(props.listingItems.map(it => getStatus(it.status)))
                setCurrentStatusLabel("(statuts multiples)")
            }
            setCurrentStatus(curr)
            if (curr == toReviewStatus) {
                setNewStatus(approvedStatus.code)
            } else {
                let currentStatusIndex = allStatuses.indexOf(curr)
                if (currentStatusIndex < allStatuses.length - 1) {
                    setNewStatus(allStatuses[currentStatusIndex + 1].code)
                } else {
                    setNewStatus(allStatuses[allStatuses.length - 1].code)
                }
            }
        } else {
            setCurrentStatusLabel(draftStatus.label)
        }
        //setSaving(false)
    }

    const getHigherStatus = (statuses: ListingItemStatus[]) => {
        return statuses.reduce((max, curr) =>
            (max.level > curr.level) ? max : curr
        )
    }

    const handleCloseDialog = () => {
        props.onClose();
    };

    const handleNewStatusChange = (e: SelectChangeEvent) => {
        setNewStatus(e.target.value)
    }

    const submit = () => {
        //setSaving(true)
        props.onStatusValidated(newStatus)
    }

    const handleNameKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            submit()
        }
    }

    let itemsLabel = ""
    if (props.listingItems.length > 0) {
        itemsLabel = (props.listingItems.length > 1) ? `ces ${props.listingItems.length} articles` : `"${props.listingItems[0].name}"`
    }

    return (
        <Dialog onClose={handleCloseDialog} open={props.open}>
            <DialogTitle fontSize={"1.5em"}>Modifier le statut</DialogTitle>
            <Box style={{marginLeft: "25px", marginBottom: "25px", marginRight: "25px"}}>

                <FormControl sx={{m: 1, minWidth: 120}}>

                    <Box>
                        <Typography css={css`
                          margin-bottom: 20px;
                          color: ${colors.darkgrey}
                        `}>Sélectionnez le nouveau statut pour <span
                            css={css`color: ${colors.darkgrey}`}>{itemsLabel}</span> </Typography>
                        <Box css={css`
                          display: flex;
                          flex-direction: row;
                          justify-content: space-around;

                          span {
                            top: 0.3em;
                          }
                        `}>
                                <span>
                                    <Typography>{currentStatusLabel}</Typography>
                                </span>
                            <span>
                                <ArrowForwardIcon/>
                                </span>
                            <FormControl>
                                <Select
                                    label={"new-status"}
                                    value={newStatus}
                                    labelId="new-status-label"
                                    onChange={handleNewStatusChange}
                                    variant="standard"
                                    css={css`margin-right: 20px;
                                      font-weight: bold;`}
                                >
                                    {
                                        statuses.map((s) => {
                                            return <MenuItem value={s.code} key={s.code}>{s.label}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </FormControl>
                <Box css={css`
                  background: white;
                  padding: 10px;
                  font-size: 0.8rem;
                  color: ${colors.verydarkgrey};
                  border: 1px solid ${colors.clearGrey};
                  margin: 10px 20px 10px 20px;
                `}>
                    {newStatus == draftStatus.code &&
                        <span>L'article sera visible uniquement par vous.</span>
                    }
                    {newStatus == proposedStatus.code &&
                        <span>L'article sera visible via le lien de partage du projet</span>
                    }
                    {newStatus == toReviewStatus.code &&
                        <span>L'article pourra être approuvé ou rejeté via le lien de partage du projet.</span>
                    }
                    {newStatus == approvedStatus.code &&
                        <span>L'article sera considéré comme approuvé pour ce projet.</span>
                    }
                    {newStatus == rejectedStatus.code &&
                        <span>L'article sera considéré comme rejeté pour ce projet.</span>
                    }
                    {newStatus == archivedStatus.code &&
                        <span>L'article restera associé à la liste mais sera masqué dans le lien de partage du projet.</span>
                    }
                </Box>

                <Box style={{marginTop: "20px", display: "flex", justifyContent: "space-around"}}>
                    <FormControl>
                        <Button onClick={submit}>Mettre à jour</Button>
                    </FormControl>
                </Box>
            </Box>
        </Dialog>
    );
}