import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {ItemFilters, noTypology, Typology} from "shared/model/model";
import {getTypology} from "shared/utils";
import {colors} from "shared/styles/vars";

import {useReferentialsState} from "state/ReferentialsState";

export interface Props {
    filters: ItemFilters
    onClose: () => void
    onReset: () => void
    onFiltersUpdated: (filters: ItemFilters) => void
}

export const ItemFiltersDialog = (props: Props) => {
    const typologies = useReferentialsState(s => s.typologies)

    const [supertypology, setSupertypology] = useState('');
    const [typology, setTypology] = useState('');
    const [subtypology, setSubtypology] = useState('');

    useEffect(() => {
        setSupertypology((props.filters.supertypology ?? noTypology).code)
        setTypology((props.filters.typology ?? noTypology).code)
        setSubtypology((props.filters.subtypology ?? noTypology).code)
    }, [props.filters])

    const handleTypologyChange = (e: SelectChangeEvent) => {
        setTypology(e.target.value)
        setSubtypology(noTypology.code)
    }

    const handleSupertypologyChange = (e: SelectChangeEvent) => {
        setSupertypology(e.target.value)
    }

    const handleSubtypologyChange = (e: SelectChangeEvent) => {
        setSubtypology(e.target.value)
    }

    const applyFilters = () => {
        props.onFiltersUpdated({
            supertypology: (currentSupertypology != noTypology) ? currentSupertypology : null,
            typology: (currentTypology != noTypology) ? currentTypology : null,
            subtypology: (currentSubtypology != noTypology) ? currentSubtypology : null,
            width: null
        })
        props.onClose()
    }

    const reset = () => {
        props.onFiltersUpdated({
            supertypology: null,
            typology: null,
            subtypology: null,
            width: null
        })
    }

    var currentSupertypology = getTypology(typologies, supertypology)
    var currentTypology = getTypology(typologies, typology)
    var currentSubtypology = getTypology(typologies, subtypology)

    return (
        <Box>

            <div>
                <span style={{fontSize: "1.1em"}}>Filtres</span> <span
                style={{fontSize: "0.8em", cursor: "pointer", color: colors.primaryMain}}><span
                onClick={reset}>réinitialiser</span></span>
            </div>

            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id="typology-label"></InputLabel>
                <Select
                    label={"supertypology"}
                    size="small"
                    value={currentSupertypology.code}
                    labelId="supertypology-label"
                    onChange={handleSupertypologyChange}
                    variant="standard"
                    style={{marginRight: "20px"}}
                >
                    <MenuItem value={noTypology.code} key={noTypology.code}>Tous les articles</MenuItem>
                    {
                        typologies.filter((t: Typology) => t.code != noTypology.code).map((t: Typology) => {
                            return <MenuItem value={t.code} key={t.code}>{t.label}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>

            {(currentSupertypology.code != noTypology.code) && (currentSupertypology.subtypologies.length > 0) &&
                <FormControl sx={{m: 1, minWidth: 120}}>
                    <InputLabel id="typology-label">Typologie</InputLabel>
                    <Select
                        label={"typology"}
                        size="small"
                        value={currentTypology.code}
                        labelId="typology-label"
                        onChange={handleTypologyChange}
                        variant="standard"
                        style={{marginRight: "20px"}}
                    >
                        {
                            currentSupertypology.subtypologies.map((t) => {
                                return <MenuItem value={t.code} key={t.code}>{t.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            }

            {(currentTypology.code != noTypology.code) && (currentTypology.subtypologies.length > 0) &&
                <FormControl sx={{m: 1, minWidth: 120}}>
                    <InputLabel id="subtypology-label">Sous-typologie</InputLabel>
                    <Select
                        size="small"
                        value={currentSubtypology.code}
                        labelId="subtypology-label"
                        variant="standard"
                        onChange={handleSubtypologyChange}
                    >
                        {
                            (currentTypology.subtypologies.map((st) => {
                                return <MenuItem value={st.code} key={st.code}>{st.label}</MenuItem>
                            }))
                        }
                    </Select>
                </FormControl>
            }

            <Box style={{marginTop: "5px"}}>
                <FormControl>
                    <Button onClick={applyFilters} variant={"outlined"}>Appliquer</Button>
                </FormControl>
            </Box>
        </Box>
    );
}