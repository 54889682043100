/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {ProjectComponent} from "../ProjectComponent";
import {useTypedParams} from "utils/routing-utils";
import {ProjectBoardsPath} from "generated/routing/ApplicationPath.generated";

export const ProjectBoardsView = () => {
    const routeParams = useTypedParams<ProjectBoardsPath>();

    return (
        <ProjectComponent
            workParams={{
                projectId: routeParams.projectId,
                listingId: null,
                itemId: null,
                boardId: null,
                menuItemType: "boards"
            }}/>
    )
};
