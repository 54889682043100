/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react';
import {ItemMaterial, Material} from "../model/model";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {Box, Typography} from "@mui/material";
import ListItem from "@mui/material/ListItem";

interface Props {
    itemMaterial: ItemMaterial,
    availableMaterials: Material[],
    onPickedMaterialsChange: (pickedMaterials: string[]) => void;
}

export const ItemMaterialPicker = (props: Props) => {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [pickedMaterials, setPickedMaterials] = React.useState<string[]>([]);

    const availableMaterials = props.availableMaterials

    const handleChange = (event: SelectChangeEvent<typeof pickedMaterials>) => {
        const {
            target: {value},
        } = event;
        let newPickedValue = typeof value === 'string' ? value.split(',') : value;
        setPickedMaterials(
            newPickedValue,
        );
        props.onPickedMaterialsChange(newPickedValue)
    };

    useEffect(() => {
        setPickedMaterials(props.itemMaterial.materials)
    }, [props.itemMaterial])

    return (
        <Box sx={{marginTop: "20px"}}>
            <FormControl sx={{m: 1, width: 300}} size="small">
                <InputLabel id="color-picker-label">Matériaux</InputLabel>
                <Select
                    labelId="material-picker-label"
                    id="material-checkbox"
                    multiple
                    value={pickedMaterials}
                    variant="standard"
                    size="small"
                    onChange={handleChange}
                    input={<OutlinedInput label="Matériaux"/>}
                    renderValue={(selected) => selected.map((code) => (availableMaterials.find((c) => c.code == code) || {"code": ""}).label).join(', ')}
                    MenuProps={MenuProps}
                >
                    {availableMaterials.map((material) => (
                        <MenuItem key={material.code} value={material.code}>
                            <Checkbox checked={pickedMaterials.indexOf(material.code) > -1}/>
                            <ListItem>
                                <Typography>{material.label} </Typography>
                            </ListItem>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );

}