
import {
    defaultItemFilters,
    ItemFilters,
    subtypologyQueryParamName,
    supertypologyQueryParamName, Typology,
    typologyQueryParamName
} from "../shared/model/model";
import {getTypology} from "../shared/utils";

export const  filtersToSearchParamsIfDefined = (filters: ItemFilters): string | undefined => {
    return filters != defaultItemFilters ? filtersToSearchParams(filters).toString() : undefined
}

export const filtersToSearchParams = (filters: ItemFilters): URLSearchParams => {
    let params = new URLSearchParams();
    params.set(supertypologyQueryParamName, filters.supertypology?.code ?? "")
    params.set(typologyQueryParamName, filters.typology?.code ?? "")
    params.set(subtypologyQueryParamName, filters.subtypology?.code ?? "")
    return params
};

export const searchParamsToFilters = (params: URLSearchParams, typologies: Typology[]): ItemFilters => {
    let supertypologyCode = params.get(supertypologyQueryParamName)
    let typologyCode = params.get(typologyQueryParamName)
    let subtypologyCode = params.get(subtypologyQueryParamName)
    let itemFilters = {
        supertypology: supertypologyCode ? getTypology(typologies, supertypologyCode) : null,
        typology: typologyCode ?  getTypology(typologies, typologyCode): null,
        subtypology: subtypologyCode ?  getTypology(typologies, subtypologyCode) : null,
        width: null
    }
    return itemFilters
}