/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, CircularProgress, Modal, Typography} from "@mui/material";
import {useConfirm} from "material-ui-confirm";
import {
    Board,
    BoardItem,
    BoardOptionKey,
    BoardOptions,
    Item,
    Listing,
    noBoard,
    noListing,
    noListingId,
    Project
} from "shared/model/model";
import {appContext} from "ApplicationContext";
import {MainContainer} from "view/layout/MainContainer";
import {BoardEditionMenu} from "./BoardEditionMenu";
import {XYCoord} from "react-dnd";
import {BoardEditor} from "./BoardEditor";
import {ItemType} from "./ItemTypes";
import {EventSnackBar} from "view/components/EventSnackBar";
import html2canvas from "html2canvas";
import downloadjs from 'downloadjs';
import {colors} from "shared/styles/vars";
import {useProjectState} from "state/ProjectState";
import {boardWidth} from "config/settings";
import {round} from 'lodash';
import {navigateTo, useTypedParams} from "utils/routing-utils";
import {EditBoardPath} from "generated/routing/ApplicationPath.generated";
import { logDebug } from 'shared/utils';

export const EditBoardView = () => {
    const routeParams = useTypedParams<EditBoardPath>();

    const [board, setBoard] = useState(noBoard);
    const [boardName, setBoardName] = useState('');

    const [pickSource, setPickSource] = useState<string>('project')
    const [sourceListingId, setSourceListingId] = useState<string>(noListingId)
    const [sourceListing, setSourceListing] = useState<Listing>(noListing)
    const project = useProjectState(s => s.project);
    const setProject = useProjectState(s => s.setProject);

    const [allLibraryItems, setAllLibraryItems] = useState([] as Item[]);
    const [pickableItems, setPickableItems] = useState<Item[]>([])

    const [initialBoardItems, setInitialBoardItems] = useState<BoardItem[]>([])
    const [boardItems, setBoardItems] = useState<BoardItem[]>([])
    const [selectedBoardItemId, setSelectedBoardItemId] = useState<string | null>(null)
    const [selectedItemType, setSelectedItemType] = useState<ItemType | null>(null)
    //const [isContentEdition, setContentEdition] = useState(false);
    const [boardOffset, setBoardOffset] = useState<XYCoord | null>(null)

    const [snackBarMessage, setSnackbarMessage] = useState<string>('')
    const [snackBarDisplayed, setSnackBarDisplayed] = useState(false);

    const [projectLoaded, setProjectLoaded] = useState(false)
    const [loading, setLoading] = useState(false)
    const [downloadRequested, setDownloadRequested] = useState(false)

    const [captionMode, setCaptionMode] = useState<boolean>(false)
    const [includeBrandCaption, setIncludeBrandCaption] = useState(false)
    const [includePriceCaption, setIncludePriceCaption] = useState(false)
    const [includeDimensionsCaption, setIncludeDimensionsCaption] = useState(false)

    const confirm = useConfirm();

    /*const toggleContentEdition = () => {
        setContentEdition(!isContentEdition)
        if (!isContentEdition) {
            setBoardItems(initialBoardItems)
        }
    }*/

    useEffect(() => {

        if (!downloadRequested) {
            return
        }
        /*html2canvas(document.getElementById('test-rendering')!!).then((canvas) => {
           var data = canvas.toDataURL('image/png');
           //display 64bit imag
           var image = new Image();
           image.src = data;
           document.body.appendChild(image);
       })*/
        html2canvas(document.getElementById(`dd-board-canvas`)!!, {useCORS: true, scale: 4}).then((canvas) => {
            const dataURL = canvas.toDataURL('image/jpeg');
            downloadjs(dataURL, board.name + ".jpeg", 'image/jpeg');
            setDownloadRequested(false)
            setLoading(false)
        })
    }, [downloadRequested])

    useEffect(() => {
        if (!project) {
            return
        }
        if (sourceListingId != noListingId) {
            let foundListing = project.listings.find((l) => l.id == sourceListingId)
            if (foundListing) {
                setSourceListing(foundListing)
            } else {
                setSourceListing(noListing)
            }
        } else {
            setSourceListing(noListing)
        }
    }, [project, sourceListingId]);

    const handlePickSourceChanged = (pickSource: string) => {
        setPickSource(pickSource)
    }

    const handleSourceListingChanged = (pickedListingId: string) => {
        let newSourceListingId = (pickedListingId != noListingId) ? pickedListingId : null
        logDebug("newSourceListingId : " + newSourceListingId)
        appContext.projectService().updateBoardSourceListing(project.id, board.id, newSourceListingId).then(() => {
            setSourceListingId(pickedListingId)
            if (pickedListingId == noListingId) {
                setPickSource('project')
            }
        })
    }

    const handleSaveClicked = () => {
        let options: BoardOptions = fillOptions()
        appContext.projectService().updateBoard(routeParams.projectId, routeParams.boardId, boardName, {items: boardItems}, options).then(() => {
            updatedBoardAndProjectState({...board, content: {items: boardItems}, name: boardName})
            setSnackbarMessage("Modifications enregistrées")
            setSnackBarDisplayed(true)
        });
    }

    const fillOptions = (): BoardOptions => {
        return {
            options: {
                "displayCaptionRefs": captionMode,
                "displayBrandCaption": includeBrandCaption,
                "displayPriceCaption": includePriceCaption,
                "displayDimsCaption": includeDimensionsCaption,
            }
        }
    }

    const handleBoardItemsUpdated = (boardItems: BoardItem[]) => {
        setBoardItems(renumberItems(boardItems))
    };

    const handleItemSelectionChange = (boardItemId: string | null, selectedItemType: ItemType | null) => {
        logDebug(`selection change to ${boardItemId}`)
        setSelectedBoardItemId(boardItemId)
        setSelectedItemType(selectedItemType)
    };

    const handleDeleteBoard = () => {
        let projectId = routeParams.projectId
        let boardId = routeParams.boardId
        confirm({
            title: "Supprimer la planche",
            description: "Cette action est irréversible !",
            cancellationText: "Annuler"
        })
            .then(() => {
                return appContext.projectService().deleteBoard(projectId, boardId).then(() => {
                    let updatedProject = {...project, boards: project.boards.filter((b) => b.id != boardId)}
                    setProject(updatedProject)
                    navigateTo({
                        name: 'ProjectBoards',
                        projectId: routeParams.projectId
                    })
                })
            })
            .catch(() => {
                logDebug("error when confirming board deletion")
            });
    };

    const handleOptionUpdated = (key: BoardOptionKey, value: boolean) => {
        if (key == "displayPriceCaption") {
            setIncludePriceCaption(value)
        } else if (key == "displayBrandCaption") {
            setIncludeBrandCaption(value)
        }
        if (key == "displayDimsCaption") {
            setIncludeDimensionsCaption(value)
        }
        if (key == "displayCaptionRefs") {
            setCaptionMode(value)
        }
    }

    const handleItemEditionRequested = (itemId: string) => {
        if (allSaved()) {
            editItem(itemId)
            return
        }
        confirm({
            title: "Modifications non enregistrées",
            description: "Êtes-vous sûr de vouloir quitter l'éditeur de planche sans sauvegarder les modifications réalisées ?",
            cancellationText: "Non",
            allowClose: true
        })
            .then(() => {
                return editItem(itemId)
            })
            .catch(() => {
                logDebug("error when confirming back action")
            });
    }

    const editItem = (itemId: string) => {
        navigateTo({name: "LibraryItem", itemId: itemId})
    }

    /*const renumberItems = (boardItems: BoardItem[]) => {
        let renumbered: BoardItem[] = []
        var nbRows = 10
        const totalHeight = 580
        let itemRows: BoardItem[][] = []
        for (let i = 1 ; i <= nbRows ; i++) {
            itemRows.push([])
        }
        //si max y = 400
        // elem qui vaut 150 va où ?
        // 0 ---> 1
        // 580 ---> 10

        //let maxY: number = boardItems.map((bi) => bi.image.y).reduce((prev, curr, ind, arr) => (prev > curr) ? prev : curr)

        boardItems.forEach((bi) => {
            let targetRow = ceil(bi.image.y / (totalHeight / nbRows))
            logDebug("push item " + bi.instanceId + " to row " + targetRow)
            itemRows[targetRow].push(bi)
        })
        var currentNum = 0
        itemRows.forEach((itemRow: BoardItem[]) => {
            let orderedRow = itemRow.sort((a, b) => (a.image.x < b.image.x) ? 0 : 1)
            orderedRow.forEach((bi) => {
                renumbered.push({...bi, refNum: ++currentNum})
            })
        })
        return renumbered
    }*/

    function renumberItems(boardItems: BoardItem[]): BoardItem[] {
        // Calculate total columns based on the board width and average image width
        const totalColumns = round(boardWidth / (boardItems.reduce((sum, item) => sum + item.image.w, 0) / boardItems.length));

        // Sort boardItems array by Y-coordinate, then X-coordinate
        boardItems.sort((a, b) => {
            if (a.image.y !== b.image.y) {
                return a.image.y - b.image.y;
            }
            return a.image.x - b.image.x;
        });

        // Assign numbers to images
        let row = 0;
        let column = 0;
        for (const item of boardItems) {
            const number = row * totalColumns + column + 1;
            // Update the refNum property of the board item
            item.refNum = number;

            // Update row and column for the next iteration
            column++;
            if (column >= totalColumns) {
                column = 0;
                row++;
            }
        }

        // Return the updated boardItems array
        return boardItems;
    }

    function updatedBoardAndProjectState(updatedBoard: Board) {
        setBoard(updatedBoard)
        setInitialBoardItems(updatedBoard.content.items)
        setBoardItems(updatedBoard.content.items)
        let updatedBoards = project.boards.filter((b) => b.id != updatedBoard.id)
        updatedBoards = [...updatedBoards, updatedBoard]
        let updatedProject = {...project, boards: updatedBoards}
        setProject(updatedProject)
    }

    const handleNameEditionDone = (name: string) => {
        logDebug("handleNameEditionDone")
        let updatedBoard = {...board, name: name}
        setBoardName(name)
    }

    function goToProject() {
        navigateTo({
            name: 'ProjectBoard',
            projectId: routeParams.projectId,
            boardId: routeParams.boardId
        })
    }

    function allSaved() {
        return (initialBoardItems == boardItems) && (boardName == board.name);
    }

    const handleBack = () => {
        if (allSaved()) {
            return goToProject()
        }
        confirm({
            title: "Modifications non enregistrées",
            description: "Êtes-vous sûr de vouloir quitter l'éditeur de planche sans sauvegarder les modifications réalisées ?",
            cancellationText: "Annuler"
        })
            .then(() => {
                return goToProject();
            })
            .catch(() => {
                logDebug("error when confirming back action")
            });
    }

    function updateBoardItemsTextFromItems(boardItems: BoardItem[], items: Item[]) {
        return boardItems.map((bi: BoardItem) => {
            return {...bi, text: items.find((i) => i.id == bi.itemId)?.name ?? bi.text}
        })
    }

    useEffect(() => {
        appContext.itemService().findAll()
            .then((allItems: Item[]) => {
                setAllLibraryItems(allItems);
                getOrFetchProject(routeParams.projectId).then((p: Project) => {
                    appContext.projectService().getBoard(routeParams.projectId, routeParams.boardId).then((board: Board) => {
                        setBoard(board);
                        setSourceListingId(board.sourceListingId ?? noListingId)
                        if (board.sourceListingId) {
                            logDebug("source listing id : " + board.sourceListingId)
                            setPickSource('listing')
                        }
                        setBoardName(board.name)
                        let items = updateBoardItemsTextFromItems(board.content.items, allItems)
                        setInitialBoardItems(items)
                        setBoardItems(items)
                        initOptions(board.options)
                        setProjectLoaded(true)
                    })
                });
            });
    }, [routeParams, boardOffset]);

    const initOptions = (options: BoardOptions) => {
        setCaptionMode(readOptionOrFalse(options, "displayCaptionRefs"))
        setIncludeBrandCaption(readOptionOrFalse(options, "displayBrandCaption"))
        setIncludePriceCaption(readOptionOrFalse(options, "displayPriceCaption"))
        setIncludeDimensionsCaption(readOptionOrFalse(options, "displayDimsCaption"))
    }

    const readOptionOrFalse = (options: BoardOptions, name: string): boolean => {
        let readValue = options.options[name]
        if (readValue) {
            return readValue
        } else {
            return false
        }
    }

    const getOrFetchProject = (projectId: string, forceRefresh: boolean = false) => {
        let projectPromise: Promise<Project>
        if ((project.id != projectId) || forceRefresh) {
            projectPromise = appContext.projectService().getProject(projectId)
            projectPromise.then((p: Project) => setProject(p))
        } else {
            projectPromise = Promise.resolve(project)
        }
        return projectPromise
    }

    const handleBoardOffsetProvided = (offset: XYCoord | null) => {
        setBoardOffset(offset)
    }

    const handleDownloadBoardImage = async () => {
        //let imageDataArray: Blob[] = await appContext.boardService().fetchImages(boardItems.map((bi) => bi.image.url))
        setLoading(true)
        setTimeout(() => {
            setDownloadRequested(true)
        }, 500)
    }

    const handleCaptionCopied = () => {
        setSnackbarMessage("Légende copiée dans le presse-papier")
        setSnackBarDisplayed(true)
    }

    const handleSnackBarClosed = () => {
        setSnackBarDisplayed(false)
    }

    useEffect(() => {
        logDebug("pick source changed to " + pickSource)
        if (pickSource == 'project') {
            setPickableItems(project.items)
        } else if (pickSource == 'library') {
            setPickableItems(allLibraryItems)
        } else if (pickSource == 'listing') {
            setPickableItems(sourceListing.items)
        }
    }, [pickSource, sourceListing]);

    const handleLoading = (loading: boolean) => {
        setLoading(loading)
    }

    const loadingStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        border: '2px solid #000',
        backgroundColor: 'white',
        padding: '20px',
        color: colors.verydarkgrey,
        textAlign: 'center',
        boxShadow: 24
    };


    const menu = <BoardEditionMenu projectId={project.id} board={board} onNameEditionDone={handleNameEditionDone}
                                   onSaveRequested={handleSaveClicked} onBackRequested={handleBack}
                                   onDeleteBoard={handleDeleteBoard} onDownloadRequested={handleDownloadBoardImage}/>
    /*: <BoardMenu isNameEdition={isNameEdition} projectId={routeParams.projectId}
                 onCancelClicked={handleCancelClicked} onSaveClicked={handleSaveNameClicked}
                 onDeleteBoard={handleDeleteBoard} onDownloadRequested={handleDownloadBoardImage}/>*/
    return (
        <MainContainer menu={menu} overflowX={"hidden"} overflowY={"hidden"} margin={"0px"}>
            <div id={"entire-page"}
                 css={css`
                 `}>
                <BoardEditor boardOffset={boardOffset} onBoardOffsetProvided={handleBoardOffsetProvided}
                             project={project} items={pickableItems} boardItems={boardItems}
                             allLibraryItems={allLibraryItems}
                             onBoardItemsUpdated={handleBoardItemsUpdated}
                             onBoardItemSelectionChange={handleItemSelectionChange}
                             selectedBoardItemId={selectedBoardItemId} selectedItemType={selectedItemType}
                             onCaptionCopied={handleCaptionCopied} onLoading={handleLoading}
                             onItemEditionRequested={handleItemEditionRequested} projectLoaded={projectLoaded}
                             onPickSourceChanged={handlePickSourceChanged} pickSource={pickSource}
                             sourceListing={sourceListing}
                             onSourceListingChanged={handleSourceListingChanged}
                             captionMode={captionMode}
                             includeBrandCaption={includeBrandCaption}
                             includePriceCaption={includePriceCaption}
                             includeDimensionsCaption={includeDimensionsCaption}
                             onOptionUpdated={handleOptionUpdated}
                />
                <EventSnackBar displayed={snackBarDisplayed} message={snackBarMessage} severity={"info"}
                               onClosed={handleSnackBarClosed}/>

            </div>

            {loading &&
                <Modal
                    open={loading}

                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={loadingStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBottom: "10px"}}>
                            Préparation de l'image...
                        </Typography>
                        <CircularProgress/>
                    </Box>
                </Modal>
            }
        </MainContainer>
    )
};
