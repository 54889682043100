/** @jsxImportSource @emotion/react */
import type {CSSProperties} from 'react'
import React, {useEffect, useState} from "react";
import {XYCoord} from 'react-dnd'
import {BoardItem} from "shared/model/model";
import {getStandardImageUrl, logDebug} from "shared/utils";


const boardBorderSize = 1

const style: CSSProperties = {
    position: 'absolute'
}

export interface Props {
    boardItem: BoardItem
    boardOffset: XYCoord | null
    boardWidth: number
    boardHeight: number
    refBoardWidth: number
    refBoardHeight: number
}

export const DisplayableItem = (props: Props) => {

    const [panelWidth, setPanelWidth] = useState<number>(0)
    const [panelHeight, setPanelHeight] = useState<number>(0)
    const [panelTop, setPanelTop] = useState<number>(0)
    const [panelLeft, setPanelLeft] = useState<number>(0)
    const [degrees, setDegrees] = useState<number>(0)

    useEffect(() => {
        logDebug("movable item use effect")
        setPanelLeft(props.boardItem.image.x)
        setPanelTop(props.boardItem.image.y)
        setPanelWidth(props.boardItem.image.w)
        setPanelHeight(props.boardItem.image.h)
        setDegrees(props.boardItem.image.r)
    }, [props.boardItem])


    const zIndex = props.boardItem.image.z
    const blobUrl = props.boardItem.image.blobData ? URL.createObjectURL(props.boardItem.image.blobData) : null
    const imageUrl = getStandardImageUrl(props.boardItem.image.id)

    const toProjectedX = (x: number) => {
        return x * props.boardWidth / props.refBoardWidth
    }

    const toProjectedY = (y: number) => {
        return y * props.boardHeight / props.refBoardHeight
    }

    const projectedX = toProjectedX(panelLeft)
    const projectedY = toProjectedY(panelTop)
    const projectedWidth = toProjectedX(panelWidth)
    const projectedHeight = toProjectedY(panelHeight)

    const itemWidthString = `${projectedWidth}px`

    logDebug(`projected pos : ${projectedX}, ${projectedY}`)
    logDebug(`projected size : ${projectedWidth}, ${projectedHeight}`)

    return (
        <div id={"displayable-item-" + props.boardItem.instanceId} style={{
            ...style, left: `${projectedX}px`, top: `${projectedY}px`, zIndex: zIndex
        }}>
            <img key={props.boardItem.instanceId} width={itemWidthString}
                 style={{
                     color: "blue",
                     width: `${projectedWidth + boardBorderSize}px`,
                     height: `${projectedHeight + boardBorderSize}px`,
                     transform: `rotate(${degrees}deg)`
                 }}
                 src={blobUrl ?? imageUrl} draggable={"false"}/>
        </div>
    )
}
