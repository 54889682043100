import type {CSSProperties} from 'react'
import React, {useEffect, useRef, useState} from "react";
import {BoardItem} from "shared/model/model";
import {XYCoord} from "react-dnd";
import {MovableItem} from "./editor/MovableItem";
import {DisplayableItem} from "./editor/DisplayableItem";
import {colors} from "shared/styles/vars";
import {boardHeight, boardWidth} from "config/settings";

interface Props {
    elementId: string
    boardWidth: number
    boardHeight: number
    boardItems: BoardItem[],
    boardOffset: XYCoord | null
}

export const BoardReadOnlyCanvas = (props: Props) => {

    const wrappingRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        let pOffset = getOffset(wrappingRef.current!!)
        console.log("offset computed:" + pOffset.x + ", " + pOffset.y)
    }, [])

    const style: CSSProperties = {
        height: props.boardHeight+"px",
        width: props.boardWidth+"px",
        color: 'white',
        textAlign: 'center',
        fontSize: '1rem',
        lineHeight: 'normal',
        float: 'left',
        left: '0',
        top: '0',
        backgroundSize: '10px 10px',
        overflow: 'hidden'
    }

    function getOffset(el: Element) {
        let position = el.getBoundingClientRect();
        return {x: position.left + window.scrollX, y: position.top + window.scrollY};
    }

    let backgroundColor = 'white'

    return (
        <div>
            <div ref={wrappingRef} style={{width: props.boardWidth + 10 +"px", height:props.boardHeight + 10  +"px",border: `2px solid ${colors.clearGrey}`,
                borderRadius: "4px"}}>
                <div id={props.elementId} style={{...style, backgroundColor}}>
                    {props.boardItems.map((boardItem) => {
                        return <DisplayableItem boardOffset={props.boardOffset} boardItem={boardItem}
                            refBoardWidth={boardWidth} refBoardHeight={boardHeight} boardWidth={props.boardWidth} boardHeight={props.boardHeight}/>
                    })}
                </div>
            </div>
        </div>
    )
}
