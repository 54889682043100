import config from 'config';
import {appContext} from 'ApplicationContext';
import {noTypology, Typology} from "../model/model";

export class ReferentialService {

    findAllTypologies(): Promise<Typology[]> {
        const http = appContext.sessionAwareHttpService();
        return http.getBody<Typology[]>(config.base.url + '/api/dd/referential/typologies')
    }

    findAllTypologiesAndNone(): Promise<Typology[]> {
        return this.findAllTypologies().then((typologies) => {
            let all = typologies.map((t) => this.withNoTypology(t))
            all.unshift(noTypology)
            return all
        })
    }

    withNoTypology(typology: Typology): Typology {
        if (typology.subtypologies.length == 0) {
            return typology
        }
        typology.subtypologies = typology.subtypologies.map((t) => this.withNoTypology(t))
        typology.subtypologies.unshift(noTypology)
        return typology
    }

}