/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Box, Checkbox, FormControl, FormControlLabel} from "@mui/material";
import {css} from "@emotion/react";
import {
    approvedStatus, archivedStatus,
    draftStatus,
    Listing,
    proposedStatus,
    rejectedStatus,
    toReviewStatus
} from "shared/model/model";
import {colors} from "shared/styles/vars";

export interface Props {
    open: boolean
    listing: Listing
    onClose: () => void
    onSharingOptionsUpdated: (shared: boolean) => void
}

export const ListingSharingDialog = (props: Props) => {

    const [shared, setShared] = useState(false)

    useEffect(() => {
        reset()
    }, [props.listing]);


    const toggleShared = () => {
        setShared(!shared)
    }

    const reset = () => {
        if (props.listing) {
            setShared(props.listing.shared)
        } else {
            setShared(false)
        }
    }

    const handleCloseDialog = () => {
        props.onClose();
    };


    const submit = () => {
        props.onSharingOptionsUpdated(shared)
    }

    return (
        <Dialog onClose={handleCloseDialog} open={props.open}>
            <DialogTitle fontSize={"1.5em"}>Options de partage</DialogTitle>
            <Box style={{marginLeft: "25px", marginBottom: "25px", marginRight: "25px", width: "300px"}}>

                <Box>
                    <FormControl sx={{m: 1, minWidth: 120}}>
                        <Box css={css`
                        `}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={shared} onChange={toggleShared}/>
                                }
                                label={"Partage de la liste activé"}
                            />
                        </Box>
                    </FormControl>
                </Box>
                    <Box css={css`
                      background: white;
                      padding: 10px;
                      font-size: 0.8rem;
                      color: ${colors.verydarkgrey};
                      border: 1px solid ${colors.clearGrey};
                      margin: 5px 20px 10px 20px;
                      display: ${shared ? "block" : "none"}
                    `}>
                       Notez que les articles avec le statut <b>Brouillon</b> ou <b>Archivé</b> ne seront pas visibles via le lien de partage.
                    </Box>

                <Box style={{marginTop: "20px"}}>
                    <FormControl>
                        <Button onClick={submit}>Valider</Button>
                    </FormControl>
                </Box>
            </Box>
        </Dialog>
    );
}