/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Box, Button, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {colors} from "shared/styles/vars";
import {Board} from "shared/model/model";
import {BoardMoreMenu} from "./BoardMoreMenu";
import {logDebug} from "shared/utils";

interface Props {
    board: Board
    projectId: string
    onSaveRequested: () => void
    onBackRequested: () => void
    onNameEditionDone: (name: string) => void
    onDeleteBoard: () => void
    onDownloadRequested: () => void
}

export const BoardEditionMenu = (props: Props) => {

    const [boardName, setBoardName] = useState('');
    const [isNameEdition, setNameEdition] = useState(false);

    const nameInputRef = useRef(null)

    useEffect(() => {
        logDebug("use Effect board menu")
        setBoardName(props.board.name)
    }, [props.board]);

    const escFunction = useCallback((event: any) => {
        if (event.key === "Escape") {
            cancelNameEdition()
        }
        //logDebug(`key: ${event.key }, selectedBoardItemId : ${selectedBoardItemId}`)
        /*if ((event.key === "Backspace") && !isNameEdition) {
            navigateTo({
                name: 'Project',
                projectId: props.routeParams.projectId,
                editName: 'false'
            })
        }*/
    }, [isNameEdition]);
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [isNameEdition]);

    const cancelNameEdition = () => {
        setNameEdition(false)
        setBoardName(props.board.name)
    };

    const handleBoardNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        logDebug("board name change")
        setBoardName(e.target.value)
    }

    function saveNewName(newName: string) {
        props.onNameEditionDone(newName)
        setNameEdition(false)
    }

    const handleBoardNameBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        saveNewName(e.target.value);
    }

    const handleNameKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            logDebug("enter tapé, on enregistre " + boardName)
            saveNewName(boardName);
        }
    }

    const toggleNameEdition = () => {
        setNameEdition(!isNameEdition)
    }
    return (
        <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 50px;
              padding: 8px 20px;
              border-bottom: 1px solid ${colors.clearGrey};
              width: 100%;
            `}
        >

            <div css={css`
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            `}>
                <div>
                    <Tooltip title="Revenir au projet" onClick={props.onBackRequested}>
                        <IconButton>
                            <ArrowBackIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
                <div>
                    {!isNameEdition &&
                        <Typography css={css`display: inline-block`} style={{marginBottom: "0px"}}
                                    onClick={toggleNameEdition} variant="h5" component="div"
                                    gutterBottom>{boardName}</Typography>
                    }
                    {isNameEdition &&
                        <TextField css={css`display: flex`} value={boardName} onChange={handleBoardNameChange}
                                   onBlur={handleBoardNameBlur}
                                   ref={nameInputRef} onKeyDown={handleNameKeyDown} variant="standard" InputProps={{
                            style: {fontSize: '1.5rem', marginBottom: "0px"}
                        }}/>
                    }
                </div>
                <Box sx={{display: "flex"}}>
                    <BoardMoreMenu onDeleteBoard={props.onDeleteBoard} onDownloadRequested={props.onDownloadRequested}/>

                    <Button startIcon={<SaveIcon/>} onClick={props.onSaveRequested}
                            variant="outlined">Enregistrer</Button>
                </Box>

            </div>

        </div>
    )
        ;
};
