/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {colors, fonts} from './vars';
import {ThemeOptions} from "@mui/material/styles";

export const globalStyles = css`
  *,
  *:before,
  *:after {
    position: relative;
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    // font-size: {fonts.baseSize}px;
    font-family: ${fonts.font};
  }

  button {
    outline: none !important;
  }

  h1 {
    font-size: 1.6rem;
    font-weight: normal;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: normal;
  }
  h3 {
    font-size: 1.4rem;
    font-weight: normal;
  }
  h4 {
    font-size: 1.3rem;
    font-weight: normal;
    text-align: center;
  }
  
  .form-field-error {
    font-size: 0.8em;
    color: ${colors.errorRed};
  }
`;

export const primaryLinkStyle = {
    cursor: "pointer", color: colors.primaryMain
}

export const neutralLinkStyle = {
    cursor: "pointer", color: colors.darkgrey
}

export const muiThemeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: colors.primaryMain,
            light: colors.primaryLight,
        },
        secondary: {
            main: colors.secondaryMain,
            contrastText: 'rgba(249,249,249,0.87)',
        },
        error: {
            main: colors.errorRed,
        },
        warning: {
            main: colors.warningMain,
        },
        info: {
            main: colors.infoMain,
        },
        success: {
            main: colors.successMain,
            light: colors.successMain
        },
    },
    typography: {
        fontFamily: ' "Roboto", "Helvetica", "Arial", sans-serif',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 30,
                    textTransform: 'none',
                    fontWeight: "700",
                    boxShadow: 'none',
                    ":hover": {
                        boxShadow: 'none'
                    }
                },
                contained: {
                    // ...
                },
                outlined: {
                   // ...
                }
            }
        }
    }
};

export const neutralContained = {
        backgroundColor: colors.verydarkgrey,
        color: colors.white,
        ":hover": {
            backgroundColor: colors.ratherdarkgrey
        }
    }

export const neutralOutlined = {
        border: "1px solid " + colors.verydarkgrey,
        color: colors.verydarkgrey,
        ":hover": {
            backgroundColor: colors.clearGrey,
            border: "1px solid " + colors.black,
        }
    }