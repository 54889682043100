/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {Divider, MenuItem} from "@mui/material";
import {IconButtonMenu} from "view/components/IconButtonMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Settings} from "@mui/icons-material";

interface Props {
    onDeleteProject: () => void
    onRenameProject: () => void
    onSharingUrlRequested: () => void
    onManageCollaboratorsRequested:() => void
    onArchiveProject:() => void
}

export const ProjectMoreMenu = (props: Props) => {

    const [menuOpen, setMenuOpen] = React.useState(false);

    const handleManageCollaboratorsRequested = () => {
        setMenuOpen(false)
        props.onManageCollaboratorsRequested()
    }

    const handleRenameProject = () => {
        setMenuOpen(false)
        props.onRenameProject()
    }

    const handleMenuStateChange = (open: boolean) => {
        setMenuOpen(open)
    }

    return (
        <IconButtonMenu icon={<Settings/>} open={menuOpen} onMenuStateChange={handleMenuStateChange}>
            <MenuItem onClick={handleRenameProject}>Renommer le projet</MenuItem>
            <MenuItem onClick={handleManageCollaboratorsRequested}>Partager le projet</MenuItem>
            <MenuItem onClick={props.onArchiveProject}>Archiver le projet</MenuItem>
            <Divider></Divider>
            <MenuItem onClick={props.onDeleteProject}>Supprimer le projet</MenuItem>
        </IconButtonMenu>
    );
};
