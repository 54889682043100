/** @jsxImportSource @emotion/react */
import {css} from "@emotion/react";
import React from 'react';
import {ProjectCard} from './ProjectCard'
import {Item, Project} from "shared/model/model";
import {Box, Card, CardActionArea, CardContent, Typography} from "@mui/material";
import {appContext} from "ApplicationContext";
import {v4 as uuidv4} from 'uuid';
import {ProjectNameDialog} from "../project-editor/dialogs/ProjectNameDialog";
import {navigateTo} from "utils/routing-utils";

import {RouteLink} from "../../components/RouteLink";

interface Props {
    projects: Project[]
}

export const ProjectList = (props: Props) => {

    const [projectNameDialogOpen, setProjectNameDialogOpen] = React.useState(false);

    const handleCloseProjectNameDialog = () => {
        setProjectNameDialogOpen(false);
    };

    const handleNewProjectClick = () => {
        setProjectNameDialogOpen(true)
    }

    const handleProjectNameValidated = (newName: string) => {
        setProjectNameDialogOpen(false)
        let id = uuidv4()
        appContext.projectService().createProject({
            id: id,
            ownerId: '',
            name: newName,
            items: [],
            boards: [],
            listings: [],
            collaborators: [],
            archived: false
        }).then(() => {
            navigateTo({name: 'Project', projectId: id});
        })
    }

    return (
        <div
            css={css`
            display: flex;
            font-size: 1.2em;
            flex-wrap: wrap;
         `}
        >
            {
                props.projects.map((project) => (
                    <Box css={css`flex: 1;`}>
                    <RouteLink
                        route={{
                            name: 'Project',
                            projectId: project.id
                        }}
                        key={project.id}
                    >
                        <ProjectCard project={project} key={project.id}/>
                    </RouteLink>
                    </Box>
                ))
            }
            <Box css={css`flex: 1;`}>
                <Card variant="outlined" sx={{maxWidth: 280}} css={css`margin: 7.5px;border-style:dashed;color:lightgray`}
                      onClick={handleNewProjectClick}>
                    <CardActionArea css={css`
                min-width: 200px;
                min-height: 200px;
            `}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" css={css`
           text-align: center
          `}>
                                + Nouveau projet
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
            {
                (props.projects.length % 2 == 0) &&
                <Box css={css`flex: 1;`}>
                </Box>

            }
            <ProjectNameDialog projectName={""}
                               open={projectNameDialogOpen}
                               onClose={handleCloseProjectNameDialog}
                               onNameValidated={handleProjectNameValidated}
                               creationMode={true}
            />
        </div>
    )
};