/** @jsxImportSource @emotion/react */
import React from 'react'
import {ProjectComponent} from "../ProjectComponent";
import {useTypedParams} from "utils/routing-utils";
import {ListingItemPath} from "generated/routing/ApplicationPath.generated";

export const ListingItemView = () => {
    const routeParams = useTypedParams<ListingItemPath>();

    return (
        <ProjectComponent workParams={{
            projectId: routeParams.projectId,
            listingId: routeParams.listingId,
            itemId: routeParams.itemId,
            boardId: null,
            menuItemType: "listing_detail"
        }}/>
    )
};
