/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {ProjectComponent} from "../ProjectComponent";
import {useTypedParams} from "utils/routing-utils";
import {ProjectBoardPath} from "generated/routing/ApplicationPath.generated";

export const ProjectBoardView = () => {
    const routeParams = useTypedParams<ProjectBoardPath>();

    return (
        <ProjectComponent workParams={{
            projectId: routeParams.projectId,
            listingId: null,
            itemId: null,
            boardId: routeParams.boardId,
            menuItemType: "board_detail"
        }}/>
    )
};
