/** @jsxImportSource @emotion/react */
import React from 'react'
import {ItemEditor} from "./ItemEditor";
import {useTypedParams} from "../../../utils/routing-utils";
import {LibraryItemPath} from "../../../generated/routing/ApplicationPath.generated";

export const LibraryItemView = () => {
    const routeParams = useTypedParams<LibraryItemPath>();

    return (
        <ItemEditor goBackText="Revenir à la librairie" itemId={routeParams.itemId}/>
    )
};