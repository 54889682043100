/** @jsxImportSource @emotion/react */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import {css} from "@emotion/react";
import {colors} from "shared/styles/vars";
import {Tooltip} from "@mui/material";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import {appContext} from "ApplicationContext";
import {AccountCircleOutlined} from "@mui/icons-material";
import {RootLink} from "view/components/RootLink";
import {useState} from "react";
import {publicRegistrationOpen} from "shared/utils";
import {noUser} from "shared/model/model";
import {navigateTo} from "utils/routing-utils";
import {useUserState} from "state/UserState";

export default function MainAppBar() {
    const connected = useUserState(s => s.connected);
    const setConnected = useUserState(s => s.setConnected);
    const [accountCreationEnabled, setAccountCreationEnabled] = useState(publicRegistrationOpen)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleGoToLibrary = () => {
        navigateTo({'name': 'Library'})
    };

    const handleLogin = () => {
        navigateTo({'name': 'Login'})
    };

    const handleRegister = () => {
        navigateTo({'name': 'Register'})
    };

    const handleLogout = () => {
        handleMenuClose();
        appContext.loginService().logout().then(() => {
            setConnected(false, noUser)
            navigateTo({name: 'Login'})
        });
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const mainMenuId = 'primary-search-account-menu';
    const mainDesktopMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mainMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {!connected &&
                <MenuItem onClick={handleLogin}>Se connecter</MenuItem>
            }
            {!connected && accountCreationEnabled &&
                <MenuItem onClick={handleRegister}>Créer un compte</MenuItem>
            }
            {connected &&
            <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
            }
        </Menu>
    );

    const mobileMainMenuId = 'primary-search-account-menu-mobile';
    const menuTextStyle = {marginBottom: "16px", marginTop: "16px"}
    const mobileMainMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMainMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            { connected &&
                <MenuItem onClick={handleGoToLibrary}>
                    <IconButton size="large" color="inherit" aria-label="ouvrir la bibliothèque">
                        <CollectionsBookmarkIcon/>
                    </IconButton>
                    <p style={menuTextStyle}>Bibliothèque</p>
                </MenuItem>
            }
            <MenuItem onClick={handleLogout}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircleOutlined/>
                </IconButton>
                <p style={menuTextStyle}>Se déconnecter</p>
            </MenuItem>
        </Menu>
    );

    const appBarCss = css`
        .MuiPaper-root {
        background-color: white;
        box-shadow: none;
        border-bottom: 1px solid ${colors.clearGrey};
        color: ${colors.verydarkgrey}
       }
    `

    return (
        <Box sx={{flexGrow: 1}} css={appBarCss}>
            <AppBar position="fixed">
                <Toolbar>
                    <RootLink short={false}/>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{display: {xs: 'none', sm: 'block'}}}
                    >
                    </Typography>
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                        {connected &&
                            <Tooltip title="Bibliothèque">
                                <IconButton size="large" color="inherit" aria-label="ouvrir la bibliothèque"
                                            onClick={handleGoToLibrary}>
                                    <CollectionsBookmarkIcon/>
                                </IconButton>
                            </Tooltip>
                        }
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={mainMenuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircleOutlined/>
                        </IconButton>
                    </Box>
                    <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMainMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {mobileMainMenu}
            {mainDesktopMenu}
        </Box>
    );
}
