/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import {Item, Listing, noItem, Project} from "shared/model/model";
import {Button, TextField, Typography} from "@mui/material";
import {css} from "@emotion/react";
import {colors} from "shared/styles/vars";
import {borderBottomWidth} from "html2canvas/dist/types/css/property-descriptors/border-width";
import {sanitize} from "shared/utils";
import {ItemRating} from "view/components/items/ItemRating";
import {ItemReactionsDetailsPanel} from "view/components/items/ItemReactionsDetailsPanel";
import {UserInfos} from "shared/model/user";

export interface Props {
    libraryItemId: string,
    guestMode: boolean,
    listing: Listing,
    project: Project,
    userInfos: UserInfos,
    onDelete: (commentId: string) => void,
    onRatingChange: (itemId: string, listingId: string | null, rating: string | null) => void
    onApprovalChange: (itemId: string, listingId: string | null, approved: boolean) => void
    onCommentSubmitted: (itemId: string, listingId: string, commentText: string) => void
    onCancelReactionRequested: (itemId: string, listingId: string) => void
}

export const ListingItemReactionPanel = (props: Props) => {
    const [comment, setComment] = useState('');
    const [commentTooLong, setCommentTooLong] = useState(false);

    const [reactionsDetailsOpen, setReactionDetailsOpen] = useState(false)

    const commentMaxSize = 1000

    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value)
        setCommentTooLong(e.target.value.length > commentMaxSize)
    }

    const saveComment = () => {
        props.onCommentSubmitted(props.libraryItemId, props.listing.id, comment)
        setComment('')
    }

   let item = props.listing.items.find((i) => i.id == props.libraryItemId)!!
    let comments = item.comments ?? []

    const nbReactions = Object.entries(item.reactions).filter(([key, value]) => value > 0).map(([key, value]) => value).reduce((sum, current) => sum + current, 0)

    const handleReactionTypePicked = (reactionType: string) => {
        props.onRatingChange(props.libraryItemId, props.listing.id, reactionType)
    }

    const borderStyle = props.guestMode ? `border: 1px solid ${colors.clearGrey}` : `border-left: 1px solid ${colors.clearGrey}`
    const bgStyle  = props.guestMode ? `background: ${colors.clearGrey2}` : "background: default"

    return (
        <div css={css`  border-radius: 10px;
                        ${borderStyle};
                        padding: 10px;
                        ${bgStyle};
            `   }>
            <Typography variant={"h6"} css={css``}>Réactions / commentaires</Typography>
            <div  css={css`margin-top: 20px`}>


                { (nbReactions > 0) &&
                    <div  css={css`margin-bottom: 20px`}>
                        <ItemRating reactions={item.reactions} readOnly={!props.guestMode}  onClick={() => setReactionDetailsOpen(true)}
                                onReactionTypePicked={handleReactionTypePicked} userReaction={item.userReaction}/>
                    </div>
                }

                {  (nbReactions == 0) &&
                    <div css={css`
                  color: ${colors.darkgrey};
                `}>Aucune réaction
                    </div>
                }

                <div css={css`margin-bottom: 15px`}>
                    {comments.map(comment => (
                        <div  css={css`padding: 10px; border-top: 1px solid ${colors.clearGrey};`}>
                            {
                                <span css={css`font-weight:bold`}>{comment.authorDisplayName != "" ? comment.authorDisplayName : "Utilisateur invité"} : </span>
                            }
                            {

                                sanitize(comment.commentText).map((line, index, lines) => (
                                    <span key={index}>
                                {line}
                                {index !== lines.length - 1 && <br />}
                                    </span>
                                    ))

                            }
                            { ((props.guestMode && !comment.authorId) || (!props.guestMode && comment.authorId)) &&
                            <div
                                css={css`font-size:0.8em;font-style:italic;color:${colors.darkgrey};cursor:pointer;`} onClick={() => props.onDelete(comment.commentId)}>Supprimer</div>
                            }
                        </div>
                    ))
                    }
                </div>

                <div css={css`width: 100%`} >
                    <TextField size="small" css={css`margin-right: 20px;margin-top:15px;background: white`} value={comment}
                               onChange={handleCommentChange}
                               label="Nouveau commentaire" multiline placeholder="Tapez un commentaire..."
                               fullWidth={true} minRows={2}/>
                    {
                        commentTooLong &&
                        <div css={css`color: ${colors.errorRed};font-size: 0.8em`}>Longueur maximale ({commentMaxSize} caractères) dépassée</div>
                    }
                    <Button css={css`margin-top:10px`}  onClick={saveComment} disabled={comment == '' || commentTooLong}>Envoyer</Button>
                </div>
            </div>
            { item &&
                <ItemReactionsDetailsPanel onCancelReactionRequested={props.onCancelReactionRequested} userInfos={props.userInfos} open={reactionsDetailsOpen} listingItem={item} listing={props.listing} onClose={() => setReactionDetailsOpen(false)}/>
            }

        </div>

    )
};