import {appContext} from 'ApplicationContext';
import {navigateTo} from "utils/routing-utils";
import {RequestType} from "../shared/services/HttpService";


export class SessionAwareHttpService {

    public getBody = <R>(url: string, getParams?: any): Promise<R> => {
        let result: Promise<R> = appContext.httpService().getBody(url, getParams)
        this.handleErrors(result)
        return result
    }

    public get = (url: string, getParams?: any) => {
        let result = appContext.httpService().get(url, getParams)
        this.handleErrors(result)
        return result
    }

    public postBody = <R>(url: string, bodyObject?: any): Promise<R> => {
        let result: Promise<R> = appContext.httpService().postBody(url, bodyObject)
        this.handleErrors(result)
        return result
    }

    public putBody = <R>(url: string, bodyObject?: any): Promise<R> => {
        let result: Promise<R> = appContext.httpService().putBody(url, bodyObject)
        this.handleErrors(result)
        return result
    }

    public post = (url: string, bodyObject?: any) => {
        let result = appContext.httpService().post(url, bodyObject)
        this.handleErrors(result)
        return result
    }

    public postMultipart = <R>(url: string, formData?: any): Promise<R> => {
        let result: Promise<R> = appContext.httpService().postMultipart(url, formData)
        this.handleErrors(result)
        return result
    }


    public put = (url: string, bodyObject?: any) => {
        let result = appContext.httpService().put(url, bodyObject)
        this.handleErrors(result)
        return result
    }

    public delete = (url: string, bodyObject?: any) => {
        let result = appContext.httpService().delete(url, bodyObject)
        this.handleErrors(result)
        return result
    }

    public fetch(
        requestType: RequestType,
        url: string,
        getParams?: any,
        bodyAsString?: string,
        bodyFormData?: FormData
    ) {
        let result = appContext.httpService().fetch(requestType, url, getParams, bodyAsString, bodyFormData)
        this.handleErrors(result)
        return result
    }

    private handleErrors = <R>(result: Promise<R>) => {
        result.catch((error: any) => {
            if ((error == 403) || (error == 401)) {
                navigateTo({"name": "Login"})
            }
        })
    }

}
