import { ApplicationBootstrapData } from './shared/model/bootstrap-data';
import {noProject} from "./shared/model/model";

if (global.bootstrapData === undefined) {
    global.bootstrapData = {project: noProject};
}

export const applicationBootstrapData =
  global.bootstrapData as ApplicationBootstrapData;

export const SharedConstants = {};
