/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {appContext} from 'ApplicationContext';
import { noUser } from 'shared/model/model';
import {navigateTo} from "utils/routing-utils";
import {useUserState} from "state/UserState";

export const LogoutView = () => {
    const setConnected = useUserState(s => s.setConnected);

    const logout = () => {
        setConnected(false, noUser)
        navigateTo({'name': 'Login'})
    };

    logout()

    return <div/>
};
