import {Board, BoardContent, Item, Listing, Project, Comment, Reaction} from "../model/model";
import {appContext} from "ApplicationContext";
import config from "config";

export class ListingService {

    findAllByProject(projectId: string): Promise<Listing[]> {
        const http = appContext.sessionAwareHttpService();
        return http.getBody<Listing[]>(`${config.base.url}/api/dd/listings?projectId=${projectId}`)
    }

    findAllByLibraryItem(libraryItemId: string): Promise<Listing[]> {
        const http = appContext.sessionAwareHttpService();
        return http.getBody<Listing[]>(`${config.base.url}/api/dd/listings?libraryItemId=${libraryItemId}`)
    }

    findAllByProjectsAndLibraryItem(libraryItemId: string, projectIds: String[]): Promise<Listing[]> {
        const http = appContext.sessionAwareHttpService();
        return http.getBody<Listing[]>(`${config.base.url}/api/dd/listings?libraryItemId=${libraryItemId}&projectIds=${projectIds}`)
    }

    createListing(listingId: string, projectId: string, name: string, originListingId: string | null, originProjectId: string | null): Promise<Listing> {
        const http = appContext.sessionAwareHttpService();
        return http.postBody<Listing>(`${config.base.url}/api/dd/listings`, {id: listingId, projectId: projectId, name: name, originListingId: originListingId, originProjectId: originProjectId})
    }

    updateItems(listingId: string, itemIds: string[]) {
        const http = appContext.sessionAwareHttpService();
        return http.put(config.base.url + '/api/dd/listings/' + listingId + "/items", { itemsIds: itemIds })
            .then(() => console.log("Successfully updated items in listing"))
    }

    addItems(listingId: string, itemIds: string[]) {
        const http = appContext.sessionAwareHttpService();
        return http.post(config.base.url + '/api/dd/listings/' + listingId + "/items", { itemsIds: itemIds })
            .then(() => console.log("Successfully added items to listing"))
    }

    removeItems(listingId: string, itemIds: string[]) {
        const http = appContext.sessionAwareHttpService();
        return http.delete(config.base.url + '/api/dd/listings/' + listingId + "/items", { itemsIds: itemIds })
            .then(() => console.log("Successfully removed items from listing"))
    }

    renameListing(listingId: string, name: string) {
        const http = appContext.sessionAwareHttpService();
        let result = http.putBody<Listing>(config.base.url + '/api/dd/listings/' + listingId + "/name", { name: name })
        result.then(() => console.log("Successfully updated listing name"))
        return result
    }

    updateSharing(listingId: string, shared: boolean) {
        const http = appContext.sessionAwareHttpService();
        let result = http.putBody<Listing>(config.base.url + '/api/dd/listings/' + listingId + "/sharing", { shared: shared })
        result.then(() => console.log("Successfully updated listing sharing"))
        return result
    }

    deleteListing(listingId: string) {
        const http = appContext.sessionAwareHttpService();
        return http.delete(config.base.url + '/api/dd/listings/' + listingId)
            .then(() => console.log("Successfully deleted listing name"))
    }

    addComment(commentId: string, listingId: string, itemId: string, commentText: string, token: string | null = null): Promise<Comment> {
        const http = appContext.sessionAwareHttpService();
        let resultPromise = http.postBody<Comment>(config.base.url + `/api/dd/listings/${listingId}/items/${itemId}/comments`,
            {commentId: commentId, commentText: commentText, token: token})
        resultPromise.then(() => console.log("Successfully added comment"))
        return resultPromise
    }

    deleteComment(commentId: string, listingId: string, itemId: string) {
        const http = appContext.sessionAwareHttpService();
        return http.delete(config.base.url + `/api/dd/listings/${listingId}/items/${itemId}/comments/${commentId}`)
            .then(() => console.log("Successfully deleted comment"))
    }

    updateStatus(listingId: string, listingItemsIds: string[], status: string): Promise<Listing> {
        const http = appContext.sessionAwareHttpService();
        let resultPromise = http.putBody<Listing>(config.base.url + `/api/dd/listings/${listingId}/status`,
            {listingItemsIds: listingItemsIds, status: status})
        resultPromise.then(() => console.log("Successfully updated status"))
        return resultPromise
    }

    getItemReactions(listingId: string, itemId: string): Promise<Reaction[]> {
        const http = appContext.sessionAwareHttpService();
        let resultPromise = http.getBody<Reaction[]>(config.base.url + `/api/dd/listings/${listingId}/items/${itemId}/reactions`)
        resultPromise.then(() => console.log("Successfully added items to listing"))
        return resultPromise
    }

}