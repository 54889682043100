/** @jsxImportSource @emotion/react */
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {
    BoardItem, defaultItemFilters,
    Item, ItemFilters,
    Listing,
    noListing, noListingId,
    Project, Typology
} from "shared/model/model";
import {XYCoord} from "react-dnd";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Button, Divider,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import Typography from '@mui/material/Typography';
import {PickableItemList} from "./PickableItemList";
import { css } from '@emotion/css'
import {appBarHeight, logDebug} from "shared/utils";
import {colors} from "shared/styles/vars";
import {ItemFiltersDialog} from "view/components/items/filter-dialog/ItemFiltersDialog";
import {FiltrableItemListHeader} from "view/components/items/FiltrableItemListHeader";

import {useReferentialsState} from "state/ReferentialsState";

const boardItemPickerMargin = 20

interface Props {
    pickSource: string
    project: Project
    sourceListing: Listing
    items: Item[]
    boardOffset: XYCoord | null
    onItemDragged: (item: BoardItem) => void
    onItemDropped: (item: BoardItem) => void
    onDragAndDropEnd: (item: BoardItem) => void
    onPickSourceChanged: (pickSource: string) => void
    onSourceListingChanged: (sourceListingId: string) => void
}

export const BoardItemPicker = (props: Props) => {

    const sourceRef = useRef<HTMLDivElement>(null)

    const [pickedListingId, setPickedListingId] = useState(noListingId);
    const [pickableItemListHeight, setPickableItemListHeight] = useState(500);
    const [changeListingMode, setChangeListingMode] = useState(false);
    const [availableListings, setAvailableListings] = useState<Listing[]>([])

    const [filters, setFilters] = useState(defaultItemFilters);
    const [filtersDialogOpen, setFiltersDialogOpen] = useState(false);

    const [filteredItems, setFilteredItems] = useState([] as Item[]);

    const isPickingList = (props.pickSource == "listing") && ((props.sourceListing.id == noListingId) || changeListingMode) && (props.project.listings.length > 0)

    useEffect(() => {
        setFilteredItems(props.items.filter ((i) => getItemFilteringCondition(i, filters)))
        setPickerHeight()
    }, [props.items, filters, props.pickSource]);

    useEffect(() => {
        setPickedListingId(props.sourceListing.id)
    }, [props.sourceListing]);

    useEffect(() => {
        let listings = [noListing, ...props.project.listings]
        setAvailableListings(listings)
    }, [props.project])

    useEffect(() => {
        let listings = [noListing, ...props.project.listings]
        setAvailableListings(listings)
    }, [])

    const handleResize = useCallback((event: any) => {
        logDebug("handle resize")
        setPickerHeight()
    }, [])

    const setPickerHeight = () => {
        if (!window.visualViewport) {
            return
        }
        if (!sourceRef.current) {
            return
        }
        let sourceHeight = sourceRef.current.clientHeight
        logDebug("source height : " + sourceHeight)

        setPickableItemListHeight(window.visualViewport?.height!! - appBarHeight - sourceHeight - boardItemPickerMargin)
    }

    useLayoutEffect(() => {
        function updateSize() {
            logDebug("update size")
            setPickerHeight()
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);



    const handleCloseFiltersDialog = () => {
        setFiltersDialogOpen(false)
    }

    const handleResetFilters = () => {
        setFilters(defaultItemFilters)
        setFiltersDialogOpen(false)
    }

    const handleFiltersUpdated = (updatedFilters: ItemFilters) => {
        setFilters(updatedFilters)
        setFiltersDialogOpen(false)
    }

    const handleOpenFiltersRequested = () => {
        setFiltersDialogOpen(true)
    }

    const handlePickSourceChange = (e: SelectChangeEvent) => {
        props.onPickSourceChanged(e.target.value)
    }

    const handlePickedListingChange = (e: SelectChangeEvent) => {
        setPickedListingId(e.target.value)
    }

    const handlePickListing = () => {
        props.onSourceListingChanged(pickedListingId)
        setChangeListingMode(false)
    }

    const handleGoChangeListing = () => {
        setChangeListingMode(true)
    }

    const getItemFilteringCondition = (item: Item, filters: ItemFilters) => {
        // If the item doesn't match the filters, ignore it
        if (!((filters.supertypology == null || item.supertypology == filters.supertypology.code)
            && (filters.typology == null || item.typology == filters.typology.code)
            && (filters.subtypology == null || item.subtypology == filters.subtypology.code))) {
            return false
        }
        return true
    }

    return (
       <div className={css`
            margin: 20px;
            width: 185px;
            display: flex;
            flex-flow: column;
        `}>
        <div ref={sourceRef} className={css`
            .MuiSelect-select, .pick-source-label, label {
              font-size: 0.9em;
              color: ${colors.verydarkgrey};
            }
              .pick-source-label {
                margin-right: 0.5em;
                color: ${colors.darkgrey};
              }
            label {
              color: ${colors.verydarkgrey};
            }
            .listing-name:hover {
              text-decoration: underline;
            }
        `}>
            <span className={"pick-source-label"}>Source :</span>
            <Select
                label={"pick-source"}
                size="small"
                value={props.pickSource}
                onChange={handlePickSourceChange}
                variant="standard"
                className={css`
                    min-width: 75px;
                `}
            >
                    <MenuItem value={"listing"}>Liste</MenuItem>
                    <MenuItem value={"project"}>Projet</MenuItem>
                    <MenuItem value={"library"}>Bibliothèque</MenuItem>
            </Select>
            { isPickingList &&
                <Box sx={{marginTop: "10px"}}>
                    <InputLabel id="pick-listing-label" className={css`white-space: normal;`}>Choisissez une liste à associer à cette planche :</InputLabel>
                    <Select
                            label={"pick-listing"}
                        size="small"
                        value={pickedListingId}
                        labelId="pick-listing-label"
                        onChange={handlePickedListingChange}
                        variant="standard"
                        className={css`
                            min-width: 75px;
                        `}
                    >
                        {
                            availableListings.map((l) => {
                                return <MenuItem value={l.id} key={l.id}>{l.name}</MenuItem>
                            })
                        }
                    </Select>
                    <Button className={css`display: block;margin-top: 10px`} onClick={handlePickListing}>Enregistrer</Button>
                </Box>
            }
            { (!changeListingMode) && (props.pickSource == "listing") && (props.sourceListing.id != noListingId) &&
                <Typography className="listing-name" color={"info"} sx={{fontSize: "0.8em", fontWeight: "bold", marginTop: "10px", cursor: "pointer", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}} onClick={handleGoChangeListing}>{props.sourceListing.name}</Typography>
            }


            { ( 1 == 1 ) &&
                <Box>
                { !isPickingList &&
                <Box
                    className={css`
                        margin-top: 10px;
                    `}
                >
                    <Box
                        className={css`
                            font-size: 0.8em;
                            color: ${colors.verydarkgrey};
        
                          .MuiButton-text {
                            font-size: 1em;
                          }
                        `}
                    >
                        {filtersDialogOpen &&
                            <ItemFiltersDialog
                                filters={filters}
                                onFiltersUpdated={handleFiltersUpdated}
                                onClose={handleCloseFiltersDialog}
                                onReset={handleResetFilters}
                            />
                        }
                    </Box>
                    <Box  className={css`
                            font-size: 0.8em;
                            color: ${colors.verydarkgrey};
                      
                            .MuiButton-text {
                              font-size: 1em;
                            }     
                        `}>
                        {!filtersDialogOpen &&
                            <FiltrableItemListHeader filters={filters} nbItems={filteredItems.length}
                                                     onOpenRequested={handleOpenFiltersRequested}/>
                        }
                    </Box>
                </Box>
               }
            </Box>
           }

        </div>
       <Divider className={css`margin-top:10px;margin-bottom: 10px`}/>
        <PickableItemList height={pickableItemListHeight} boardOffset={props.boardOffset} items={filteredItems}
                          onDragAndDropEnd={props.onDragAndDropEnd} onItemDropped={props.onItemDropped}
                          onItemDragged={props.onItemDragged}/>
       </div>
    )
}