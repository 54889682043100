import {ClientUid} from './model/client-ids';
import { nominal } from './utils/nominal-class';
import config from "config";
import {
    approvedStatus,
    archivedStatus,
    draftStatus,
    noTypology,
    proposedStatus,
    rejectedStatus,
    toReviewStatus,
    Typology
} from "./model/model";

export const publicRegistrationOpen = false

export const allStatuses = [
    draftStatus, proposedStatus, toReviewStatus, rejectedStatus, approvedStatus, archivedStatus
]
export const appBarHeight = 50

export function toFloatLocale(value: string) {
    return value.replaceAll(".", ",")
}

export function isNumber(fieldValue: string) {
    if ((fieldValue == '') || (fieldValue == null)) {
        return false
    }
    let parsed = parseFloatLocale(fieldValue)
    if (parsed == null) {
        return false
    }
    return !isNaN(parsed)
}

export function isValidEmailAddress(email: string) {
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return expression.test(email);
}

export function parseFloatLocale(value: string): number | null {
    if ((value == '') || (value == null)) {
        return null
    }
    let withoutBlanks = value.replace(/\s/g, '')
    let withDot = whatDecimalSeparator() == "," ? withoutBlanks.replaceAll(",", ".") : withoutBlanks
    return parseFloat(withDot)
}

export function formatPrice(value: number): string {
    return value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

function whatDecimalSeparator() {
    return 1.1.toLocaleString().substring(1, 2);
}

export function assertUnreachable(x: never): never {
    throw new Error(`Expected unreachable code ! Value : "${JSON.stringify(x)}"`);
}

export function getStandardImageUrl(imageId: string) {
    return getImageUrl(imageId, '')
}

export function getThumbnailImageUrl(imageId: string, requestedFormat: string | null = null) {
    let format = requestedFormat != null ? requestedFormat : "xxxs"
    return getImageUrl(imageId, format)
}

function getImageUrlWithKey(imageKey: string) {
    if (config.cdn.enabled && !config.cdn.proxified) {
        return config.cdn.url + '/public/images/' + imageKey
    } else if (config.cdn.enabled && config.cdn.proxified) {
        return config.base.url + '/cdn/public/images/' + imageKey
    } else {
        return config.base.url + '/public/images/' + imageKey
    }
}

export function getDownloadImageUrl(imageId: string) {
    let format = ''
    let imageKey = `${imageId}.png`
    return getImageUrlWithKey(imageKey)
}

function getImageUrl(imageId: string, requestedFormat: string) {
    let format = requestedFormat != null ? requestedFormat : "xxxs"
    let imageKey = format != '' ? `${imageId}?f=${format}` : imageId
    return getImageUrlWithKey(imageKey)
}

export function getTypology(typologies: Typology[], typologyCode: string): Typology {
    for (let typology of typologies) {
        let found = getTypologyInner(typology, typologyCode)
        if (found.code != noTypology.code) {
            return found
        }
    }
    return noTypology
}

function getTypologyInner(typology: Typology, typologyCode: string): Typology {
    if (typology.code == typologyCode) {
        return typology
    }
    for (let sub of typology.subtypologies) {
        let found = getTypologyInner(sub, typologyCode)
        if (found.code != noTypology.code) {
            return found
        }
    }
    return noTypology
}

let uniqueIdIndex = 0;

export const clientUid = () =>
    nominal<ClientUid>('ClientUid_' + uniqueIdIndex++);


export const getCookie = (cookieName: string) => {
    const name = cookieName + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(name) !== -1) return c.substring(name.length, c.length);
    }
    return undefined;
};

export const compareByString =
    <T>(l: (o: T) => string) =>
        (o1: T, o2: T) =>
            l(o1).localeCompare(l(o2));

export const defer = (action: () => void) => setTimeout(action, 0);

export const sanitize = (inputString: string) => {
    // Escape HTML entities to prevent HTML injection
    const escapeHTML = (str: string) =>
        str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');

    // Split the input string into lines, preserving whitespace
    return inputString.split(/\r?\n/).map((line) => escapeHTML(line.trim()))
};

export const getItemColors = () => {
    return [
        {code: "brown", label: "Marron", hexValue: "#8B4513"},
        {code: "black", label: "Noir", hexValue: "#000000"},
        {code: "beige", label: "Beige", hexValue: "#F5F5DC"},
        {code: "white", label: "Blanc", hexValue: ""},
        {code: "gray", label: "Gris", hexValue: "#808080"},
        {code: "gold", label: "Or", hexValue: "#FFD700"},
        {code: "green", label: "Vert", hexValue: "#008000"},
        {code: "yellow", label: "Jaune", hexValue: "#FFFF00"},
        {code: "pink", label: "Rose", hexValue: "#FFC0CB"},
        {code: "red", label: "Rouge", hexValue: "#FF0000"},
        {code: "blue", label: "Bleu", hexValue: "#0000FF"},
        {code: "multicolored", label: "Multicolore", hexValue: ""},
        {code: "silver", label: "Argent", hexValue: "#C0C0C0"},
        {code: "copper", label: "Cuivre", hexValue: "#B87333"},
        {code: "taupe", label: "Taupe", hexValue: "#B19D8D"},
        {code: "orange", label: "Orange", hexValue: "#FFA500"},
    ]
}

export const getMaterials = () => {
    return [
        {code: "wood", label: "Bois"},
        {code: "metal", label: "Métal"},
        {code: "particle_board", label: "Panneau de particules"},
        {code: "bamboo", label: "Bambou"},
        {code: "steel", label: "Acier"},
        {code: "rattan", label: "Rotin"},
        {code: "wrought_iron", label: "Fer forgé"},
        {code: "glass", label: "Verre"},
        {code: "pvc_synthetic", label: "Pvc et Synthetique"},
        {code: "natural_fibers", label: "Fibres naturelles"},
        {code: "marble", label: "Marbre"},
        {code: "aluminium", label: "Aluminium"},
        {code: "non_woven", label: "Intissé"},
        {code: "cane", label: "Cannage"},
        {code: "cast_iron", label: "Fonte"},
        {code: "resin", label: "Résine"},
        {code: "stainless_steel", label: "Inox"},
        {code: "ceramic", label: "Céramique"},
        {code: "fabric", label: "Tissu"},
    ];
}

export const getLibraryItemStatuses = () => {
    return allStatuses;
}

export const getStatus = (statusCode: string) => {
    return allStatuses.find((s) => s.code == statusCode)!!
}


export const logDebug = (message: string) => {
    if (config.log.level == "debug") {
        console.log(message)
    }
}

export const logInfo = (message: string) => {
    if (config.log.level == "debug" || config.log.level == "info") {
        console.log(message)
    }
}

export const logWarn = (message: string) => {
    if (config.log.level == "debug" || config.log.level == "info" || config.log.level == "warn") {
        console.log(message)
    }
}

export const logError = (message: string, e: any | null = null) => {
    if (config.log.level == "debug" || config.log.level == "info" || config.log.level == "warn" || config.log.level == "error" ) {
        if (e != null) {
            console.error(message, e)
        } else {
            console.error(message)
        }
    }
}

export const getPageUrl = () => {
    return window.location.origin + window.location.pathname + window.location.search
}

