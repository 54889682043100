/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {Button, Popover} from "@mui/material";
import {DISLIKE_REACTION, LIKE_REACTION, NEUTRAL_REACTION} from "shared/model/model";
import {css} from "@emotion/react";
import {ReactionBarSelector} from "@charkour/react-reactions";
import {colors} from "shared/styles/vars";

interface Props {
    userReaction: string | null
    onReactionTypePicked: (reactionType: string) => void
}


export const ItemReactionPicker = (props: Props) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePicked = (reactionType: string) => {
        setAnchorEl(null);
        props.onReactionTypePicked(reactionType)
    }

    const allReactions = [
        {node: <div>👍</div>, label: '', key: LIKE_REACTION},
        {node: <div>👎</div>, label: '', key: DISLIKE_REACTION},
        {node: <div>😐</div>, label: '', key: NEUTRAL_REACTION}
    ]

    return (
        <div style={{display: "inline"}}>
            <Button
                id="react-menu"
                aria-controls={open ? 'react-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant={"outlined"}
                onClick={handleClick}>
                Réagir
            </Button>
            <Popover
                css={css`
                  .MuiPopover-paper {
                    box-shadow: none;
                    border: 1px solid ${colors.mediumgrey};
                    border-radius: 10px;
                    padding: 8px;

                    div {
                      border-radius: 0 !important;
                      box-shadow: none !important;
                    }
                  }
                `}
                id="listing-edition-menu"
                aria-labelledby="listing-edition-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <ReactionBarSelector iconSize={24} reactions={allReactions} onSelect={handlePicked}/>
            </Popover>
        </div>
    )

}