/** @jsxImportSource @emotion/react */
import {css} from "@emotion/react";
import React, {useEffect, useState} from 'react';
import {ItemCard} from '../ItemCard'
import {Item, Project, ItemCardSelectionMode, Listing, noListing, noItem} from "shared/model/model";
import {Box, Typography} from "@mui/material"
import {ItemReactionsDetailsPanel} from "../ItemReactionsDetailsPanel";
import {UserInfos} from "shared/model/user";

interface Props {
    items: Item[]
    project: Project
    projectId: string | null
    listingId: string | null
    sharingToken: string | null
    userInfos: UserInfos
    selectionMode: ItemCardSelectionMode
    itemAction: 'open_origin' | 'open_edition' | null
    selectedItemsIds: string[]
    onSelectionChange: (itemId: string) => void
    onRatingChange: (itemId: string, listingId: string | null, rating: string | null) => void
    onApprovalChange: (itemId: string, listingId: string | null, approved: boolean) => void
    onItemClicked: (itemId: string) => void
    onStatusClicked: (listingItemId: string) => void
    onCancelReactionRequested: (itemId: string, listingId: string) => void
}

export const ItemList = (props: Props) => {
    const [reactionsDetailsOpen, setReactionDetailsOpen] = useState(false)
    const [listingItemForReactionDisplay, setListingItemForReactionDisplay] = useState<Item>(noItem)
    const [listingForReactionDisplay, setListingForReactionDisplay] = useState<Listing>(noListing)


    const handleReactionsDetailsRequested = (listingItemId: string) => {
        if (!listingItemId) {
            setReactionDetailsOpen(false)
            setListingItemForReactionDisplay(noItem)
            setListingForReactionDisplay(noListing)
        }
        const item = props.project.listings.flatMap((l) => l.items).find((i) => i.listingItemId == listingItemId)
        if (item) {
            setListingItemForReactionDisplay(item)
            const listing = props.project.listings.find((l) => l.items.includes(item))
            if (listing) {
                setListingForReactionDisplay(listing)
            }
        }
        setReactionDetailsOpen(true)
    }

    const handleCancelReaction = (itemId: string, listingId: string) => {
        props.onCancelReactionRequested(itemId, listingId)
        setReactionDetailsOpen(false)
    }

    return (
        <div
            css={css`
            display: flex;
            flex-wrap: wrap;
            margin-left: -7.5px;
            margin-right: 7.5px;
         `}
        >
        {
            (props.items.length > 0 &&
                props.items.map((item) => (
                    <Box css={css`
                    `}>
                    <ItemCard item={item} project={props.project} projectId={props.projectId} listingId={props.listingId} key={item.id}
                              selectionMode={props.selectionMode}
                              sharingToken={props.sharingToken}
                              selected={props.selectedItemsIds.includes(item.id)}
                              onSelectionChange={() => props.onSelectionChange(item.id)}
                              selectOnClick={props.selectedItemsIds.length > 0}
                              itemAction={props.itemAction}
                              onRatingChange={props.onRatingChange}
                              onApprovalChange={props.onApprovalChange}
                              onStatusClicked={props.onStatusClicked}
                            onItemClicked={props.onItemClicked}
                            onReactionsDetailsRequested={handleReactionsDetailsRequested}/>
                    </Box>
                ))
            )
        }
        {
            (props.items.length == 0 &&
                <Typography variant="body1" color={"grey"} style={{marginLeft: "5px", marginTop: "20px"}}>
                    Aucun article trouvé pour les critères sélectionnés.
                </Typography>
            )
        }
        { listingItemForReactionDisplay && (listingItemForReactionDisplay != noItem) &&
            <ItemReactionsDetailsPanel userInfos={props.userInfos} open={reactionsDetailsOpen}
                                       listingItem={listingItemForReactionDisplay} listing={listingForReactionDisplay}
                                       onClose={() => setReactionDetailsOpen(false)}
                                       onCancelReactionRequested={handleCancelReaction}
            />
        }
        </div>
    )
};