export const dimensions = {
  screenSmMin: 768
};

export const fonts = {
  // baseSize: 8,
  font: "Helvetica Neue, Arial, Roboto, 'sans-serif'"
};

export const colors = {
  primaryMain: '#049DBF',
  primaryLight: '#88898C',
  primaryLight2: '#CCE8EFFF',
  secondaryMain: '#bb7d43',
  warningMain: '#F2D6B3',
  infoMain: '#547C8C',
  successMain: '#3b7d3b',
  errorRed: '#D9583B',

  black: '#000000',
  verydarkgrey: '#333',
  ratherdarkgrey: '#686868',
  darkgrey: '#9d9d9d',
  mediumgrey: '#cbcbcb',
  clearGrey: '#eeeeee',
  clearGrey2: '#f8f8f8',
  white: '#ffffff',

  darkRed: '#bd4641',
  yellow: '#ffef9d',
  orange: '#ffc04d',
  lightgreen: '#d2f8d2',
  darkgreen: '#116c11',
  purple: '#A366A3'
};
