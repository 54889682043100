/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {ProjectComponent} from "../ProjectComponent";
import {useTypedParams} from "utils/routing-utils";
import {ProjectListingPath} from "generated/routing/ApplicationPath.generated";

export const ProjectListingView = () => {
    const routeParams = useTypedParams<ProjectListingPath>();

    return (
        <ProjectComponent
            workParams={{projectId: routeParams.projectId, listingId: routeParams.listingId, itemId: null, boardId: null, menuItemType: "listing_detail"}}/>
    )
};
