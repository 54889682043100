import {noProject, Project} from "shared/model/model";
import {create} from "zustand";

export interface ProjectState {
    project: Project
    setProject: (project: Project) => void
}

export const useProjectState = create<ProjectState>(set => ({
    project: noProject,
    setProject: (project: Project) => set({ project })
}))