/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Box, Typography} from "@mui/material";
import {getThumbnailImageUrl, logDebug} from "shared/utils";
import {PercentCrop, ReactCrop} from "react-image-crop";
import {css} from "@emotion/react";
import 'react-image-crop/dist/ReactCrop.css'
import { CropDto } from 'shared/model/model';

export interface Props {
    imageId: string
    onClose: () => void
    onCropped: (crop: CropDto) => void
    cropping: boolean
    crop: CropDto | null
    open: boolean
}

export const ImageCropperDialog = (props: Props) => {

    const [originalImgUrl, setOriginalImgUrl] = useState('')
    const [percentCrop, setPercentCrop] = useState<PercentCrop>()

    useEffect(() => {
        setOriginalImgUrl(getThumbnailImageUrl(props.imageId, "m"))
    }, [props.imageId]);

    useEffect(() => {
        setPercentCrop(props.crop != null ? {...props.crop, unit: '%'} : undefined)
    }, [props.crop]);

    const handleCloseDialog = () => {
        props.onClose();
    };

    const onImageLoad = (e: any) => {
        if (percentCrop) {
            return
        }
        // const {naturalWidth: width, naturalHeight: height} = e.currentTarget
        setPercentCrop({x: 0, y: 0, width: 100, height: 100, unit: '%'})
    }


    const applyCrop = () => {
        if (percentCrop) {
            logDebug(`Crop: {x, y}=${percentCrop.x},${percentCrop.y}, {width, height}=${percentCrop.width},${percentCrop.height}`)
            props.onCropped(percentCrop)
        }
    }

    return (
        <Dialog onClose={handleCloseDialog} open={props.open}>
            <DialogTitle fontSize={"1.5em"}>Recadrer l'image</DialogTitle>
            <Box css={css`
              height: 600px;

              .ReactCrop__drag-elements {
                height: 100%;
              }
            `}>
                { !props.cropping &&
                    <ReactCrop crop={percentCrop} onChange={(crop, percentCrop) => setPercentCrop(percentCrop)}>
                        <img css={css`object-fit: contain;
                          max-width: 100%;
                          height: 400px;`} src={originalImgUrl} onLoad={onImageLoad}/>

                    </ReactCrop>
                }

                { props.cropping &&
                    <Box css={css`width:500px;height:100%;background-color:white;opacity:0.9;z-index:100`}>
                        <Typography gutterBottom variant="h6" component="div"
                                    css={css`text-align: center;margin-top:50px`} color={"lightgray"}>
                            <img src="https://i.ibb.co/5kL9Jjv/Spinner-1s-200px.gif" css={css`margin-top:80px;width: 80px`} />
                        </Typography>
                    </Box>
                }

                <Box css={css`margin-top: 20px;
                  text-align: center`}>
                    <Button css={css`margin-top: 20px;`} variant={"contained"} onClick={applyCrop} disabled={props.cropping}>Appliquer</Button>
                    <Button css={css`margin-top: 20px;`} onClick={props.onClose} disabled={props.cropping}>Annuler</Button>
                </Box>
            </Box>
        </Dialog>
    );
}