import config from "config";
import {appContext} from "ApplicationContext";
import {ImageDto} from "shared/model/model";

export class BoardService {

    async fetchImage(imageUrl: string): Promise<Blob> {
        let getParams = {"imageUrl": imageUrl}
        const response = await appContext.sessionAwareHttpService().fetch('get', `${config.base.url}/api/dd/image`, getParams)
        return response.blob()
    }

    fetchImages = async (imageUrls: string[]) => {
        const imageDataPromises = imageUrls.map(url => this.fetchImage(url));
        return await Promise.all(imageDataPromises);
    }

    removeBackground = async (imageId: string) => {
        const updatedImage = await appContext.sessionAwareHttpService().postBody<ImageDto>(`${config.base.url}/api/dd/images/remove-background?imageId=${imageId}`, {})
        return updatedImage.id
    }


}