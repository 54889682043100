/** @jsxImportSource @emotion/react */
import React from 'react'
import {ProjectBoard} from "shared/model/model";
import {Card, CardActionArea, CardContent, CardMedia, Paper, Typography} from "@mui/material";
import {css} from "@emotion/react";
import {navigateTo} from "utils/routing-utils";
import {appContext} from "ApplicationContext";
import {colors} from "shared/styles/vars";

interface Props {
    board: ProjectBoard
    projectId: string
}

export const ProjectBoardCard = (props: Props) => {

    const openBoardView = () => {
        navigateTo({name: 'EditBoard', projectId: props.projectId, boardId: props.board.id})
    }
    return (


            <Card variant="outlined"  sx={{ maxWidth: 280, minWidth: 150 }}
              css={css`
              cursor: pointer;
                min-height: 120px;
                margin: 7.5px;
                background-size: 10px 10px;
                `}
              onClick={openBoardView}
        >
            <div
                css={css`
                        `}
            >
                <CardActionArea css={css`
                    min-height: 120px;
                `}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div"   css={css`
                          text-align: center
                        `}>
                            {props.board.name}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </div>
        </Card>

    )
};