/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import * as React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Button, IconButton, Tooltip} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import {Edit} from "@mui/icons-material";
import {Item} from "shared/model/model";
import {useUserState} from "state/UserState";

interface Props {
    item: Item,
    goBackText: string
    onBackRequested: () => void
    openUrl:() => void
    edit:() => void
}

export const ItemViewMenu = (props: Props) => {
    const userInfos = useUserState(s => s.userInfos);

    return (
        <div
            css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          `}
        >
            <IconButton
                aria-label="retour"
                onClick={props.onBackRequested}
                edge="start"
            >
                <ArrowBackIcon/>
            </IconButton>
            <div css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  `}>
                <div>
                    <div>
                        <Button  startIcon={<LaunchIcon/>} onClick={props.openUrl}>Voir sur le site d'origine</Button>
                        { (props.item.ownerId == userInfos?.id) &&
                            <Button startIcon={<Edit/>} onClick={props.edit}>Modifier l'article</Button>
                        }
                    </div>
                </div>

            </div>

        </div>
    );
};
