/** @jsxImportSource @emotion/react */
import * as React from 'react';
import {Button, IconButton, Menu, MenuItem} from "@mui/material";
import {IconButtonMenu} from "view/components/IconButtonMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {MoreHoriz, Settings} from "@mui/icons-material";
import {neutralOutlined} from "shared/styles/common-styles";

interface Props {
    onDeleteListing: () => void,
    onRenameListing: () => void,
    onChangeSharing: () => void,
    sharingEnabled: boolean,
}

export const ListingEditionMenu = (props: Props) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleRenameList = () => {
        setAnchorEl(null);
        props.onRenameListing()
    }

    const handleDeleteList = () => {
        setAnchorEl(null);
        props.onDeleteListing()
    }

    const handleChangeSharing = () => {
        setAnchorEl(null);
        props.onChangeSharing()
    }

    return (
        <div style={{display: "inline"}}>
        <Button
            id="listing-edition-menu"
            aria-controls={open ? 'listing-edition-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant="outlined"
            sx={{...neutralOutlined}}
        >
            <Settings/> Paramètres de la liste
        </Button>
        <Menu
            id="listing-edition-menu"
            aria-labelledby="listing-edition-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem onClick={handleRenameList}>Renommer la liste</MenuItem>
            <MenuItem onClick={handleDeleteList}>Supprimer la liste</MenuItem>
            <MenuItem onClick={handleChangeSharing}>Modifier les options de partage</MenuItem>
        </Menu>

</div>
    );
};
