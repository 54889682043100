/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import * as React from 'react';
import {appContext} from 'ApplicationContext';
import {MainContainer} from '../layout/MainContainer';
import {Item, noUser, Referentials} from "shared/model/model";
import {Box, Typography} from "@mui/material";
import {v4 as uuidv4} from "uuid";
import {FiltrableItemList} from "../components/items/filtrable-item-list/FiltrableItemList";
import {LibraryAppBar} from "./LibraryAppBar";
import {useEffect, useState } from 'react';
import {navigateTo} from "utils/routing-utils";
import {useReferentialsState} from "state/ReferentialsState";
import {useUserState} from "state/UserState";

export const LibraryView = () => {
    const userInfos = useUserState(s => s.userInfos);
    const setConnected = useUserState(s => s.setConnected);

    const handleLogout = () => {
        appContext.loginService().logout().then(() => {
            setConnected(false, noUser)
            navigateTo({name: 'Login'})
        });
    };

    const handleAddItem = () => {
        let id = uuidv4()
        appContext.itemService().addItem({id: id, name: 'Nouvel article'} as Item)
            .then(() => {
                navigateTo({name: "LibraryItem", itemId: id})
            });
    }

    const handleItemClicked= (itemId: string) => {
        navigateTo({name: 'LibraryItem', itemId: itemId})
    }

    return (
        <MainContainer menu={<LibraryAppBar onAddItem={handleAddItem} onLogout={handleLogout}/>}>
            <div
                css={css`
        width: 100%;
        margin-top:70px;
        `}
            >
                <div>
                    <Typography variant="h3" component="div" gutterBottom
                    >
                        Bibliothèque
                    </Typography>

                    <FiltrableItemList project={null} isAddPickerMode={false} selectedItemsIds={[]}
                                       addFromLibrary={true} isSelectionEnabled={false}
                                       userInfos={userInfos!!}
                                       onCancelReactionRequested={() => null}
                                       onSelectionChange={() => null}
                                       onRatingChange={() => null}
                                       onApprovalChange={() => null}
                                       itemAction={'open_edition'}
                                       onStatusClicked={() => null}
                                       onFiltersChanged={() => null}
                                       onItemClicked={handleItemClicked}/>

                </div>
            </div>

        </MainContainer>
    );
};
