import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Alert, AlertColor} from "@mui/material";

interface Props {
    displayed: boolean,
    message: string,
    severity: AlertColor,
    onClosed: () => void
}

export const EventSnackBar = (props: Props) => {
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        props.onClosed()
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={props.displayed}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}>
                <Alert severity={props.severity}>{ props.message }</Alert>
            </Snackbar>
    );
}