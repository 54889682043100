import type {CSSProperties} from 'react'
import React, {useEffect, useRef, useState} from "react";
import {DropTargetMonitor, useDrop, XYCoord} from 'react-dnd'

import {ItemTypes} from './ItemTypes'
import {DragSourceItem} from "./DragSourceItem";
import {MovableItem} from "./MovableItem";
import {colors} from "shared/styles/vars";
import {BoardItem} from "shared/model/model";
import {RefLabel} from "./RefLabel";
import {DragSourceRefLabel} from "./DragSourceRefLabel";
import { logDebug } from 'shared/utils';

/*
Reference size for the canvas is 580
 */
interface Props {
    boardWidth: number
    boardHeight: number
    boardItems: BoardItem[],
    onBoardItemDragged: (item: BoardItem) => void
    onBoardItemDropped: (item: BoardItem) => void
    onBoardItemDragAndDropEnd: (item: BoardItem) => void
    onRefLabelDragged: (item: BoardItem) => void
    onRefLabelDropped: (item: BoardItem) => void
    onRefLabelDragAndDropEnd: (item: BoardItem) => void
    onBoardOffsetProvided: (offset: XYCoord | null) => void
    onResizeEnd: (x: number, y: number, width: number, height: number) => void
    onRotateEnd: (degrees: number) => void
    boardOffset: XYCoord | null
    selectedBoardItem: string | null
    onItemClick: (item: BoardItem) => void
    debugMode: boolean
    onDelete: (boardItemId: string) => void;
    onMoveForward: (boardItemId: string) => void;
    onMoveBackward: (boardItemId: string) => void;
    onRemoveBackground: (boardItemId: string) => void;
    onCrop: (boardItemId: string) => void;
    onEditItem: (boardItemId: string) => void;
    captionMode: boolean
}



export const BoardCanvas = (props: Props) => {

    const wrappingRef = useRef<HTMLDivElement | null>(null);
    const [pointerClientOffset, setPointerClientOffset] = useState<XYCoord | null>(null) // the last recorded { x, y }client offset of the pointer while a drag operation is in progress
    const [dragSourceClientOffset, setDragSourceClientOffset] = useState<XYCoord | null>(null) // the projected { x, y }client offset of the drag source component's root DOM node, based on its position at the time when the current drag operation has started, and the movement difference
    const [dropTargetClientOffset, setDropTargetClientOffset] = useState<XYCoord | null>(null)
    const [targetOffsetRelativeToBoard, setTargetOffsetRelativeToBoard] = useState<XYCoord | null>(null)

    useEffect(() => {
        let pOffset = getOffset(wrappingRef.current!!)
        logDebug("offset computed:" + pOffset.x + ", " + pOffset.y)
        props.onBoardOffsetProvided(pOffset)
    }, [])

    const style: CSSProperties = {
        height: props.boardHeight+"px",
        width: props.boardWidth+"px",
        color: 'white',
        textAlign: 'center',
        fontSize: '1rem',
        lineHeight: 'normal',
        float: 'left',
        left: '0',
        top: '0',
        backgroundSize: '10px 10px',
        overflow: 'hidden'
    }

    function getOffset(el: Element) {
        let position = el.getBoundingClientRect();
        return {x: position.left + window.scrollX, y: position.top + window.scrollY};
    }

    function updateOffsetStates(monitor: DropTargetMonitor<DragSourceItem, any>, sourceItem: DragSourceItem): XYCoord | null {
        setDropTargetClientOffset(monitor.getClientOffset())
        setPointerClientOffset(sourceItem.clientOffset)
        setDragSourceClientOffset(sourceItem.sourceClientOffset)

        let localBoardOffset = sourceItem.boardOffset // boardOffset from props is always null / undefined
        let localDropTargetOffset = monitor.getClientOffset() // we can't read anything from state, even if we just updated it, so we get all info from the item and monitor and use a local copy
        let localPointerOffset = sourceItem.clientOffset
        let localDragSourceOffset = sourceItem.sourceClientOffset

        if (!localBoardOffset || !localDropTargetOffset || !sourceItem.clientOffset || !sourceItem.sourceClientOffset) {
            logDebug("Can't compute board item position, there are null elements")
            logDebug(`localBoardOffset=${localBoardOffset}, localTargetClientOffset=${localDropTargetOffset}, sourceItem.clientOffset=${sourceItem.clientOffset}, sourceItem.sourceClientOffset=${sourceItem.sourceClientOffset}`)
            return null
        }

        let relativeOffset = {
            x: localDropTargetOffset.x - localBoardOffset.x - localPointerOffset?.x!! + localDragSourceOffset?.x!!,
            y: localDropTargetOffset.y - localBoardOffset.y - localPointerOffset?.y!! + localDragSourceOffset?.y!!,
        }
        setTargetOffsetRelativeToBoard(relativeOffset)

        return relativeOffset
    }

    const [{canDrop: canDropItem, isOver: isItemOver}, dropItem] = useDrop<DragSourceItem, any, any>(() => ({
        accept: [ItemTypes.IMAGE, ItemTypes.REF_LABEL],
        drop: (sourceItem: DragSourceItem, monitor) => {
            let coords = updateOffsetStates(monitor, sourceItem);
            if (!coords) {
                logDebug("Coords not known at dopping time!")
                return
            }
            logDebug("drop !")
            let targetDropItem;
            if (monitor.getItemType() == ItemTypes.IMAGE) {
                targetDropItem = {
                    ...sourceItem.boardItem,
                    image: {...sourceItem.boardItem.image, x: coords.x, y: coords.y}
                } as BoardItem
                props.onBoardItemDropped(targetDropItem)
            } else {
                targetDropItem = {
                    ...sourceItem.boardItem,
                    ref: {...sourceItem.boardItem.ref, x: coords.x, y: coords.y}
                } as BoardItem
                props.onRefLabelDropped(targetDropItem)
            }
            return {name: 'BoardCanvas'}
        },
        collect: (monitor) => {
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            }
        },
        hover: (sourceItem: DragSourceItem, monitor) => {
            updateOffsetStates(monitor, sourceItem);
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            }
        }
    }))

    const isActive = canDropItem && isItemOver && (props.boardItems.length == 0)
    let backgroundColor = '#222'
    let backgroundImage = 'radial-gradient(gray 0.5px, white 0.5px)'
    if (isActive) {
        backgroundImage = `radial-gradient(gray 0.5px, ${colors.clearGrey2} 0.5px)`

    } else if (canDropItem) {
        backgroundImage = 'radial-gradient(gray 0.5px, white 0.5px)'
    }

    return (
        <div>
            <div style={{
                position: "absolute",
                zIndex: "1",
                top: "0px",
                left: "0px",
                color: "white",
                padding: "0px",
                fontSize: "0.8em",
                fontFamily: "Courier New",
                backgroundColor: colors.verydarkgrey,
                display: props.debugMode ? "block" : "none"
            }}>

                {props.boardOffset &&
                <div>Board offset : {props.boardOffset.x},{props.boardOffset.y}</div>
                }
                {dragSourceClientOffset &&
                <div>Drag source offset : {dragSourceClientOffset.x},{dragSourceClientOffset.y}</div>
                }
                {pointerClientOffset &&
                <div>Pointer offset : {pointerClientOffset.x},{pointerClientOffset.y}</div>
                }
                {dropTargetClientOffset &&
                <div>Drop target offset: {dropTargetClientOffset.x},{dropTargetClientOffset.y}</div>
                }
                {targetOffsetRelativeToBoard &&
                <div>Target offset relative to board
                    : {targetOffsetRelativeToBoard.x},{targetOffsetRelativeToBoard.y}</div>
                }
            </div>
            <div ref={wrappingRef} style={{width: (props.boardWidth + 2) +"px", height:props.boardHeight+"px",
                borderLeft: `1px solid ${colors.mediumgrey}`, borderRight: `1px solid ${colors.mediumgrey}`}}>
                <div id={"dd-board-canvas"}  ref={dropItem} style={{...style, backgroundColor, backgroundImage: backgroundImage}}>
                    {props.boardItems.map((boardItem) => {
                        return <MovableItem boardOffset={props.boardOffset} boardItem={boardItem}
                                            onDragStart={props.onBoardItemDragged}
                                            onDragAndDropEnd={props.onBoardItemDragAndDropEnd} key={boardItem.instanceId}
                                            onResizeEnd={props.onResizeEnd}
                                            onRotateEnd={props.onRotateEnd}
                                            selected={boardItem.instanceId == props.selectedBoardItem}
                                            onItemClick={props.onItemClick}
                                            debugMode={props.debugMode}
                                            onDelete={props.onDelete}
                                            onRemoveBackground={props.onRemoveBackground}
                                            onCrop={props.onCrop}
                                            onEditItem={props.onEditItem}
                                            onMoveForward={props.onMoveForward}
                                            onMoveBackward={props.onMoveBackward}
                        />
                    })}
                    {props.captionMode && props.boardItems.map((boardItem) => {
                        return <RefLabel boardOffset={props.boardOffset} boardItem={boardItem}
                                         onDragStart={props.onRefLabelDragged}
                                         onDragAndDropEnd={props.onRefLabelDragAndDropEnd} key={boardItem.instanceId}
                                         debugMode={props.debugMode} onItemClick={props.onItemClick}
                        />
                    })}
                </div>
            </div>
        </div>
    )
}
