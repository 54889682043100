/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import {Comment, noItem, noListing, noListingId, noProject, noStatus, noUser, Project} from "shared/model/model";
import {appContext} from "ApplicationContext";
import {MainContainer} from "../layout/MainContainer";
import {css} from "@emotion/react";
import {EventSnackBar} from "../components/EventSnackBar";
import {v4 as uuidv4} from "uuid";
import {ItemPropertiesPanel} from "../projects/project-editor/item/ItemPropertiesPanel";
import {ListingItemReactionPanel} from "../projects/project-editor/listing-item/ListingItemReactionPanel";
import {SharedItemViewMenu} from "./SharedItemViewMenu";
import {useConfirm} from "material-ui-confirm";
import Chip from "@mui/material/Chip";
import {AlertColor} from "@mui/material";
import {navigateTo, useTypedParams} from "../../utils/routing-utils";
import {SharedListingItemPath} from "../../generated/routing/ApplicationPath.generated";
import {useNavigate} from "react-router-dom";
import {useReferentialsState} from "state/ReferentialsState";


export const SharedListingItemView = () => {
    const routeParams = useTypedParams<SharedListingItemPath>();
    const navigate = useNavigate()

    const [item, setItem] = useState(noItem);
    const [project, setProject] = useState(noProject);
    const [userInfos, setUserInfos] = useState(noUser);
    const [listing, setListing] = useState(noListing);

    const [snackBarDisplayed, setSnackBarDisplayed] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarSeverity, setSnackBarSeverity] = useState<AlertColor>('info');

    const [status, setStatus] = useState(noStatus)
    const statuses = useReferentialsState(s => s.listingItemStatuses)

    const confirm = useConfirm();

    useEffect(() => {
        if (item) {
            setStatus(statuses.find(s => s.code == item.status) ?? noStatus)
        }
    }, [item]);


    const fetchProject = () => {
        return appContext.projectService().getSharedProject(routeParams.token)
            .then((project: Project) => {
                setProject(project);
                let collab = project.collaborators.find((c) => c.inviteToken == routeParams.token)!!
                setUserInfos({...noUser, mail: collab.collaboratorMail})
                return project
            });
    };

    const handleBack = () => {
        navigate(-1)
    }

    const handleRatingChange = (itemId: string, listingId: string | null, rating: string | null) => {
        appContext.projectService().addGuestRating(routeParams.token, itemId, listingId, rating)
            .then(() => {
                fetchProjectAndListing()
            })
    }

    const handleApprovalChange = (itemId: string, listingId: string | null, approved: boolean) => {
        appContext.projectService().updateGuestApproval(routeParams.token, itemId, listingId, approved)
            .then(() => {
                fetchProjectAndListing()
            })
    }

    const saveComment = (itemId: string, listingId: string, commentText: string) => {
        let id = uuidv4()
        appContext.listingService().addComment(id, listingId, routeParams.itemId, commentText, routeParams.token)
            .then((comment: Comment) => {
                const updatedItem = {...item, comments: [...item.comments, comment]}
                const updatedItems = listing.items.map((i) => i.id == itemId ? updatedItem : i)
                const updatedListing = {...listing, items: updatedItems}
                const updatedListings = project.listings.map((l) => l.id == listing.id ? updatedListing : l)
                let updatedProject = {...project, listings: updatedListings}
                updateListingsAndProjectState(updatedProject, routeParams.listingId)
            })
    }

    function updateListingsAndProjectState(updatedProject: Project, currentListingId: string) {
        setProject(updatedProject)
        let updatedListing = updatedProject.listings.find((l) => l.id == currentListingId)
        if (!updatedListing) {
            return
        }
        setListing(updatedListing)
        let listingItem = updatedListing.items.find((item) => item.id == routeParams.itemId)
        if (listingItem) {
            setItem(listingItem)
        }
    }


    const deleteComment = (commentId: string) => {
        confirm({
            title: "Supprimer le commentaire",
            description: "Êtes vous sûr(e) de vouloir supprimer ce commentaire ?",
            cancellationText: "Annuler"
        })
            .then(() => {
                appContext.listingService().deleteComment(commentId, listing.id, routeParams.itemId)
                    .then(() => {
                        fetchProjectAndListing()
                    })
            })

    }

    const openUrl = () => {
        window.open(item.url, '_blank')
    }

    const edit = () => {
        navigateTo({name: 'LibraryItem', itemId: item.id})
    }

    const handleSnackBarClosed = () => {
        setSnackBarDisplayed(false)
    }

    function fetchProjectAndListing() {
        fetchProject().then((project) => {
            let listing = project.listings.find((l) => l.id == routeParams.listingId)!!
            let item = listing.items.find((i) => i.id == routeParams.itemId)!!
            setProject(project)
            setItem(item)
            setListing(listing)
        })
    }

    useEffect(() => {
        fetchProjectAndListing();
    }, []);

    const cancelReaction = (itemId: string, listingId: string) => {
        handleRatingChange(itemId, listingId, null)
    }

    return (

        <MainContainer justify={"center"} menu={
            <SharedItemViewMenu item={item} sharedToken={routeParams.token} goBackText={"retour"}
                                onBackRequested={handleBack} openUrl={openUrl} edit={edit}/>
        }
        >
            <div
                css={css`
                  max-width: 800px;
                  display: flex;

                  .MuiChip-filled {
                    background-color: ${status.color};
                    color: ${status.textColor};
                  }
                `}
            >
                <div>
                    {listing.id != noListingId &&
                        <Chip label={status.label}/>
                    }
                    <ItemPropertiesPanel item={item} fullPage={true}/>

                    {listing.id != noListingId &&
                        <div css={css`
                          margin-top: 30px;
                        `}>
                            <ListingItemReactionPanel libraryItemId={routeParams.itemId} listing={listing}
                                                      userInfos={userInfos}
                                                      project={project}
                                                      onCommentSubmitted={saveComment}
                                                      onRatingChange={handleRatingChange}
                                                      onCancelReactionRequested={cancelReaction}
                                                      onApprovalChange={handleApprovalChange}
                                                      guestMode={true} onDelete={deleteComment}/>
                        </div>
                    }

                    <EventSnackBar displayed={snackBarDisplayed} message={snackBarMessage}
                                   onClosed={handleSnackBarClosed} severity={snackBarSeverity}/>
                </div>
            </div>
        </MainContainer>

    )
};