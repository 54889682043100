/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

import React, { useState, useEffect } from 'react';
import { logDebug } from 'shared/utils';

import { Direction } from './constants';

interface Props {
    onResize: (direction: string, movementX: number, movementY: number) => void;
    onRotate: (movementX: number, movementY: number) => void;
    onResizeStart: () => void;
    onResizeEnd: () => void;
    onRotateStart: () => void;
    onRotateEnd: () => void;
    rotation: number
    dragging: boolean
}

const Resizer = (props: Props) => {
    const [direction, setDirection] = useState('');
    const [mouseDown, setMouseDown] = useState(false);
    const [rotating, setRotating] = useState(false);
    const [resizing, setResizing] = useState(false);

    const handleMouseMove = (e: any) => {
        if (!direction) return;

        logDebug(`handleMouseMove !!!!`)

        //const ratio = window.devicePixelRatio
        const ratio = 1 // TODO ETD why was it originally  window.devicePixelRatio?
        if (rotating) {
            logDebug("call onRotate...")
            props.onRotate(e.clientX, e.clientY);
        } else if (direction && !rotating) {
            props.onResize(direction, e.movementX  / ratio, e.movementY / ratio);
        }
        //e.stopPropagation()
    };

    useEffect(() => {

        if (mouseDown) {
            window.addEventListener('mousemove', handleMouseMove);
        }

        return () => {
            window.removeEventListener('mousemove',  handleMouseMove);
        };
    }, [mouseDown, direction, props.onResize]);

    const handleMouseUp = () => {
        logDebug("handleMouseUp")
        if (mouseDown) {
            if (rotating) {
                props.onRotateEnd();
            } else {
                props.onResizeEnd();
            }
        }
        setMouseDown(false);
        setRotating(false);
        setResizing(false)
    }

    useEffect(() => {

        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [mouseDown]);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>, direction: string) => {
        logDebug(`handleMouseDown ${direction}`)
        if (direction === 'rotate') {
            setDirection(direction);
            logDebug("Set rotating true")
            setRotating(true);
            props.onRotateStart();
        } else {
            setDirection(direction);
            setResizing(true);
            props.onResizeStart();
        }
        setMouseDown(true);
        //e.stopPropagation()
    };

    const selectionBorderColor = "#1a73e8";

    return (
        <div
            css={css`
            
            .top-left {
  position: absolute;
  cursor: nwse-resize;
  height: 10px;
  width: 10px;
  z-index: 2;
left: -2px;
top: -2px;
  background-color: ${selectionBorderColor};
}

.top {
  position: absolute;
  cursor: ns-resize;
  height: 2px;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: ${selectionBorderColor};
}

.top-right {
  position: absolute;
  cursor: nesw-resize;
  height: 10px;
  width: 10px;
  z-index: 2;
right: -2px;
top: -2px;
  background-color: ${selectionBorderColor};
}

.right {
  position: absolute;
  cursor: ew-resize;
  width: 2px;
  height: 100%;
  z-index: 1;
  right: 0;
  top: 0;
  background-color: ${selectionBorderColor};
}

.bottom-left {
  position: absolute;
  cursor: nesw-resize;
  width: 10px;
  height: 10px;
  z-index: 2;
  left: 0;
  left: -2px;
bottom: -2px;
  background-color: ${selectionBorderColor};
}

.bottom {
  position: absolute;
  cursor: ns-resize;
  width: 100%;
  height: 2px;
  z-index: 1;
  bottom: 0;
  left: 0;
  background-color: ${selectionBorderColor};
}

.right-bottom {
  position: absolute;
  cursor: nwse-resize;
  width: 10px;
  height: 10px;
  z-index: 2;
  right: -2px;
bottom: -2px;
  background-color: ${selectionBorderColor};
}

.left {
  position: absolute;
  cursor: ew-resize;
  width: 2px;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: ${selectionBorderColor};
}

.rotate-handle {
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 2px solid ${selectionBorderColor};
  border-radius: 50%;
  top: -22px; /* Adjust the position as needed */
  left: calc(50% - 10px); /* Adjust the position as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px; /* Adjust the font size of the rotation icon */
  display: ${(resizing || props.dragging) ? "none" : "block"}
}

position: absolute;
width: 100%;
height: 100%;
transform: rotate(${props.rotation}deg)



            `}
        >
            <div className="top-left" onMouseDown={(e) => handleMouseDown(e, Direction.TopLeft)}></div>

            <div className="rotate-handle" onMouseDown={(e) => handleMouseDown(e, 'rotate')}></div>

            <div className="top" onMouseDown={(e) => handleMouseDown(e, Direction.Top)}></div>

            <div className="top-right" onMouseDown={(e) => handleMouseDown(e, Direction.TopRight)}></div>

            <div className="right" onMouseDown={(e) => handleMouseDown(e, Direction.Right)}></div>

            <div className="right-bottom" onMouseDown={(e) => handleMouseDown(e, Direction.BottomRight)}></div>

            <div className="bottom" onMouseDown={(e) => handleMouseDown(e, Direction.Bottom)}></div>

            <div className="bottom-left" onMouseDown={(e) => handleMouseDown(e, Direction.BottomLeft)}></div>

            <div className="left" onMouseDown={(e) => handleMouseDown(e, Direction.Left)}></div>
        </div>
    );
};

export default Resizer;