/** @jsxImportSource @emotion/react */
import type {CSSProperties} from 'react'
import React, {createRef, useEffect, useState} from "react";
import {DragPreviewImage, DragSourceMonitor, DropTargetMonitor, useDrag, useDragLayer, XYCoord} from 'react-dnd'

import {ItemTypes} from './ItemTypes'
import {DragSourceItem} from "./DragSourceItem";
import Resizer from "view/components/resizer/resizer";
import {Direction} from "view/components/resizer/constants";
import Menu from "@mui/material/Menu";
import {Divider, MenuItem} from "@mui/material";
import {BoardItem} from "shared/model/model";
import {getStandardImageUrl, logDebug} from "shared/utils";
import {getEmptyImage} from "react-dnd-html5-backend";

const itemWidth = 150
const itemHeight = 150

const boardBorderSize = 1

const itemWidthString = `${itemWidth}px`

const keepRatio = true

const style: CSSProperties = {
    cursor: 'move',
    position: 'absolute'
}

export interface Props {
    boardItem: BoardItem
    boardOffset: XYCoord | null
    onDragStart: (item: BoardItem) => void
    onDragAndDropEnd: (item: BoardItem) => void
    onResizeEnd: (x: number, y: number, width: number, height: number) => void
    onRotateEnd: (degrees: number) => void
    onItemClick: (item: BoardItem) => void
    selected: boolean
    debugMode: boolean
    onDelete: (boardItemId: string) => void;
    onMoveForward: (boardItemId: string) => void;
    onMoveBackward: (boardItemId: string) => void;
    onRemoveBackground: (boardItemId: string) => void;
    onCrop: (boardItemId: string) => void;
    onEditItem: (boardItemId: string) => void;
}

interface DropResult {
    name: string
}

export const MovableItem = (props: Props) => {

    const imageRef = createRef<HTMLImageElement>()

    const [panelWidth, setPanelWidth] = useState<number>(0)
    const [panelHeight, setPanelHeight] = useState<number>(0)
    const [panelTop, setPanelTop] = useState<number>(0)
    const [panelLeft, setPanelLeft] = useState<number>(0)
    const [resizing, setResizing] = useState<boolean>(false)
    const [rotating, setRotating] = useState<boolean>(false)
    const [panelUpdated, setPanelUpdated] = useState<boolean>(false)
    const [degrees, setDegrees] = useState<number>(0)
    const [rotationHandleX, setRotationHandleX] = useState<number>(0)
    const [rotationHandleY, setRotationHandleY] = useState<number>(0)
    const [previewPosition, setPreviewPosition] = useState<XYCoord>({x: 0, y: 0})

    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        handleClick(event)
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const closeMenu = () => {
        setContextMenu(null);
    };

    const handleClose = () => {
        closeMenu()
    };

    const handleDelete = () => {
        closeMenu()
        props.onDelete(props.boardItem.instanceId)
    }

    const handleMoveForward = () => {
        closeMenu()
        props.onMoveForward(props.boardItem.instanceId)
    }

    const handleMoveBackward = () => {
        closeMenu()
        props.onMoveBackward(props.boardItem.instanceId)
    }

    const handleCrop = () => {
        closeMenu()
        props.onCrop(props.boardItem.instanceId)
    }

    const handleRemoveBackground = () => {
        closeMenu()
        props.onRemoveBackground(props.boardItem.instanceId)
    }

    const handleEditItem = () => {
        closeMenu()
        props.onEditItem(props.boardItem.instanceId)
    }

    const [{
        isDragging,
        dragSourceOffset,
    }, drag, preview] = useDrag<DragSourceItem, any, any>(() => ({
        type: ItemTypes.IMAGE,
        canDrag: !resizing && !rotating,
        item: (monitor: DragSourceMonitor<DragSourceItem, any>) => {
            props.onDragStart(props.boardItem)
            return {
                boardItem: props.boardItem,
                clientOffset: monitor.getClientOffset(),
                sourceClientOffset: monitor.getSourceClientOffset(),
                boardOffset: props.boardOffset
            }
        },
        end: (item: DragSourceItem, monitor) => {
            const dropResult = monitor.getDropResult<DropResult>()
            if (item && dropResult) {
                props.onDragAndDropEnd(item.boardItem)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            dragSourceOffset: monitor.getSourceClientOffset(),
            handlerId: monitor.getHandlerId(),
        }),
    }), [resizing, rotating, props.boardOffset])


    useEffect(() => {
        logDebug(`Dragging: ${isDragging}`)
    }, [isDragging])

    useEffect(() => {
        setPanelLeft(props.boardItem.image.x)
        setPanelTop(props.boardItem.image.y)
        setPanelWidth(props.boardItem.image.w)
        setPanelHeight(props.boardItem.image.h)
        setDegrees(props.boardItem.image.r)

    }, [props.boardItem])

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        props.onItemClick(props.boardItem)
        e.stopPropagation()
    }

    const handleResizeStart = () => {
        setResizing(true)
    };

    useEffect(() => {
        if (panelUpdated) {
            if (resizing) {
                setResizing(false)
                props.onResizeEnd(panelLeft, panelTop, panelWidth, panelHeight)
            }
            if (rotating) {
                setRotating(false)
                props.onRotateEnd(degrees)
            }
            setPanelUpdated(false)
        }
    }, [panelUpdated]);

    const handleResizeEnd = () => {
        setPanelUpdated(true)
    };

    const resizeTop = (movementX: number, movementY: number) => {
        let newHeight = panelHeight - movementY;
        setPanelHeight(newHeight)
        setPanelTop(panelTop + movementY)

        if (keepRatio) {
            let heightVariationRatio = newHeight / props.boardItem.image.h
            let newWidth = props.boardItem.image.w * heightVariationRatio
            setPanelWidth(newWidth)
        }

        showDims()
    };

    const resizeRight = (movementX: number, movementY: number) => {
        let newWidth = panelWidth + movementX
        setPanelWidth(panelWidth + movementX)

        if (!keepRatio) {
            let widthVariationRatio = newWidth / props.boardItem.image.w
            let newHeight = props.boardItem.image.h * widthVariationRatio
            setPanelHeight(newHeight)
        }

        showDims()
    };

    const resizeBottom = (movementX: number, movementY: number) => {
        let newHeight = panelHeight + movementY;
        setPanelHeight(newHeight)

        if (keepRatio) {
            let heightVariationRatio = newHeight / props.boardItem.image.h
            let newWidth = props.boardItem.image.w * heightVariationRatio
            setPanelWidth(newWidth)
        }

        showDims()
    };

    const resizeTopLeft = (movementX: number, movementY: number) => {
        let newHeight = panelHeight - movementY;
        setPanelHeight(newHeight)
        setPanelTop(panelTop + movementY)

        if (keepRatio) {
            let heightVariationRatio = newHeight / props.boardItem.image.h
            let newWidth = props.boardItem.image.w * heightVariationRatio
            let widthVariation = newWidth - props.boardItem.image.w
            setPanelWidth(newWidth)
            setPanelLeft(props.boardItem.image.x - widthVariation)
        } else {
            resizeLeftOnly(movementX)
        }
    }

    const resizeBottomLeft = (movementX: number, movementY: number) => {
        let newHeight = panelHeight + movementY;
        setPanelHeight(newHeight)

        if (keepRatio) {
            let heightVariationRatios = newHeight / props.boardItem.image.h
            let newWidth = props.boardItem.image.w * heightVariationRatios
            let widthVariation = newWidth - props.boardItem.image.w
            setPanelWidth(newWidth)
            setPanelLeft(props.boardItem.image.x - widthVariation)
        } else {
            resizeLeft(movementX, movementY)
        }
    }

    function resizeLeftOnly(movementX: number) {
        let newWidth = panelWidth - movementX;
        setPanelWidth(newWidth)
        setPanelLeft(panelLeft + movementX)
        return newWidth;
    }

    const resizeLeft = (movementX: number, movementY: number) => {
        let newWidth = resizeLeftOnly(movementX);

        if (!keepRatio) {
            let widthVariationRatio = newWidth / props.boardItem.image.w
            let newHeight = props.boardItem.image.h * widthVariationRatio
            setPanelHeight(newHeight)
        }
        showDims()
    };

    const showDims = () => {
        //logDebug(`showDims - ${panelLeft}, ${panelTop}, ${panelWidth}, ${panelHeight}`)
    }

    const handleRotate = (x: number, y: number) => {
        // Calculate the angle of rotation based on movementX and movementY
        const handleX = x - (props.boardOffset?.x ?? 0)
        const handleY = y - (props.boardOffset?.y ?? 0)

        let image = props.boardItem.image
        const imageCenterX = image.x + image.w / 2
        const imageCenterY = image.y + image.y / 2

        const angle = Math.atan2(handleY - imageCenterY, handleX - imageCenterX)
        const degrees = angle * (180 / Math.PI);
        setDegrees(degrees + 90)

        setRotationHandleX(handleX)
        setRotationHandleY(handleY)
    }

    const handleRotateEnd = () => {
        setPanelUpdated(true)
    }

    const handleRotateStart = () => {
        setRotating(true)
        setRotationHandleX(0)
        setRotationHandleY(0)
    }

    const handleResize = (direction: string, movementX: number, movementY: number) => {
        switch (direction) {
            case Direction.TopLeft:
                resizeTopLeft(movementX, movementY)
                break;

            case Direction.Top:
                resizeTop(movementX, movementY)
                break;

            case Direction.TopRight:
                resizeTop(movementX, movementY)

                if (!keepRatio) {
                    resizeRight(movementX, movementY)
                }
                break;

            case Direction.Right:
                resizeRight(movementX, movementY)
                break;

            case Direction.BottomRight:
                resizeBottom(movementX, movementY)
                if (!keepRatio) {
                    resizeRight(movementX, movementY)
                }
                break;

            case Direction.Bottom:
                resizeBottom(movementX, movementY)
                break;

            case Direction.BottomLeft:
                resizeBottomLeft(movementX, movementY)
                break;

            case Direction.Left:
                resizeLeft(movementX, movementY)
                break;

            default:
                break;
        }
    };

    const opacity = isDragging ? 0.4 : 1
    const zIndex = props.boardItem.image.z

    const blobUrl = props.boardItem.image.blobData ? URL.createObjectURL(props.boardItem.image.blobData) : null

    const imageUrl = getStandardImageUrl(props.boardItem.image.id)

    useEffect(() => {
        if (!dragSourceOffset || !props.boardOffset) {
            return
        }
        // logDebug(`Drag source: ${dragSourceOffset.x}, ${dragSourceOffset.y}`)
        let relativeOffset = {
            x: dragSourceOffset.x - props.boardOffset.x,
            y: dragSourceOffset.y - props.boardOffset.y,
        }
        // logDebug(`Relative offset: ${relativeOffset.x}, ${relativeOffset.y}`)
        setPreviewPosition(relativeOffset)

    }, [dragSourceOffset, props.boardOffset])
    useEffect(() => {
        preview(getEmptyImage(), {captureDraggingState: true})
    }, [isDragging])

    return (
        <>
            <div id={"movable-item-" + props.boardItem.instanceId} style={{
                ...style, opacity: opacity, left: `${panelLeft}px`, top: `${panelTop}px`, zIndex: zIndex
            }} onClick={handleClick} onContextMenu={handleContextMenu}>
                <div ref={(node) => drag(node)}>

                    <div>
                        {props.selected &&
                        <Resizer rotation={degrees} dragging={isDragging} onResize={handleResize}
                                 onResizeStart={handleResizeStart} onResizeEnd={handleResizeEnd} onRotate={handleRotate}
                                 onRotateEnd={handleRotateEnd} onRotateStart={handleRotateStart}/>
                        }

                        <img ref={imageRef} key={props.boardItem.instanceId} width={itemWidthString}
                             style={{
                                 color: "blue",
                                 width: `${panelWidth + boardBorderSize}px`,
                                 height: `${panelHeight + boardBorderSize}px`,
                                 transform: `rotate(${degrees}deg)`
                             }}
                             src={blobUrl ?? imageUrl} draggable={"false"}/>
                        <div style={{
                            backgroundColor: resizing ? "red" : "black",
                            color: "white",
                            position: "absolute",
                            right: "0px",
                            bottom: "0px",
                            display: props.debugMode ? "block" : "none"
                        }}>
                            {panelWidth}, {panelHeight}
                        </div>
                        <div style={{
                            backgroundColor: rotating ? "red" : "black",
                            color: "white",
                            position: "absolute",
                            right: "5px",
                            bottom: "50px",
                            display: props.debugMode ? "block" : "none"
                        }}>
                            <span>cursor: {rotationHandleX}, {rotationHandleY}</span>
                            <span>angle: {degrees}</span>
                        </div>

                        <Menu
                            open={contextMenu !== null}
                            onClose={closeMenu}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                contextMenu !== null
                                    ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                                    : undefined
                            }
                        >
                            <MenuItem onClick={handleMoveForward}>Avancer</MenuItem>
                            {(props.boardItem.image.z) > 1 &&
                            <MenuItem onClick={handleMoveBackward}>Reculer</MenuItem>
                            }
                            <MenuItem onClick={handleCrop}>Recadrer</MenuItem>
                            <MenuItem onClick={handleRemoveBackground}>Retirer l'arrière-plan</MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleEditItem}>Modifier l'article</MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleDelete}>Supprimer</MenuItem>
                        </Menu>
                    </div>

                </div>

            </div>
            {isDragging && preview(
                (<div style={{...style, left: previewPosition.x + "px", top: previewPosition.y + "px"}}>
                    {/* Your draggable item content */}
                    <img key={props.boardItem.instanceId + "-preview"} width={itemWidthString}
                         style={{
                             color: "blue",
                             width: `${panelWidth + boardBorderSize}px`,
                             height: `${panelHeight + boardBorderSize}px`,
                             transform: `rotate(${degrees}deg)`
                         }}
                         src={blobUrl ?? imageUrl} draggable={"false"}/>
                </div>), {}
            )}
        </>
    )
}
